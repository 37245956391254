import React, { useState, useEffect } from "react";
import { useModal } from "react-hooks-use-modal";
import {
  updateCpOptOutByPatientId,
  handleGetCarePlansByUserId,
} from "../../../services/CarePlan";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getPatientscharts } from "../../../services/PharmacyUser";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
const SelectPatient = (props) => {
  const { setPatient } = props;
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"), 10);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [cps, setCps] = useState([]);
  const [Modal, open, close] = useModal("root", {
    preventScroll: true,
  });

  const [warnopen, setWarnOpen] = React.useState(false);

  const handleClickOpen = () => {
    setWarnOpen(true);
  };

  const handleClose = () => {
    setWarnOpen(false);
  };

  useEffect(() => {
    getPatientscharts(pharmacyId)
      .then((res) => {
        const filteredOptions = res.data.filter((item) => item.active) || [];
        setOptions(filteredOptions);
      })
      .catch((err) => {
        console.error(err);
        setOptions([]);
      });
  }, [pharmacyId]);

  const handleSelectPatient = (patient) => {
    if (patient.cpOptOut) {
      open();
    } else {
      setConfirmed(true);
      setPatient(patient);
      handleGetCarePlansByUserId(patient.id)
        .then((res) => {
          console.log(res.data);
          setCps(res.data);
          if (res.data.length > 0) {
            handleClickOpen();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const cpStatusClickHandler = () => {
    const payload = {
      cpOptOut: !value.cpOptOut,
    };
    updateCpOptOutByPatientId(value.id, payload)
      .then(() => {
        setPatient(value);
        setConfirmed(true);
        setOptions(
          options.map((item) =>
            item.id === value.id ? { ...item, cpOptOut: !value.cpOptOut } : item
          )
        );
        close();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <>
      <div className="row">
        <div className=" col-12 text-center pt-3 pb-3 w-100 fw-bold d-flex justify-content-center">
          <p>Select Patient</p>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={warnopen}>
              <DialogTitle
                sx={{
                  m: 0,
                  p: 2,
                  color: "orange",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                id="customized-dialog-title">
                Warning
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                })}>
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <Typography
                  sx={{ color: "orange", textAlign: "center" }}
                  gutterBottom>
                  <WarningAmberRoundedIcon sx={{ fontSize: 50 }} />
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    bgcolor: "orange",
                    p: 1,
                    color: "warning",
                  }}
                  gutterBottom>
                  There {cps.length > 1 ? "are" : "is"} {cps.length} active care{" "}
                  {cps.length > 1 ? "plans" : "plan"} currently assigned to{" "}
                  {value?.first} {value?.last}.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  OK
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
          <Autocomplete
            disabled={confirmed}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{
              width: 300,
            }}
            getOptionLabel={(item) =>
              `${item.first} ${item.last}${item.MRN ? "- MRN:" + item.MRN : ""}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Search Patient" />
            )}
          />
        </div>
        <br />
        {value !== null && (
          <>
            <div className="col-12">
              <ul className="list-group mb-3 mt-3" disabled={confirmed}>
                <li className="list-group-item text-center pt-3 pb-3 w-100 fw-bold bg-info">
                  <Stack direction="column" spacing={2}>
                    Patient Details
                    <Avatar
                      alt={`${value.first} ${value.last}`}
                      src="../../../assets/images/patient_placeholder.png"
                      sx={{
                        alignSelf: "center",
                        alignItems: "center",
                        float: "right",
                      }}
                    />
                  </Stack>
                </li>
                <li className="list-group-item">
                  Patient First Name : {value.first}
                </li>
                <li className="list-group-item">
                  Patient Last Name : {value.last}
                </li>
                <li className="list-group-item">
                  Patient Date of Birth : {value.DOB}
                </li>
                <li className="list-group-item">Patient MRN : {value.MRN}</li>
                <li className="list-group-item">
                  Patient Gender : {value.gender}
                </li>
                <li className="list-group-item">
                  Patient Phone : {value.phone}
                </li>
                <li className="list-group-item">
                  Patient Email : {value.email}
                </li>
              </ul>
            </div>
            <Stack direction="column" spacing={2} sx={{ paddingRight: "10px" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setConfirmed(false);
                  setValue(null);
                }}
                disabled={!confirmed}>
                Select Different Patient
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={() => handleSelectPatient(value)}
                disabled={confirmed}>
                Confirm
              </Button>
            </Stack>
          </>
        )}
      </div>

      <Modal title="Change Careplan Status" close={close}>
        {value ? (
          <div className="card" style={{ width: "35vw", height: "35vh" }}>
            <div className="card-body">
              <h5 className="text-center fs-5 text-danger">
                {value.first} {value.last} Opted Out from Care Plan
              </h5>
              <hr />
              <p className="card-text">
                Discuss with patient to confirm if he/she would like to{" "}
                <span className="fw-bold text-success">Opt In </span>
                before proceeding
              </p>
              <p className="card-text mt-4">
                If you already confirmed you can click{" "}
                <span className="fw-bold text-success">Opt In </span>, Otherwise
                click <span className="fw-bold"> Cancel</span>
              </p>
              <div className="d-flex justify-content-between mt-3">
                <button
                  className={`btn btn-outline-${
                    value.cpOptOut ? "success" : "danger"
                  }`}
                  onClick={() => cpStatusClickHandler()}>
                  {value.cpOptOut ? "Opt In" : "Opt Out"}
                </button>
                <button className="btn btn-secondary" onClick={close}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default SelectPatient;
