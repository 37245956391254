import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { getUserRole, getUserPrivilege, getUserById } from "../services/Common";
import { getPharmaPrivileges } from "../services/Admin";

const loadUserPrivilege = async () => {
  try {
    const token = localStorage.getItem("Token");
    const myDecodedToken = decodeToken(token) || {};
    const userID = myDecodedToken.userId;
    const pharmacyId = parseInt(localStorage.getItem("pharmacy"));

    const userInfo = await getUserById(userID);
    const userType = userInfo.data.data.user[0].type;

    if (userType === "pharmacyAdmin") {
      const pharmacyPrivileges = (await getPharmaPrivileges(pharmacyId)) || [];

      const userPrivilege =
        pharmacyPrivileges?.data?.data?.map((priv) => priv.name) || [];

      return userPrivilege;
    } else {
      const roleResp = await getUserRole(userID);
      const roleID = roleResp.data.data[0].roleID;

      const privResp = await getUserPrivilege(roleID);
      const userPrivilege =
        privResp?.data?.data?.map((priv) => priv.name) || [];

      return userPrivilege;
    }
  } catch (error) {
    console.error("Error in getUserInfo:", error);
    return [];
  }
};

const PharmacyProtectedRoutes = ({ restrictedPrivilege }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAllowedPrivilege = await loadUserPrivilege();

        if (
          userAllowedPrivilege.includes(restrictedPrivilege) ||
          !restrictedPrivilege
        ) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
          navigate("notAuthorized");
        }
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching user info:", error);
        navigate("notAuthorized");
        setIsLoaded(true);
      }
    };

    fetchData();
  }, [navigate, restrictedPrivilege]);

  if (!isLoaded)
    return (
      <div
        className="d-flex flex-row justify-content-center mt-5  align-items-center"
        style={{ height: "90vh" }}>
        <div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="ms-4 align-middle text-secondary">Loading</div>
      </div>
    );
  return isAuthorized ? <Outlet /> : <div>Not Authorized</div>;
};

export default PharmacyProtectedRoutes;
