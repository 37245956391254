import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useModal } from "react-hooks-use-modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HideSource from "@mui/icons-material/HideSource";
import useSnackbar from "@mui/base/useSnackbar";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { css, keyframes } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  getAllForms,
  deleteForm,
  toggleFormHide,
  getPrivelge,
} from "../../services/PharmacyUser";
import FormsCreator from "../../components/pharmacyUser/formCenter/FormsCreator";
import SendForm from "../../components/pharmacyUser/formCenter/SendForm";
import { getUserById, getUserRole } from "../../services/Common";
import { decodeToken } from "react-jwt";
import FormsInstancesManage from "./FormsInstancesManage";

const FormsManage = () => {
  const colors = [
    "#FF0000",
    "#FF8700",
    "#FFD300",
    "#DEFF0A",
    "#A1FF0A",
    "#0AFF99",
    "#0AEFFF",
    "#147DF5",
    "#580AFF",
    "#BE0AFF",
  ];

  const getColor = (idx) => {
    return colors[idx % colors.length];
  };

  //   ========================================== Snackbar ==========================================
  const blue = {
    50: "#F0F7FF",
    400: "#3399FF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    200: "#E0E3E7",
  };

  const snackbarInRight = keyframes`
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(0);
    }
  `;

  const CustomSnackbar = styled("div")(
    ({ theme }) => css`
      position: fixed;
      z-index: 5500;
      display: flex;
      right: 16px;
      top: 16px;
      left: auto;
      justify-content: start;
      max-width: 560px;
      min-width: 300px;
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? blue[600] : blue[400]};
      box-shadow: ${theme.palette.mode === "dark"
        ? `0 5px 13px -3px rgba(0,0,0,0.4)`
        : `0 5px 13px -3px ${grey[200]}`};
      padding: 0.75rem;
      color: ${theme.palette.mode === "dark" ? "#fff" : blue[900]};
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      animation: ${snackbarInRight} 500ms;
      transition: transform 0.2s ease-out;
    `
  );
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  //  ====================== Material UI Ends  ======================
  //   ====================== Modal ======================

  const [Modal, openModal, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [DeleteModal, openDeleteModal, closeDelete] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [SendModal, openSendModal, closeSend] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  //   ====================== Modal Ends ======================

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacyID = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [forms, setForms] = useState([]);
  const [forms4Search, setForms4Search] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [randflag, setRandflag] = useState("init");
  const [thisform, setThisform] = useState({});
  const [deletebuttonflag, setDeletebuttonflag] = useState(false);
  const [msg, setMsg] = useState("");
  const [hiddenVisibility, setHiddenVisibility] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [privilege, setPrivilege] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState({});
  const [isSendingOriginal, setIsSendingOriginal] = useState(null);

  useEffect(() => {
    userID &&
      getUserById(userID)
        .then((res) => {
          setUser(res.data.data.user[0]);
          return res.data.data.user[0].type;
        })
        .then((type) => {
          type.toLowerCase() !== "pharmacyadmin" &&
            getUserRole(userID).then((res) => {
              setUserRole(res.data.data[0].roleID || "");
            });
        })
        .catch((err) => console.error(err));
  }, [userID]);

  useEffect(() => {
    userRole &&
      getPrivelge(userRole)
        .then((res) => {
          setPrivilege(res.data.data.map((x) => x.name));
        })
        .catch((err) => console.error(err));
  }, [userRole]);

  useEffect(() => {
    getAllForms(pharmacyID)
      .then((res) => {
        const formsRaw = res.data.map((el) => {
          let temp = el.formText;
          delete el.formText;
          return {
            ...el,
            json: JSON.parse(temp),
            isHidden: el.isHidden === 0 ? false : true,
            isSent: el.isSent === 0 ? false : true,
          };
        });

        setForms(formsRaw.reverse() || []);
      })
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randflag]);

  const handleChanges = (e) => {
    setFormTitle(e.target.value);
  };

  const handleCreate = () => {
    let form = {
      json: {},
      text: "",
    };

    form.json.title = formTitle;
    return form;
  };

  const handleDeleteChanges = (e) => {
    if (e.target.value.toLowerCase() === thisform.json.title.toLowerCase()) {
      setDeletebuttonflag(true);
    } else {
      setDeletebuttonflag(false);
    }
  };

  const handleDelete = () => {
    // delete form

    deleteForm(thisform.ID)
      .then(() => {
        setMsg(`Form "${thisform.json.title}" Has Been Successfully Deleted`);
        setForms(forms.filter((form) => form.ID !== thisform.ID));
        handleOpen();
      })
      .catch((err) => {
        console.error(err);
        setMsg(
          `Form "${thisform.json.title}" is active and can not be deleted `
        );
        handleOpen();
      });
  };

  const handleHideForm = (item) => {
    let FormID = item.ID;
    toggleFormHide(FormID)
      .then((res) => {
        setForms(
          forms.map((form) =>
            form.ID !== FormID ? form : { ...form, isHidden: !form.isHidden }
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleToggleHidden = () => {
    setHiddenVisibility(!hiddenVisibility);
  };
  useEffect(() => {
    setForms4Search(forms);
  }, [forms]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let searchLower = e.target.value.toLowerCase();
    if (e.target.value === "") {
      setForms4Search(forms);
    }
    setForms4Search(
      forms.filter((el) => el.json.title.toLowerCase().includes(searchLower))
    );
  };

  const onBackHandler = () => {
    setRandflag("init");
    setThisform({});
    setIsUpdate(false);
    setIsSendingOriginal(null);
  };

  if (
    isSendingOriginal !== null &&
    randflag === "send" &&
    isSendingOriginal === false
  ) {
    return (
      <FormsInstancesManage
        originalForm={thisform}
        formId={thisform.ID}
        handleBack={onBackHandler}
      />
    );
  }
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Forms</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Forms Center</li>
              <li
                className={`breadcrumb-item ${
                  randflag === "init" ? "active" : "cstm_breadcrumb"
                }`}
                onClick={() => {
                  setRandflag("init");
                  setThisform({});
                  setIsUpdate(false);
                }}>
                Manage Forms
              </li>
              {randflag !== "init" && (
                <li className="breadcrumb-item active">
                  {isUpdate
                    ? "Preview & Edit Form"
                    : randflag === "creator"
                    ? "Create Form"
                    : randflag === "send"
                    ? "Send Form"
                    : ""}
                </li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {randflag === "init" ? (
                <>
                  <div className="mb-2">
                    {(privilege.includes("writeForms") ||
                      user.type?.toLowerCase() === "pharmacyadmin") && (
                      <Button
                        className="mx-2 ms-0  mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={openModal}>
                        Create New Form
                      </Button>
                    )}
                    {forms.some((item) => item.isHidden === true) && (
                      <Button
                        className="mx-sm-2 mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<HideSource />}
                        onClick={() => handleToggleHidden()}>
                        {!hiddenVisibility ? "Show Hidden " : "hide "}Forms
                      </Button>
                    )}
                  </div>

                  <div className="card px-2 pb-3 col-12  pt-4">
                    <div className="col-12 col-md-6 col-lg-4 my-2  ps-lg-5">
                      <div className="input-group flex-nowrap  ">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Search Forms"
                          aria-label="Search"
                          aria-describedby="addon-wrapping"
                          onChange={handleSearch}
                          value={search}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size={"1x"}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="cstm_Survey_table col-12 col-lg-12 px-lg-5">
                      {forms4Search.map((item, i) => {
                        return hiddenVisibility ? (
                          <React.Fragment key={item.ID}>
                            <div
                              className="cstm_Survey_table_row card col-12"
                              style={{ backgroundColor: getColor(i) }}>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th
                                      className="py-2 ps-3"
                                      colspan="6"
                                      style={{
                                        backgroundColor: item.isHidden
                                          ? "#bab39e"
                                          : "white",
                                      }}>
                                      {item.json.title}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="col-12 ">
                                  <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                    <td className="text-center col col-auto flex-grow-1">
                                      <button
                                        className="w-100 h-100 border-0 m-0 p-0"
                                        onClick={() => {
                                          setThisform(item);
                                          openSendModal();
                                        }}>
                                        Send Form
                                      </button>
                                    </td>

                                    <td className="text-center col col-auto flex-grow-1">
                                      <button
                                        className="w-100 h-100 border-0 m-0 p-0"
                                        onClick={() => {
                                          setIsUpdate(true);
                                          setRandflag("creator");
                                          setThisform(item);
                                        }}>
                                        View & Edit
                                      </button>
                                    </td>

                                    <td className="text-center col col-auto flex-grow-1">
                                      <button
                                        className="w-100 h-100 border-0 m-0 p-0"
                                        onClick={() => {
                                          handleHideForm(item);
                                        }}
                                        color="warning">
                                        {!item.isHidden ? "Hide" : "Unhide"}
                                      </button>
                                    </td>

                                    <td className="text-center col col-auto flex-grow-1 p-0">
                                      <button
                                        className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                        onClick={() => {
                                          openDeleteModal();
                                          setThisform(item);
                                        }}>
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        ) : (
                          !item.isHidden && (
                            <React.Fragment key={item.ID}>
                              <div
                                key={item.ID}
                                className="cstm_Survey_table_row card col-12"
                                style={{ backgroundColor: getColor(i) }}>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="py-2 ps-3" colspan="6">
                                        {item.json.title}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="col-12 ">
                                    <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setThisform(item);
                                            openSendModal();
                                          }}>
                                          Send Form
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setIsUpdate(true);
                                            setRandflag("creator");
                                            setThisform(item);
                                          }}>
                                          View & Edit
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            handleHideForm(item);
                                          }}
                                          color="warning">
                                          {!item.isHidden ? "Hide" : "Unhide"}
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1 p-0">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                          onClick={() => {
                                            openDeleteModal();
                                            setThisform(item);
                                          }}>
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </React.Fragment>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <Modal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Create New Form
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label">
                              Form Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            close();
                          }}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          onClick={() => {
                            close();
                            setRandflag("creator");
                            setThisform(handleCreate());
                          }}>
                          Create
                        </button>
                      </div>
                    </div>
                  </Modal>
                  <DeleteModal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Delete {thisform?.json?.title && thisform.json.title}
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ color: "red" }}>
                              Please type "
                              {thisform?.json?.title && thisform.json.title}" in
                              the field below to confirm the deleted form title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInput"
                              aria-describedby="emailHelp"
                              onChange={handleDeleteChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeDelete();
                          }}>
                          Cancel
                        </button>
                        <button
                          disabled={!deletebuttonflag}
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          onClick={() => {
                            closeDelete();
                            handleDelete();
                          }}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </DeleteModal>
                  <SendModal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Do you want to modify the form or view existing variants
                        before sending it?
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <div className="d-flex justify-content-evenly row">
                          <button
                            className="btn btn-success col-2"
                            onClick={() => {
                              setIsSendingOriginal(false);
                              setRandflag("send");
                            }}>
                            Yes
                          </button>
                          <button
                            className="btn btn-success col-2"
                            onClick={() => {
                              setIsSendingOriginal(true);
                              setRandflag("send");
                            }}>
                            No
                          </button>
                        </div>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeSend();
                            setIsSendingOriginal(null);
                            setRandflag("init");
                          }}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </SendModal>
                  {open ? (
                    <ClickAwayListener onClickAway={onClickAway}>
                      <CustomSnackbar {...getRootProps()}>{msg}</CustomSnackbar>
                    </ClickAwayListener>
                  ) : null}
                </>
              ) : randflag === "creator" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisform({});
                      setIsUpdate(false);
                    }}>
                    Back
                  </Button>
                  <FormsCreator
                    form={thisform}
                    setRandflag={setRandflag}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    privilege={privilege}
                    userID={userID}
                    isAdmin={user.type?.toLowerCase() === "pharmacyadmin"}
                    creatorType={"pharmacy"}
                  />
                </>
              ) : randflag === "send" && isSendingOriginal === true ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisform({});
                      setIsUpdate(false);
                    }}>
                    Back
                  </Button>
                  <SendForm form={thisform} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormsManage;
