import React from "react";
import { SideBar } from "../../utils/globalState";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Calendar from "./Calendar";
import FollowUpForms from "../../components/patient/CPCenter/FollowUpForms";
import CPHistory from "./CPHistory";
const CPCenter = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Care Plan Center</h1>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {/* <div className="row">
              <div className="col-12"> */}
            <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example">
                  <Tab label="Calendar" {...a11yProps(0)} />
                  <Tab label="Current Care Plan Forms" {...a11yProps(1)} />
                  <Tab label="Filled Care Plan Forms" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Calendar />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <FollowUpForms />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <CPHistory />
              </TabPanel>
            </Box>
            {/* </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CPCenter;
