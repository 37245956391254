import axiosWithAuth from "../utils/axiosWithAuth";
import baseURL from "../utils/baseURL";
import axios from "axios";

export const getProfile = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/${id}`);
};

export const handleProfileUpdate = (id, payload) => {
  payload.ContactInfo = [];
  return axiosWithAuth().put(`${baseURL.curoback}/user/${id}`, payload);
};

export const addNewPatient = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/provider/patient`, payload);
};

export const getPatients = (providerId, page) => {
  let offset = page === 1 ? 0 : (page - 1) * 100;
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${providerId}/patients?offset=${offset}&limit=100`
  );
};

export const getPatientsTotal = (providerId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${providerId}/patient_count`
  );
};

export const searchPt = (searchItems, pId) => {
  let dob = !searchItems.DOB
    ? ""
    : `${
        searchItems.DOB.split("-")[1].charAt(0) === "0"
          ? searchItems.DOB.split("-")[1].charAt(1)
          : searchItems.DOB.split("-")[1]
      }/${
        searchItems.DOB.split("-")[2].charAt(0) === "0"
          ? searchItems.DOB.split("-")[2].charAt(1)
          : searchItems.DOB.split("-")[2]
      }/${searchItems.DOB.split("-")[0]}`;

  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${pId}/patients?first=${
      searchItems.first || ""
    }&last=${searchItems.last || ""}&DOB=${dob}&email=${
      searchItems.email || ""
    }&phone=${searchItems.phone || ""}&MRN=${searchItems.MRN || ""}`
  );
};

export const searchPtForms = (searchItems, pId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${pId}/patients?${searchItems}`
  );
};

export const getPatientscharts = (providerId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${providerId}/get_patients`
  );
};

export const getAllPharmacies = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getPharmacies`);
};

export const getAllRxsByProviderId = (ProviderId) => {
  return axiosWithAuth().post(`${baseURL.curoback}/provider/get_referrals`, {
    provider_id: ProviderId,
  });
};

export const searchForPatientToAddRx = (providerId, seachItem) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/${providerId}/${seachItem}`
  );
};

export const handleDiag = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/provider/get_diag`);
};

export const getDrug = (name) => {
  return axios.get(
    `https://api.fda.gov/drug/drugsfda.json?search=openfda.brand_name:${name}`
  );
};

export const addRx = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/provider/add_ref`, payload);
};

export const updatePatientInfo = (Patient_id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/provider/patient/${Patient_id}`,
    payload
  );
};

export const AddPatientProviderLink = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/provider/addProviderPatient`,
    payload
  );
};

export const toggleProviderPatientLink = (payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/provider/togglelinkstatus`,
    payload
  );
};

export const getProviderFeatures = (providerID) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/admin/providerfeatures/${providerID}`
  );
};

// ====================== Survey services ========== Start ============

export const getAllRes = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/get_all_responses`);
};

export const addSurvey = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/addServey`, payload);
};

export const getSurveys = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/getSurveys`);
};

export const getSurveyById = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/getSurveyById/${id}`);
};

export const updateSurvey = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/editServey/${id}`,
    payload
  );
};

export const addResponse = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/add_response`,
    payload
  );
};

export const getNotificationsUsers = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/notified_users`);
};

export const addNewRecipent = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/notified_users`,
    payload
  );
};

export const deleteRecipent = (email) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/servey/notified_users/${email}`
  );
};

export const getTokens = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/tokens`);
};

export const getTokenById = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/tokens/${id}`);
};

export const updateToken = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/tokens/${id}`,
    payload
  );
};

export const createNewToken = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/tokens`, payload);
};

export const deleteSurvey = (id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/servey/deleteSurvey/${id}`
  );
};

// ====================== Survey services ========== END ============

export const getFormsByCreatorID = (creatorID) => {
  return axiosWithAuth().get(`${baseURL.curoback}/form/creator/${creatorID}`);
};

export const getResponsesBySenderID = (senderID) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/responses/sender/${senderID}`
  );
};

export const getDocsByCreatorID = (creatorID) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/docs/creator/${creatorID}`
  );
};

export const getDocResponsesBySenderID = (senderID) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/doc_responses/sender/${senderID}`
  );
};
