import React, { useState, useEffect } from "react";
import FormsCharts from "../../components/provider/formCenter/Analytics/Charts/FormsCharts";
import FormsTrends from "../../components/provider/formCenter/Analytics/Trends/FormsTrends";
import { SideBar } from "../../utils/globalState";
import { decodeToken } from "react-jwt";
import { getResponsesBySenderID } from "../../services/Provider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function FormsAnalytics() {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [rawResponses, setRawResponses] = useState([]);
  const [msg, setMsg] = useState("");
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetFormsResponses = () => {
    getResponsesBySenderID(userID)
      .then((res) => {
        setRawResponses(res.data);
      })
      .catch((err) => {
        console.error(err.message);
        setMsg(err.message);
        setRawResponses([]);
      });
  };

  useEffect(() => {
    handleGetFormsResponses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1 className="mt-0 pt-0">Forms Analytics</h1>
          <nav>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">Forms Center</li>
              <li className="breadcrumb-item active">Forms Analytics</li>
            </ol>
          </nav>
        </div>
        {/* <div className="card"> */}
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example">
            <Tab value="one" label="Forms Charts" />
            <Tab value="two" label="Forms Trends" />
          </Tabs>
        </Box>
        {msg && { msg }}
        {value === "one" ? (
          <FormsCharts rawResponses={rawResponses} />
        ) : value === "two" ? (
          <FormsTrends rawResponses={rawResponses} />
        ) : (
          <FormsCharts rawResponses={rawResponses} />
        )}
        {/* </div> */}
      </main>
    </div>
  );
}

export default FormsAnalytics;
