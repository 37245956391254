import React, { useState, useRef, useEffect } from "react";
import { useModal } from "react-hooks-use-modal";
import { v4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";
import { Button, ListSubheader } from "@mui/material";
import { Paper } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grow from "@mui/material/Grow";
import FillCpFuForm from "./FillCpFuForm";
import { decodeToken } from "react-jwt";
import ReviewResponse from "../formCenter/ReviewResponse";
import { Alert } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import dayjs from "dayjs";
import { getSystemUserByPharmacyId } from "../../../services/Common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  RecurrenceEditorComponent,
  RecurrenceEditor,
} from "@syncfusion/ej2-react-schedule";
import {
  handleUpdateCarePlan,
  handleGetCpResponsesByCpId,
} from "../../../services/CarePlan";
import {
  handleUpdateEvent,
  handleAddEvent,
  handleGetEventById,
  handleGetFollowingEvents,
  handleDeleteEventWithAllFollowings,
} from "../../../services/Calendar";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReschReqView from "./ReschReqView";
const CpFollowUp = (props) => {
  const { currentCp, setCurrentCp, setCpList, cpList } = props;
  const pharmacyId = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [showFUfill, setShowFUfill] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [msg, setMsg] = useState("");
  const [recString, setRecString] = useState("");
  const [openDates, setOpenDates] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [recurrenceRule, setRecurrenceRule] = useState(null);
  const [systemUser, setSystemUser] = useState({});
  const [fuEvent, setFuEvent] = useState({});

  const [inputs, setInputs] = useState({
    startDate: dayjs(),
    location: currentCp.isPhoneFu ? "phone" : "form",
  });
  const [thisReschReq, setThisReschReq] = useState({
    eventToChange: null,
    cpid: null,
  });
  const recObject = useRef(null);

  const modalOptions = {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  };

  const [Modal, open, close] = useModal("root", modalOptions);
  const [ModalOptOut, openOptOut, closeOptOut] = useModal("root", modalOptions);
  const [ReOptModal, reOptOpen, reOptClose] = useModal("root", modalOptions);

  const startFuClickHandler = (exceptionString, recDate) => {
    setSearchParams((params) => {
      params.set("id", currentCp.id);
      params.set("uid", userID);
      params.set("phId", pharmacyId);
      params.set("resid", "na");
      params.set("except", exceptionString);
      params.set("d", recDate);
      return params;
    });
    setShowFUfill(true);
  };

  const columnDef = [
    {
      field: "date",
      headerName: "Follow-Up Date",
      sortable: true,
      minWidth: 200,
      valueGetter(params) {
        return dayjs(params.row.date).format("MM/DD/YYYY");
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      width: 120,
      valueGetter(params) {
        return params.row.response?.completedOn ? "Completed" : "Pending";
      },
    },
    {
      field: "completedOn",
      headerName: "Completed On",
      sortable: true,
      width: 120,
      valueGetter(params) {
        return params.row?.response?.completedOn
          ? dayjs(params.row.response.completedOn).format("MM/DD/YYYY")
          : "";
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 450,
      renderCell: (params) => (
        <div className="d-flex gap-1">
          <button
            disabled={!currentCp.isPhoneFu || params.row?.response?.completedOn}
            className="btn btn-primary btn-sm"
            onClick={() =>
              startFuClickHandler(params.row.syncExcepDate, params.row.date)
            }>
            Start Follow Up Call
          </button>
        </div>
      ),
    },
    {
      valueGetter: (params) => params.row,
      renderCell: (params) => (
        // dayjs(params.row.changeRequested.originalDates).format("MM/DD/YYYY") === dayjs(params.row.date).format("MM/DD/YYYY")

        <>
          {params.row.changeRequested &&
            params.row.changeRequested.some(
              (ele) =>
                dayjs(ele.originalDates).format("MM/DD/YYYY") ===
                dayjs(params.row.date).format("MM/DD/YYYY")
            ) && (
              <IconButton
                aria-label="fingerprint"
                color="warning"
                onClick={() => {
                  setThisReschReq({
                    eventToChange: params.row.date,
                    cpid: currentCp.id,
                    thisOrder: params.row,
                  });
                  console.log(
                    dayjs(params.row.changeRequested.originalDates),
                    dayjs(params.row.date)
                  );
                }}>
                <InfoOutlinedIcon fontSize="large" />
              </IconButton>
            )}
        </>
      ),
    },
  ];

  const handleInputsChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (newValue) => {
    setInputs((inputs) => ({
      ...inputs,
      startDate: newValue,
    }));
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const onChange = (args) => {
    setRecString(args.value);
  };

  const recurrenceFromIso = (milliSecDate) => {
    return (
      dayjs(milliSecDate)
        .toISOString()
        .split(".")[0]
        .split("")
        .map((c) => (!isNaN(c) || c === "T" ? c : ""))
        .join("") + "Z"
    );
  };

  const getAllData = async (id) => {
    const cpResponsesResp = await handleGetCpResponsesByCpId(currentCp.id);
    const cpResponses = cpResponsesResp.data.filter(
      (cpRes) => !cpRes.isInitResponse
    );

    handleGetEventById(id)
      .then(async (res) => {
        setFuEvent(res.data);
        let recObject = new RecurrenceEditor({
          change: (args) => {},
        });

        let ruleStringDates = recObject.getRecurrenceDates(
          new Date(res.data.StartTime),
          res.data.RecurrenceRule,
          res.data.RecurrenceException
        );
        const mainEventDates = ruleStringDates.map((d) => ({
          id: v4(),
          eventId: id,
          date: d,
          syncExcepDate: recurrenceFromIso(d),
          isMainEvent: true,
          response: {},
          changeRequested: res.data.changeRequest
            ? JSON.parse(res.data.changeRequest)
            : null,
        }));
        const followingEvents = await handleGetFollowingEvents(id);
        let followingDates = followingEvents.data.map((ev) => ({
          id: v4(),
          eventId: ev.Id,
          date: new Date(ev.StartTime).getTime(),
          syncExcepDate: ev.RecurrenceException,
          isMainEvent: false,
          response: cpResponses.find((r) => r.fuSubEventId === ev.Id),
          changeRequested: res.data.changeRequest
            ? JSON.parse(res.data.changeRequest)
            : null,
        }));

        const allDates = [...mainEventDates, ...followingDates].sort(
          (a, b) => a.date - b.date
        );

        setCombinedData(allDates);
        setRecurrenceRule(
          res.data.RecurrenceRule ? res.data.RecurrenceRule : null
        );
        setInputs({
          ...inputs,
          startDate: dayjs(res.data.StartTime),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (currentCp.fuEventId) {
      getAllData(currentCp.fuEventId);
    }

    getSystemUserByPharmacyId(pharmacyId).then((res) => {
      setSystemUser(res.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCp]);

  const handleUpdateSchedule = (event) => {
    event.preventDefault();
    setMsg("");
    if (currentCp?.fuOptedIn && currentCp?.fuEventId) {
      handleUpdateEvent(currentCp.fuEventId, {
        updatedBy: userID,
        pharmacyId,
        creatorId: currentCp.creatorID,
        OwnerId: userID,
        Subject: `Follow up for ${currentCp?.recipient?.first} ${
          currentCp?.recipient?.last
        } Started ${inputs.startDate.format("MM/DD/YYYY")}`,
        StartTime: dayjs(inputs.startDate).toISOString(),
        EndTime: dayjs(inputs.startDate).add(30, "minutes").toISOString(),
        IsAllDay: false,
        Location: inputs.location,
        Priority: "Normal",
        RecurrenceRule: recString,
        patientName: `${currentCp?.recipient?.first} ${currentCp?.recipient?.last}`,
        isInitEvent: false,
        isAutomated: true,
      })
        .then((r) => {
          setMsg("Follow Up schedule has been updated");
          if (currentCp.fuEventId) {
            getAllData(currentCp.fuEventId);
          }
          setChecked(false);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      let eventData = {
        pharmacyId,
        creatorId: currentCp.creatorID,
        OwnerId: userID,
        Subject: `Follow up for ${currentCp?.recipient?.first} ${currentCp?.recipient?.last} Started ${inputs.startDate}`,
        StartTime: dayjs(inputs.startDate).toISOString(),
        EndTime: dayjs(inputs.startDate).add(30, "minutes").toISOString(),
        IsAllDay: false,
        Location: inputs.location,
        Priority: "Normal",
        RecurrenceRule: recString,
        patientName: `${currentCp?.recipient?.first} ${currentCp?.recipient?.last}`,
        isInitEvent: false,
        isAutomated: true,
      };

      handleAddEvent(eventData)
        .then((res) => {
          handleUpdateCarePlan(currentCp.id, {
            fuOptedIn: true,
            fuEventId: res.data.id,
          });
          setCurrentCp({
            ...currentCp,
            fuOptedIn: true,
            fuEventId: res.data.id,
          });
          setMsg("Follow Up schedule has been updated");
          setChecked(false);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const formViewClickHandler = (formType) => {
    if (formType === 0) {
      setCurrentForm({
        value: null,
        formText: currentCp.initForm.json,
      });
      open();
    } else {
      setCurrentForm({
        value: null,
        formText: currentCp.fuForm.json,
      });
      open();
    }
  };

  const handleOptOut = () => {
    handleUpdateCarePlan(currentCp.id, {
      fuOptedIn: false,
      fuEventId: null,
    })
      .then(() => {
        if (currentCp.fuEventId) {
          handleDeleteEventWithAllFollowings(currentCp.fuEventId, userID);
        }
        setMsg("Patient has been opted out of follow up");
        closeOptOut();
        const updatedCp = { ...currentCp, fuOptedIn: false, fuEventId: null };
        setCurrentCp(updatedCp);
        setCpList(
          cpList.map((cp) => (cp.id === currentCp.id ? updatedCp : cp))
        );
        setRecurrenceRule(null);
        setCombinedData([]);
        setRecString("");
        setTimeout(() => {
          setMsg("");
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleReOptIn = () => {
    handleUpdateCarePlan(currentCp.id, {
      fuOptedIn: true,
    }).then(() => {
      const updatedCp = { ...currentCp, fuOptedIn: true };
      setCurrentCp(updatedCp);
      setCpList(cpList.map((cp) => (cp.id === currentCp.id ? updatedCp : cp)));
      setMsg("Patient has been opted in for follow up");
      reOptClose();
      setTimeout(() => {
        setMsg("");
      }, 3000);
    });
  };

  const handleUpdatePreference = () => {
    const allEvents = [...new Set(combinedData.map((d) => d.eventId))].sort(
      (a, b) => a - b
    );

    handleUpdateCarePlan(currentCp.id, {
      isPhoneFu: inputs.location === "phone" ? true : false,
    })
      .then(() => {
        setCurrentCp({
          ...currentCp,
          isPhoneFu: inputs.location === "phone" ? true : false,
        });
      })
      .then(() => {
        allEvents.forEach((ev) => {
          handleUpdateEvent(ev, {
            OwnerId: inputs.location === "form" ? systemUser.id : userID,
            updatedBy: userID,
            location: inputs.location,
            isAutomated: inputs.location === "form" ? true : false,
          });
        });
      })
      .then(() => {
        setMsg("Follow Up preference has been updated");
        setTimeout(() => {
          setMsg("");
        }, 3000);
      })
      .catch((err) => {
        setMsg("something went wrong");
        setTimeout(() => {
          setMsg("");
        }, 3000);
        console.error(err);
      });
  };

  const requestAction = (note) => {
    getAllData(currentCp.fuEventId);
  };

  return (
    <div className="col-12">
      <ReschReqView req={thisReschReq} action={requestAction} />
      <div className="row">
        {showFUfill ? (
          <div className="col-md-9 col-12">
            <FillCpFuForm
              getAllData={getAllData}
              cp={currentCp}
              setCurrentCp={setCurrentCp}
              setShowFUfill={setShowFUfill}
            />
          </div>
        ) : (
          <>
            {currentCp.fuOptedIn ? (
              <div className="col-md-9 col-12">
                <div className="row">
                  <div className="col-12" id="RecurrenceEditor">
                    <div className="row d-flex align-items-center justify-content-around mb-3">
                      {msg && <Alert sx={{ mb: 2 }}>{msg}</Alert>}
                      <Button
                        className="col-5"
                        variant="contained"
                        onClick={handleChange}>
                        {`${
                          currentCp?.fuOptedIn && currentCp?.fuEventId
                            ? "Change"
                            : "Create"
                        } Schedule/Prefrence`}
                      </Button>
                      <Button
                        className="col-5"
                        variant="contained"
                        color="error"
                        onClick={() => openOptOut()}>
                        Opt Out of Follow Up
                      </Button>
                      <Grow
                        in={checked}
                        style={
                          !checked
                            ? { transformOrigin: "0 0 0", display: "none" }
                            : { transformOrigin: "0 0 0" }
                        }
                        {...(checked ? { timeout: 1000 } : {})}>
                        <Paper sx={{ padding: "10px", marginTop: "10px" }}>
                          <div className="col-12 mb-4 text-center  w-100 fw-bold"></div>
                          <div className="col-12">
                            <label className="form-label">Start Date</label>
                            <div
                              className="col-12 mb-2"
                              style={{ maxWidth: "unset" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  onChange={handleDateChange}
                                  value={inputs.startDate}
                                />
                              </LocalizationProvider>
                            </div>

                            <RecurrenceEditorComponent
                              id="RecurrenceEditor"
                              ref={recObject}
                              value={recurrenceRule}
                              change={onChange}
                              frequencies={[
                                "daily",
                                "weekly",
                                "monthly",
                                "yearly",
                              ]}
                            />
                          </div>
                          <div className="col-12 mb-3">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                dayjs(inputs.startDate).format(
                                  "YYYY-MM-DD HH:mm"
                                ) ===
                                  dayjs(fuEvent.StartTime).format(
                                    "YYYY-MM-DD HH:mm"
                                  ) && recurrenceRule === recString
                              }
                              sx={{ float: "right", minWidth: "13rem" }}
                              onClick={handleUpdateSchedule}>
                              {currentCp?.fuOptedIn && currentCp?.fuEventId
                                ? "Update"
                                : "Create"}{" "}
                              Schedule
                            </Button>
                          </div>

                          <br />
                          <hr />

                          <label className="form-label">
                            Follow Up Preference
                          </label>

                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            name="location"
                            onChange={handleInputsChange}
                            defaultValue={inputs.location}
                            value={inputs.location}>
                            <option value="phone">Phone call follow up</option>
                            <option value="form">Form follow up</option>
                          </select>
                          <div className="col-12 ">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                (inputs.location === "phone" &&
                                  currentCp.isPhoneFu === 1) ||
                                (inputs.location === "form" &&
                                  currentCp.isPhoneFu === 0)
                              }
                              sx={{ float: "right", minWidth: "13rem" }}
                              onClick={handleUpdatePreference}>
                              Update Preference
                            </Button>
                          </div>
                        </Paper>
                      </Grow>
                    </div>
                  </div>
                  {combinedData.length > 0 ? (
                    <div className="col-12 mt-3 mb-3 dg_2liner">
                      <div className="my-3 fw-bold fs-5">
                        <p>Follow Up Summary</p>
                      </div>
                      <DataGrid
                        rows={combinedData}
                        columns={columnDef}
                        slots={{ toolbar: GridToolbar }}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                          },
                        }}
                        pageSizeOptions={[5, 100]}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="col-md-9 col-12">
                <div className="row">
                  {/* build ui for telling the user that patient has opted out and action button to opt in the patient again
                   */}
                  <div className="col-12">
                    <Alert severity="warning" className="mb-3">
                      Patient has opted out of follow up
                    </Alert>

                    <div className="col-12 d-flex justify-content-center  ">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => reOptOpen()}>
                        Opt In for Follow Up
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className="col-md-3 col-12">
          <div>
            {currentCp?.fuOptedIn && currentCp?.fuEventId ? (
              <Alert severity="warning">
                Patient Already Has Follow Up Scheduled. Click
                <Button
                  sx={{ padding: "0", margin: "0" }}
                  variant="text"
                  onClick={() => {
                    setOpenDates((prev) => !prev);
                  }}>
                  HERE
                </Button>
                to find the follow up dates.
                <Grow
                  in={openDates}
                  style={
                    !openDates
                      ? { transformOrigin: "0 0 0", display: "none" }
                      : { transformOrigin: "0 0 0" }
                  }
                  {...(openDates ? { timeout: 1000 } : {})}>
                  <List sx={{ maxHeight: "200px", overflowY: "scroll" }}>
                    {combinedData.map((ev) => (
                      <ListItem key={ev.id}>
                        {dayjs(ev.date).format("MM/DD/YYYY")}
                      </ListItem>
                    ))}
                  </List>
                </Grow>
              </Alert>
            ) : (
              ""
            )}
          </div>
          <br />
          <div>
            {currentCp?.fuOptedIn && currentCp?.isPhoneFu ? (
              <Alert severity="warning">
                Patient Opted-In For Phone Follow Up
              </Alert>
            ) : (
              ""
            )}
          </div>

          <div>
            {currentCp?.fuOptedIn && !currentCp?.isPhoneFu ? (
              <Alert severity="warning">
                Patient Opted-In For Follow Up By Form
              </Alert>
            ) : (
              ""
            )}
          </div>
          <Grid>
            <Paper sx={{ minHeight: "10rem", padding: "10px" }}>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#a8beda",
                      fontSize: "large",
                    }}>
                    Patient Information
                  </ListSubheader>
                }>
                <ListItemText
                  primary={`First Name: ${currentCp.recipient.first}`}
                />
                <ListItemText
                  primary={`Last Name: ${currentCp.recipient.last}`}
                />
                <ListItemText
                  primary={`Date Of Birth: ${currentCp.recipient.DOB}`}
                />
                <ListItemText
                  primary={`Gender:  ${currentCp.recipient.gender}`}
                />
                <ListItemText primary={`MRN:  ${currentCp.recipient.MRN}`} />
                <ListItemText
                  primary={`Email Address:  ${currentCp.recipient.email}`}
                />
                <ListItemText
                  primary={`Phone Number: ${currentCp.recipient.phone}`}
                />
              </List>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#a8beda",
                      fontSize: "large",
                    }}>
                    Initial Form
                  </ListSubheader>
                }>
                <ListItemText
                  className={
                    currentCp.initForm.json.title ? "clickable_text" : ""
                  }
                  onClick={() => {
                    formViewClickHandler(0);
                  }}
                  primary={`Title : ${
                    currentCp?.initForm
                      ? JSON.parse(currentCp?.initForm?.formText)?.title
                      : ""
                  }`}
                />
              </List>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#a8beda",
                      fontSize: "large",
                    }}>
                    Follow Up Form
                  </ListSubheader>
                }>
                <ListItemText
                  className={
                    currentCp.fuForm.json.title ? "clickable_text" : ""
                  }
                  onClick={() => {
                    formViewClickHandler(1);
                  }}
                  primary={`Title :  ${
                    currentCp?.fuForm
                      ? JSON.parse(currentCp?.fuForm?.formText)?.title
                      : ""
                  }`}
                />
              </List>
            </Paper>
          </Grid>
        </div>
      </div>
      <Modal>
        {
          <div
            className="card p-3"
            style={{
              width: "80vw",
              height: "80vh",
            }}>
            <button
              onClick={() => {
                close();
                setCurrentForm(null);
              }}
              className="btn btn-outline-danger mb-4 p-1"
              style={{ width: "2.1rem" }}>
              <CloseIcon />
            </button>
            {currentForm ? (
              <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                <ReviewResponse data={currentForm} />
              </div>
            ) : null}
          </div>
        }
      </Modal>
      <ModalOptOut>
        <div
          className="card p-3"
          style={{
            width: "50vw",
            height: "30vh",
          }}>
          <div className="card-header text-center">
            <h5>Follow Up Opt Out</h5>
          </div>

          <div className="col-12 mb-4 text-center mt-5">
            <h5>
              Are you sure you want to opt out{" "}
              <span className="fw-bold">{`${currentCp.recipient.first} ${currentCp.recipient.last}`}</span>{" "}
              of follow up?
            </h5>
            <div className="d-flex justify-content-evenly mt-5">
              <Button
                variant="contained"
                color="success"
                sx={{ float: "right" }}
                onClick={handleOptOut}>
                Yes
              </Button>

              <Button
                variant="contained"
                color="error"
                sx={{ float: "right" }}
                onClick={() => {
                  closeOptOut();
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </ModalOptOut>
      <ReOptModal>
        <div
          className="card p-3"
          style={{
            width: "50vw",
            height: "30vh",
          }}>
          <div className="card-header text-center">
            <h5>Follow Up Opt In</h5>
          </div>
          <div className="col-12 mb-4 text-center mt-5">
            <h5>
              Are you sure you want to opt in{" "}
              <span className="fw-bold">{`${currentCp.recipient.first} ${currentCp.recipient.last}`}</span>{" "}
              for follow up?
            </h5>
            <div className="d-flex justify-content-evenly mt-5">
              <Button
                variant="contained"
                color="success"
                sx={{ float: "right" }}
                onClick={handleReOptIn}>
                Yes
              </Button>

              <Button
                variant="contained"
                color="error"
                sx={{ float: "right" }}
                onClick={() => {
                  reOptClose();
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </ReOptModal>
    </div>
  );
};

export default CpFollowUp;
