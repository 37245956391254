import React, { useState, useEffect } from "react";
import { SideBar } from "../../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";
import "../../../styles/commonStyles/nav.css";
import { getPharmaPrivileges } from "../../../services/Admin";

const Navigator = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"));

  const [isNavContentShown, setNavContentShown] = useState(false);
  const [privileges, setPrivileges] = useState([]);

  const handleClick = (index) => {
    setNavContentShown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    getPharmaPrivileges(pharmacyId)
      .then((res) => {
        setPrivileges(res.data.data.map((item) => item.name));
      })
      .catch((err) => {
        setPrivileges([]);
        console.error(err);
      });
  }, [pharmacyId]);

  return (
    <div className={isDivVisible ? "" : "toggle-Navigation"} id="side-bar-nav">
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              {privileges.includes("Dashboard") && (
                <li
                  className={`nav-content 
                  `}>
                  <NavLink
                    to="/pharmacy-admin/dashboard"
                    className={`nav-link ${
                      isNavContentShown["Dashboard"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav">
                    <i
                      className="bi bi-speedometer2"
                      style={{ fontSize: "1.4rem" }}
                    />
                    Dashboard
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Admin"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Admin")}>
                  <span>Admin Tools</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Admin"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Admin"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-codes`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-fingerprint"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Register Codes</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/manage-users`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-video2"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Pharmacy Users</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/add-roles`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-badge-fill"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Roles Management</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/pharmacy-admin/pharmacy-info`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-image"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Pharmacy Information</span>
                    </NavLink>
                  </li>
                  {privileges.includes("Messages") && (
                    <li>
                      <NavLink
                        to={`/pharmacy-admin/messages-groupe`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-wechat"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Manage Messaging groups</span>
                      </NavLink>
                    </li>
                  )}
                  {privileges.includes("Careplan Center") && (
                    <li>
                      <NavLink
                        to={`/pharmacy-admin/cp-owners`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className="bi bi-person-lines-fill"
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Manage CP-Owners</span>
                      </NavLink>
                    </li>
                  )}

                  <li>
                    <NavLink
                      to={`/pharmacy-admin/announcements`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-menu-button-wide"}`}
                        style={{ fontSize: "1.5rem" }}></i>

                      <span>Manage Announcements</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {privileges.some(
                (privilege) =>
                  privilege === "Manage Providers" ||
                  privilege === "Add Provider"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Provider"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Provider")}>
                    <span>Provider Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Provider"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Provider"] ? "show" : ""
                    }`}>
                    {privileges.includes("Add Provider") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/add-provider`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-person-plus-fill"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Provider</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Manage Providers") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/manage-providers`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people-fill"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Provider</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) =>
                  privilege === "Manage Patients" ||
                  privilege === "Add Patient" ||
                  privilege === "Add Bulk Patients"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Patient"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Patient")}>
                    <span>Patient Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Patient"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Patient"] ? "show" : ""
                    }`}>
                    {privileges.includes("Add Patient") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/add-patient`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-person-plus"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Patient</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Manage Patients") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/manage-patients`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Patients</span>
                        </NavLink>
                      </li>
                    )}
                    {privileges.includes("Add Bulk Patients") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/add-bulk-patients`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-people"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Add Bulk Patients</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) =>
                  privilege === "Messages" ||
                  privilege === "Bulk Messages" ||
                  privilege === "Birthday Messages"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Messages"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Messages")}>
                    <span>Messages Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Messages"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Messages"] ? "show" : ""
                    }`}>
                    {privileges.includes("Messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-dots"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Messages</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Bulk Messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/bulk-messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-square-quote"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Bulk Messages</span>
                        </NavLink>
                      </li>
                    )}
                    {privileges.includes("Birthday Messages") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/birthday-messages`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-chat-square-heart"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Birthday Messages</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) =>
                  privilege === "Manage Forms" ||
                  privilege === "Forms History" ||
                  privilege === "Forms Analytics"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Forms"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Forms")}>
                    <span>Forms Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Forms"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Forms"] ? "show" : ""
                    }`}>
                    {privileges.includes("Manage Forms") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/manage-forms`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-ui-checks"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Forms</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Forms History") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/forms-history`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-clock-history"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Forms History</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Forms Analytics") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/forms-analytics`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-graph-up"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Forms Analytics</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) =>
                  privilege === "Manage Documents" ||
                  privilege === "Documents History" ||
                  privilege === "Documents Analytics"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Documents"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Documents")}>
                    <span>Documents Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Documents"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Documents"] ? "show" : ""
                    }`}>
                    {privileges.includes("Manage Documents") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/manage-documents`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-files"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Documents</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Documents History") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/documents-history`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-file-earmark-medical"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Documents History</span>
                        </NavLink>
                      </li>
                    )}
                    {/* documents-analytics */}
                    {privileges.includes("Documents Analytics") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/documents-analytics`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-graph-up"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Documents Analytics</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) =>
                  privilege === "Manage Surveys" ||
                  privilege === "Survey Dissatisfaction"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Survey"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Survey")}>
                    <span>Survey Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Survey"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Survey"] ? "show" : ""
                    }`}>
                    {privileges.includes("Manage Surveys") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/manage-surveys`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-kanban"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Manage Surveys</span>
                        </NavLink>
                      </li>
                    )}

                    {privileges.includes("Survey Dissatisfaction") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/survey-dissatisfaction`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className={`${"bi bi-emoji-frown"}`}
                            style={{ fontSize: "1.5rem" }}></i>
                          <span>Survey Dissatisfaction</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {privileges.some(
                (privilege) => privilege === "Careplan Center"
              ) && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["CarePlan"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("CarePlan")}>
                    <span>Care Plan</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["CarePlan"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["CarePlan"] ? "show" : ""
                    }`}>
                    <li>
                      <NavLink
                        to={`/pharmacy-admin/cp-center/scheduler`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-calendar3"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Calendar</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/pharmacy-admin/cp-center/care-plan-manager`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className="bi bi-heart-pulse"
                          style={{ fontSize: "1.5rem" }}></i>
                        {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                        <span>Care Plan Manager</span>
                      </NavLink>
                    </li>
                    {/* cp-history */}
                    <li>
                      <NavLink
                        to={`/pharmacy-admin/cp-center/cp-history`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className="bi bi-file-earmark-medical"
                          style={{ fontSize: "1.5rem" }}></i>
                        {/* <HandshakeIcon
                        style={{ fontSize: "1.7rem", marginRight: "5px" }}
                      /> */}
                        <span>Care Plan History</span>
                      </NavLink>
                    </li>
                    {/* forms-lib */}
                    {privileges.includes("Careplan Forms") && (
                      <li>
                        <NavLink
                          to={`/pharmacy-admin/cp-center/forms-lib`}
                          className="nav-link "
                          data-bs-target="#components-nav">
                          <i
                            className="bi bi-collection-fill"
                            style={{ fontSize: "1.5rem" }}></i>

                          <span>Forms Library</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
