import React, { useEffect, useState } from "react";
import { SideBar, ProviderAddRxController } from "../../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../../styles/commonStyles/nav.css";
import { getProviderFeatures } from "../../../services/Provider";
import { decodeToken } from "react-jwt";

const Navigator = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const resetRx = ProviderAddRxController((state) => state.resetRx);
  const location = useLocation();
  const [isNavContentShown, setNavContentShown] = useState(false);
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const isDivVisible = SideBar((state) => state.isDivVisible);

  useEffect(() => {
    if (location.pathname !== "/provider/add-rx") {
      resetRx();
    }
    getProviderFeatures(userID)
      .then((res) => {
        setAllowedFeatures(res.data.features.map((el) => el.feature));
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClick = (index) => {
    setNavContentShown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  return (
    <div className={isDivVisible ? "" : "toggle-Navigation"} id="side-bar-nav">
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              <li
                className={`nav-content 
               `}>
                <NavLink
                  to="/provider/dashboard"
                  className={`nav-link ${
                    isNavContentShown["Dashboard"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav">
                  <i
                    className="bi bi-speedometer2"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Patient"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Patient")}>
                  <span>Patient Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Patient"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Patient"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/provider/add-patient`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-person-plus"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Add Patient</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/provider/manage-patients`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-people"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Patients</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Messages"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Messages")}>
                  <span>Messages Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Messages"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Messages"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/provider/messages`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-chat-dots"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Messages</span>
                    </NavLink>
                  </li>
                  {allowedFeatures.includes("bulk messages") && (
                    <li>
                      <NavLink
                        to={`/provider/bulk-messages`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-chat-square-quote"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Bulk Messages</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    isNavContentShown["Rx"] ? "active_group" : null
                  }`}
                  data-bs-target="#components-nav"
                  onClick={() => handleClick("Rx")}>
                  <span>Rx Center</span>
                  <i
                    className={`bi bi-chevron-${
                      isNavContentShown["Rx"] ? "up" : "right"
                    } ms-auto`}></i>
                </NavLink>
                <ul
                  className={`nav-content collapse ${
                    isNavContentShown["Rx"] ? "show" : ""
                  }`}>
                  <li>
                    <NavLink
                      to={`/provider/add-rx`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-prescription2"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Add Rx</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/provider/manage-rxs`}
                      className="nav-link "
                      data-bs-target="#components-nav">
                      <i
                        className={`${"bi bi-capsule"}`}
                        style={{ fontSize: "1.5rem" }}></i>
                      <span>Manage Rxs</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {allowedFeatures.includes("forms") && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Forms"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Forms")}>
                    <span>Forms Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Forms"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Forms"] ? "show" : ""
                    }`}>
                    <li>
                      <NavLink
                        to={`/provider/manage-forms`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-ui-checks"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Manage-forms</span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={`/provider/forms-history`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-clock-history"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Forms history</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/provider/forms-analytics`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-graph-up"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Forms analytics</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {allowedFeatures.includes("documents") && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Documents"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Documents")}>
                    <span>Documents Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Documents"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Documents"] ? "show" : ""
                    }`}>
                    <li>
                      <NavLink
                        to={`/provider/manage-documents`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-files"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Manage Documents</span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to={`/provider/documents-history`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-file-earmark-medical"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Documents history</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/provider/documents-analytics`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-graph-up"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Documents analytics</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {allowedFeatures.includes("survey") && (
                <li>
                  <NavLink
                    to="#"
                    className={`nav-link ${
                      isNavContentShown["Surveys"] ? "active_group" : null
                    }`}
                    data-bs-target="#components-nav"
                    onClick={() => handleClick("Surveys")}>
                    <span>Surveys Center</span>
                    <i
                      className={`bi bi-chevron-${
                        isNavContentShown["Surveys"] ? "up" : "right"
                      } ms-auto`}></i>
                  </NavLink>
                  <ul
                    className={`nav-content collapse ${
                      isNavContentShown["Surveys"] ? "show" : ""
                    }`}>
                    <li>
                      <NavLink
                        to={`/provider/manage-surveys`}
                        className="nav-link "
                        data-bs-target="#components-nav">
                        <i
                          className={`${"bi bi-kanban"}`}
                          style={{ fontSize: "1.5rem" }}></i>
                        <span>Manage Surveys</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
