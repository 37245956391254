import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/common/layout/Header";
import Navigator from "../components/provider/providerNavs/ProviderNav";
import Footer from "../components/common/layout/Footer";
import SessionTimeOut from "./../components/common/SessionTimeOut/SessionTimeOut";
import Dashboard from "../pages/provider/Dashboard";
import Profile from "../pages/provider/Profile";
import ProfilePictureUploader from "./../components/common/profile/ProfilePictureUploader";
import AddPatient from "./../pages/provider/AddPatient";
import ManagePatients from "./../pages/provider/ManagePatients";
import Messages from "./../pages/provider/Messages";
import ManageRxs from "./../pages/provider/RxCenter/ManageRxs";
import AddNewRx from "./../pages/provider/RxCenter/AddNewRx";
import NotFound from "./../components/common/NotFound";
import ContactUs from "./../components/common/ContactUs";
import ManageSurvey from "../pages/provider/SurveyCenter/ManageSurvey";
import SendSurvey from "../pages/provider/SurveyCenter/SendSurvey";
import EditAndReview from "../pages/provider/SurveyCenter/EditAndReview";
import Results from "../pages/provider/SurveyCenter/Results";
import Analytics from "../pages/provider/SurveyCenter/Analytics";
import FormsManage from "../pages/provider/FormsManage";
import FormsHistory from "./../pages/provider/FormsHistory";
import DocumentsManage from "./../pages/provider/DocumentsManage";
import DocumentsHistory from "./../pages/provider/DocumentsHistory";
import ProviderProtectedRoutes from "../utils/ProviderProtectedRoutes";
import NotAuthorized from "./../components/common/NotAuthorized";
import MassText from "./../pages/provider/MassText";
import FormsAnalytics from "../pages/provider/FormsAnalytics";
import DocumentsAnalytics from "./../pages/provider/DocumentsAnalytics";

const ProviderRoutes = () => {
  return (
    <div>
      <SessionTimeOut />
      <Header />
      <Navigator />
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile-picture" element={<ProfilePictureUploader />} />
        <Route path="add-patient" element={<AddPatient />} />
        <Route path="manage-patients" element={<ManagePatients />} />
        <Route path="messages" element={<Messages />} />
        <Route path="manage-rxs" element={<ManageRxs />} />
        <Route path="add-rx" element={<AddNewRx />} />
        <Route path="contact-us" element={<ContactUs />} />
        {/* =============== Provider Protected Routes Start ================= */}
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="survey" />}>
          <Route path="manage-surveys" element={<ManageSurvey />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="survey" />}>
          <Route path="manage-surveys/send-survey" element={<SendSurvey />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="survey" />}>
          <Route
            path="manage-surveys/edit-and-review"
            element={<EditAndReview />}
          />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="survey" />}>
          <Route path="manage-surveys/results" element={<Results />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="survey" />}>
          <Route path="manage-surveys/analytics" element={<Analytics />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="forms" />}>
          <Route path="manage-forms" element={<FormsManage />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="forms" />}>
          <Route path="forms-history" element={<FormsHistory />} />
        </Route>
        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="forms" />}>
          <Route path="forms-analytics" element={<FormsAnalytics />} />
        </Route>

        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="documents" />}>
          <Route path="manage-documents" element={<DocumentsManage />} />
        </Route>

        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="documents" />}>
          <Route path="documents-history" element={<DocumentsHistory />} />
        </Route>

        <Route
          path="/"
          element={<ProviderProtectedRoutes restrictedFeature="documents" />}>
          <Route path="documents-analytics" element={<DocumentsAnalytics />} />
        </Route>

        <Route
          path="/"
          element={
            <ProviderProtectedRoutes restrictedFeature="bulk messages" />
          }>
          <Route path="bulk-messages" element={<MassText />} />
        </Route>

        <Route path="notAuthorized" element={<NotAuthorized />} />
        {/* =============== Provider Protected Routes End ================= */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default ProviderRoutes;
