import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  searchPt,
  searchUsers,
  getProfile,
  sendForm,
  storeFormResponseLinkInDB,
  deleteFormResponse,
} from "../../../services/PharmacyUser";
import {
  sendSMS,
  sendEmail,
  urlShortner,
  getPharmacyById,
} from "../../../services/Common";
import baseURL from "../../../utils/baseURL";
import { decodeToken } from "react-jwt";

function RecipentsFromDB(props) {
  const pharmacy_id = localStorage.getItem("pharmacy");
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const token = localStorage.getItem("Token") || {};
  const myDecodedToken = decodeToken(token);
  const userId = myDecodedToken.userId;
  const [selected, setSelected] = useState([]);
  const [recepint, setRecepint] = useState("");
  const [suggistions, setSuggistions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [logflag, setLogflag] = useState(false);
  const [sfirsname, setSfirsname] = useState([]);
  const [slastname, setSlastname] = useState([]);
  const [smrn, setSmrn] = useState([]);
  const [instanceId, setInstanceId] = useState(props.instanceId || null);
  const [userType, setUserType] = useState("1");
  const [pharmacySMSNum, setPharmacySMSNum] = useState(null);
  const [smsLog, setSmsLog] = useState([]);

  useEffect(() => {
    if (props.instanceId) {
      setInstanceId(props.instanceId);
    }
  }, [props.instanceId]);

  useEffect(() => {
    let combinedResults = [...sfirsname, ...slastname, ...smrn];

    let nameToMrnMap = {};
    combinedResults.forEach((element) => {
      let [name, mrn] = element.split("-MRN: ");
      nameToMrnMap[name] = mrn ? `MRN: ${mrn}` : "";
    });

    let uniqueNames = Object.keys(nameToMrnMap);

    let uniqueUsers = uniqueNames.map((name) => {
      let mrn = nameToMrnMap[name];
      return mrn ? `${name}-${mrn}` : name;
    });

    setSuggistions(
      uniqueUsers
        .map((user) => {
          return selected.includes(user) ? null : user;
        })
        .filter((el) => el !== null) || []
    );
  }, [sfirsname, slastname, smrn, selected]);

  useEffect(() => {
    let c = [
      { field: "id", headerName: "ID", width: 80 },
      { field: "first", headerName: "First name", width: 100 },
      { field: "last", headerName: "Last name", width: 100 },
      {
        field: "phone",
        headerName: "Phone",
        width: 150,
        valueFormatter: (params) => (params.value ? params.value : "N/A"),
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        valueFormatter: (params) => (params.value ? params.value : "N/A"),
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        valueFormatter: (params) => (params.value ? "Success" : "Failed"),
      },
      { field: "msg", headerName: "Details", width: 700 },
    ];
    setColumns(c);

    getPharmacyById(pharmacy_id)
      .then((res) => {
        setPharmacySMSNum(res.data.smsPhoneNum);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pharmacy_id]);

  const handleSearch = (e) => {
    setRecepint(e.target.value);
    setSmsLog([]);

    try {
      let fnamesearch = `first=${e.target.value}&last=&MRN=`;

      searchUsers(pharmacy_id, fnamesearch).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.length > 0) {
            setSfirsname([
              ...sfirsname,
              ...res.data
                .filter((el) => el.id.toString() !== userId)
                .filter((el) => {
                  if (userType === "1") {
                    return el.type === "Patient" && el.active;
                  } else if (userType === "2") {
                    return el.type === "User" || el.type === "pharmacyAdmin";
                  } else {
                    return el.type === "Provider";
                  }
                })
                .map((el) => {
                  return `${el.id}-${el.first} ${el.last}`;
                }),
            ]);
          }
        }
      });
      let lnamesearch = `first=&last=${e.target.value}&MRN=`;
      searchUsers(pharmacy_id, lnamesearch).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.length > 0) {
            setSlastname([
              ...slastname,
              ...res.data
                .filter((el) => el.id.toString() !== userId)
                .filter((el) => {
                  if (userType === "1") {
                    return el.type === "Patient" && el.active;
                  } else if (userType === "2") {
                    return el.type === "User" || el.type === "pharmacyAdmin";
                  } else {
                    return el.type === "Provider";
                  }
                })
                .map((el) => {
                  return `${el.id}-${el.first} ${el.last}`;
                }),
            ]);
          }
        }
      });

      let mrnsearch = {
        first: "",
        last: "",
        email: "",
        phone: "",
        DOB: "",
        MRN: e.target.value,
      };
      searchPt(mrnsearch, pharmacy_id).then((res) => {
        console.log(res, "mrn");
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            setSmrn([
              ...smrn,
              ...res.data.data
                .filter(
                  (el) => el.user.id.toString() !== userId && el.user.active
                )
                .filter(() => {
                  if (userType === "1") {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((el) => {
                  return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
                }),
            ]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const hanleSetRecipents = () => {
    setLogflag(false);
    setSmsLog([]);
    if (recepint !== "" && !selected.includes(recepint)) {
      setSelected([...selected, recepint]);
      setSuggistions(suggistions.filter((el) => el !== recepint));
    }
    setRecepint("");
  };

  const heandleSend = () => {
    selected.forEach((el) => {
      getProfile(el.split("-")[0]).then((resp) => {
        let phone = resp?.data?.data?.user[0]?.phone
          ? resp.data.data.user[0].phone
          : "";
        let first = resp.data.data.user[0].first;
        let last = resp.data.data.user[0].last;
        let recId = resp.data.data.user[0].id;
        let email = resp?.data?.data?.user[0]?.email
          ? resp.data.data.user[0].email
          : "";

        if (phone.trim() !== "") {
          let clean = ("" + phone.trim()).replace(/\D/g, "");
          let match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
          const invcodes = [
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "001",
            "011",
            "002",
            "000",
          ];

          if (match) {
            if (invcodes.includes(match[1])) {
              phone = "";
            } else {
              phone = `${match[1]}${match[2]}${match[3]}`;
            }
          }
        }
        sendForm({
          formId:
            props.data.form.ID?.toString() || props.data.form.formId.toString(),
          senderId: userId,
          recipientId: recId.toString(),
          instanceId,
        })
          .then(async (re) => {
            let data = {
              date: new Date(),
              completed: "",
              PatientId: el.split("-")[0],
              Statusflag: "pending",
              FormId: props.data.form.ID || props.data.form.formId,
              Token: re.data.response_id,
              ActionUserId: "",
              pharmacyId: pharmacy_id,
              recipientName: `${first} ${last}`,
            };
            let longurl = `${baseURL.frontEnd}/appless-form/?fid=${
              props.data.form.ID ? props.data.form.ID : props.data.form.formId
            }&rid=${recId}&t=${
              re.data.response_id
            }&ph=${pharmacy_id}&i=${instanceId}`;

            const url = await urlShortner(longurl);

            const smsPayload = {
              to: phone,
              body: `Dear ${first} ${last}, You were sent a form from ${pharmacy_name} Pharmacy. Please take a moment to fill out the form linked below. Your responses will help us provide you with the best possible care. ${
                url ? url : longurl
              } , Thank you for your cooperation! If you would like to unsubscribe please send STOP`,
              from: pharmacySMSNum,
            };

            sendSMS(smsPayload)
              .then(() => {
                setSmsLog((prev) => [
                  ...prev,
                  {
                    id: recId,
                    first: first,
                    last: last,
                    msg: `Sent SMS to Phone`,
                    status: true,
                    phone: phone,
                    email: email,
                  },
                ]);
              })
              .catch(() => {
                sendEmail({
                  to: email,
                  message: `Dear ${first} ${last}, You were sent a form from ${pharmacy_name} Pharmacy. Please take a moment to fill out the form linked below. Your responses will help us provide you with the best possible care. ${
                    url ? url : longurl
                  } , Thank you for your cooperation!`,
                })
                  .then(() => {
                    setSmsLog((prev) => [
                      ...prev,
                      {
                        id: recId,
                        first: first,
                        last: last,
                        msg: `Sent to Email`,
                        status: true,
                        phone: phone,
                        email: email,
                      },
                    ]);
                  })
                  .catch((err) => {
                    setSmsLog((prev) => [
                      ...prev,
                      {
                        id: recId,
                        first: first,
                        last: last,
                        msg: `Failed to send Via Phone and Email`,
                        status: false,
                        phone: phone,
                        email: email,
                      },
                    ]);
                    deleteFormResponse(data.Token).catch((err) => {
                      console.error(err);
                    });
                  });
              })
              .finally(() => {
                storeFormResponseLinkInDB(
                  data.Token,
                  url ? url : longurl
                ).catch((err) => {
                  console.error(err);
                });
                setSelected([]);
                setSfirsname([]);
                setSlastname([]);
                setSmrn([]);
                setSuggistions([]);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      });

      //   Ready to send to the backend (sending instance)
    });
  };

  const handleTypeChange = (e) => {
    setUserType(e.target.value);
    setSelected([]);
    setSfirsname([]);
    setSlastname([]);
    setSmrn([]);
    setSuggistions([]);
    setRecepint("");
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div
            className="card-header fs-5"
            style={{
              textAlign: "center",
              color: "whitesmoke",
              background:
                "linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)",
            }}>
            Search for Recipients
          </div>
          <div className="card-body">
            <div className="input-group  mb-3 d-flex flex-column">
              <label htmlFor="userType" className="form-label">
                Recipient Type:
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                style={{ width: "200px" }}
                onChange={handleTypeChange}
                value={userType}>
                <option value="1">Patients</option>
                <option value="2">Pharmacy Users</option>
                <option value="3">Providers</option>
              </select>
            </div>

            <div className="input-group  mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Recipient's Name..."
                aria-label="Recipient's username with two button addons"
                list="datalistOptions"
                onChange={handleSearch}
                value={recepint}
              />
              <datalist id="datalistOptions">
                {suggistions.map((item, idx) => {
                  return <option key={idx} value={item} />;
                })}
              </datalist>
              <button
                className="btn btn-outline-secondary"
                onClick={hanleSetRecipents}
                type="button">
                Add
              </button>
            </div>
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="Receipts"
              placeHolder="Receipts List..."
            />
            <Button
              variant="contained"
              style={{ float: "right", marginTop: "20px" }}
              onClick={heandleSend}
              disabled={selected.length === 0}>
              Send Form
            </Button>
          </div>
          <p style={{ textAlign: "center" }}>
            {smsLog.length > 0 ? (
              <>
                Succeeded: {smsLog.filter((log) => log.status).length} Failed:{" "}
                {smsLog.filter((log) => !log.status).length}
              </>
            ) : null}
            {smsLog.length > 0 ? (
              <>
                <Button
                  onClick={() => {
                    setLogflag(true);
                  }}
                  variant="text">
                  Log
                </Button>{" "}
              </>
            ) : null}
          </p>
        </div>
        {logflag ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
                marginBottom: "30px",
              }}>
              {logflag ? (
                <>
                  <div className="col-12 mb-4 px-1">
                    {smsLog.length > 0 && (
                      <div className="my-1" style={{ height: 500 }}>
                        <h5 className="text-center fw-bold">
                          Sent Forms Status Log
                        </h5>
                        <DataGrid
                          rows={smsLog}
                          columns={columns}
                          pageSize={10}
                          getRowClassName={(params) => {
                            return params.row.status
                              ? "bg-success text-white"
                              : "bg-danger text-white";
                          }}
                          density="compact"
                          components={{ Toolbar: GridToolbar }}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default RecipentsFromDB;
