import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/commonStyles/scheduler.css";
import dayjs from "dayjs";
import { decodeToken } from "react-jwt";
import { handleGetCarePlansByUserId } from "../../services/CarePlan";
import { createElement } from "@syncfusion/ej2-base";
import {
  handleGetEventById,
  handleGetFollowingEvents,
  handleAddEvent,
  handleUpdateEvent,
} from "../../services/Calendar";
import {
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  ScheduleComponent,
  Inject,
  Resize,
  DragAndDrop,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import RequestReschedule from "../../components/patient/CPCenter/RequestReschedule";

const Calendar = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const pharmacyId = Number(window.localStorage.getItem("pharmacy"));
  const navigate = useNavigate();

  const [careplans, setCareplans] = useState([]);
  const [events, setEvents] = useState([]);
  const [msg, setMsg] = useState({ text: "", type: "" });
  let scheduleObj = useRef(events);
  const eventSettings = { dataSource: events, enableTooltip: true };
  const [open, setOpen] = useState(false);
  const [selectedCarePlan, setSelectedCarePlan] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const recurrenceFromIso = (milliSecDate) => {
    return (
      dayjs(milliSecDate)
        .toISOString()
        .split(".")[0]
        .split("")
        .map((c) => (!isNaN(c) || c === "T" ? c : ""))
        .join("") + "Z"
    );
  };

  async function getEvents(eventsList) {
    const allEvents = [];

    return Promise.all(
      eventsList.map(async (eventId) => {
        if (!eventId) return;
        const event = await handleGetEventById(eventId);
        const followingEvents = await handleGetFollowingEvents(eventId);
        allEvents.push(event.data, ...followingEvents.data);
      })
    ).then(() => allEvents);
  }

  function getCompleteButtonStatus(event) {
    if (event.isInitEvent) return false;
    if (event.Status) return false;
    if (
      !dayjs(event.StartTime).isBetween(
        dayjs(),
        dayjs().subtract(30, "days"),
        "day",
        "[]"
      )
    )
      return false;
    if (
      event.Location === "phone" &&
      !dayjs().isAfter(dayjs(event.StartTime).add(1, "hour"))
    )
      return false;
    return true;
  }

  function getReqButtonButtonStatus(event) {
    if (event.isInitEvent) return false;
    if (event.Status) return false;
    if (event.Location === "form") return false;

    if (dayjs().isAfter(dayjs(event.StartTime).subtract(30, "hours")))
      return false;

    return true;
  }

  useEffect(() => {
    handleGetCarePlansByUserId(userID)
      .then(async (res) => {
        const cps = res.data;
        setCareplans(cps);
        const eventsList = cps.map((cp) => [cp.fuEventId, cp.initEventId]);
        const flatEventList = [...new Set(eventsList.flat(1))];
        const allEventsFromDB = await getEvents(flatEventList);
        const allEventsWithColors = allEventsFromDB.map((event) => ({
          ...event,
          CategoryColor: event.Status ? "#3ba040" : "#ff8800",
        }));
        setEvents(allEventsWithColors);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userID]);

  const onActionBegin = (args) => {
    if (
      args.requestType === "eventCreate" ||
      args.requestType === "eventRemove"
    ) {
      setMsg({
        text: "No events can be created or deleted. please contact your pharmacy if you need further assistance.",
        type: "danger",
      });
      args.cancel = true;
      setTimeout(() => {
        setMsg({ text: "", type: "" });
      }, 7000);
    }

    if (args.requestType === "eventChange") {
      console.log("eventChange", args);
      const event =
        events.find((event) => event.Id === args.changedRecords[0].Id) ||
        args.changedRecords[0];

      console.log("event from change", event);
      if (event && event.isInitEvent) {
        setMsg({
          text: "This event is read only. please contact your pharmacy if you need further assistance.",
          type: "danger",
        });
        args.cancel = true;
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 7000);
      } else if (event && event.Status) {
        setMsg({
          text: "This event is already filled. please contact your pharmacy if you need further assistance.",
          type: "danger",
        });
        args.cancel = true;
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 7000);
      } else if (event && event.Location === "phone") {
        setMsg({
          text: "This is a phone follow up and can not be changed directly. please submit a request to reschedule or contact the pharmacy for further assistance.",
          type: "danger",
        });
        args.cancel = true;
        setTimeout(() => {
          setMsg({ text: "", type: "" });
        }, 7000);
      }
    }
  };

  const onActionComplete = (args) => {
    if (args.requestType) {
      if (args.requestType === "eventChanged") {
        if (args.addedRecords.length > 0) {
          // update parent by id
          // create new event using the occurrence instance
          const postPayload = { ...args.addedRecords[0], creatorId: userID };
          postPayload.Guid && delete postPayload.Guid;
          postPayload.Id && delete postPayload.Id;
          handleAddEvent(postPayload)
            .then(() => {
              const putPayload = {
                ...args.changedRecords[0],
                updatedBy: userID,
                isInitEvent: null,
              };
              putPayload.Guid && delete putPayload.Guid;
              putPayload.Id && delete putPayload.Id;
              args.changedRecords.length &&
                handleUpdateEvent(args.changedRecords[0].Id, putPayload)
                  .then(() => {
                    const newEvents = events.map((event) => {
                      if (event.Id === args.changedRecords[0].Id) {
                        return { ...event, ...args.changedRecords[0] };
                      }
                      return event;
                    });
                    newEvents.push({
                      Id: args.addedRecords[0].Id,
                      creatorId: userID,
                      pharmacyId,
                      Subject: args.addedRecords[0].Subject || null,
                      StartTime: args.addedRecords[0].StartTime || null,
                      EndTime: args.addedRecords[0].EndTime || null,
                      IsAllDay: args.addedRecords[0].IsAllDay || null,
                      Description: args.addedRecords[0].Description || null,
                      Location: args.addedRecords[0].Location || null,
                      CategoryColor: args.addedRecords[0].CategoryColor || null,
                      Priority: args.addedRecords[0].Priority || null,
                      RecurrenceRule:
                        args.addedRecords[0].RecurrenceRule || null,
                      RecurrenceException:
                        args.addedRecords[0].RecurrenceException || null,
                      RecurrenceID: args.addedRecords[0].RecurrenceID || null,
                      StartTimezone: args.addedRecords[0].StartTimezone || null,
                      EndTimezone: args.addedRecords[0].EndTimezone || null,
                      OwnerId: args.addedRecords[0].OwnerId || null,
                      FollowingID: args.addedRecords[0].FollowingID || null,
                      IsReadonly: args.addedRecords[0].IsReadonly || null,
                      IsBlock: args.addedRecords[0].IsBlock || null,
                      isInitEvent:
                        args.addedRecords[0].isInitEvent === undefined
                          ? null
                          : args.addedRecords[0].isInitEvent === null
                          ? null
                          : args.addedRecords[0].isInitEvent,
                      patientName: args.addedRecords[0].patientName || null,
                      Status: args.addedRecords[0].Status || false,
                    });
                    setEvents(newEvents.filter((el) => !el.Guid));
                    scheduleObj.current.eventSettings.dataSource =
                      newEvents.filter((el) => !el.Guid);
                  })
                  .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } else {
          // update parent by id
          const payload = {
            ...args.changedRecords[0],
            updatedBy: userID,
            isInitEvent:
              args.changedRecords[0].isInitEvent === null
                ? null
                : args.changedRecords[0].isInitEvent === undefined
                ? null
                : args.changedRecords[0].isInitEvent,
          };
          payload.Guid && delete payload.Guid;
          payload.Id && delete payload.Id;
          handleUpdateEvent(args.changedRecords[0].Id, payload)
            .then(() =>
              setEvents(
                events.map((event) => {
                  if (event.Id === args.changedRecords[0].Id) {
                    return { ...event, ...args.changedRecords[0] };
                  }
                  return event;
                })
              )
            )
            .catch((err) => console.error(err));
        }
      }
    }
  };

  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      const locationField = args.element.querySelector(
        'input[name="Location"]'
      );
      if (locationField) {
        locationField.readOnly = true; // Set the Location field as read-only
      }
      const SubjectField = args.element.querySelector('input[name="Subject"]');
      if (SubjectField) {
        SubjectField.readOnly = true; // Set the Location field as read-only
      }
    } else if (args.type === "QuickInfo") {
      // create a button to request fu change and append to the quick popup
      if (args.element.querySelector(".e-event-popup")) {
        let button = createElement("button", {
          className: "e-btn",
          innerHTML: "Fill Form",
        });

        let reqButton = createElement("button", {
          className: "e-btn ml-5",
          innerHTML: "Request Follow Up Change",
          styles: "color: red",
        });

        button.addEventListener("click", () => {
          // open the history modal
          const event = events.find((el) => el.Id === args.data.Id);
          const cp = careplans.find(
            (el) =>
              el.fuEventId === event.RecurrenceID || el.fuEventId === event.Id
          );

          navigate(
            `/patient/cp-fu-form/?id=${
              cp.id
            }&uid=${userID}&except=${recurrenceFromIso(
              new Date(args.data.StartTime).getTime()
            )}&d=${new Date(args.data.StartTime).getTime()}`
          );
        });

        reqButton.addEventListener("click", () => {
          // open the history modal
          const event = events.find((el) => el.Id === args.data.Id);
          const cp = careplans.find(
            (el) =>
              el.fuEventId === event.RecurrenceID || el.fuEventId === event.Id
          );
          console.log(cp, "cp");
          console.log(event, "event");
          let payload = {
            ...cp,
            thisEvent: args.data.StartTime,
            thisEventId: args.data.Id,
            ...event,
          };
          setSelectedCarePlan(payload);
          handleClickOpen();
        });

        if (!args.data?.isInitEvent && getCompleteButtonStatus(args.data)) {
          args.element.querySelector(".e-event-popup").appendChild(button);
        }

        if (!args.data?.isInitEvent && getReqButtonButtonStatus(args.data)) {
          args.element.querySelector(".e-event-popup").appendChild(reqButton);
        }
      }

      if (args.element.querySelector(".e-popup-content")) {
        // create view to display priority and patient name

        let statusContainer = createElement("div", {
          className: "e-resource",
        });

        let firstStatusDiv = createElement("div", {
          className: "e-circle-check e-icons",
          styles: `color: ${args.data.Status ? "#3ba040" : "#ff8800"}`,
        });

        let secondStatusDiv = createElement("div", {
          className: "e-resource-details e-text-ellipsis",
          innerHTML: `${args.data.Status ? "Filled" : "To Be Filled"}`,
          styles: `color: ${
            args.data.Status ? "#3ba040" : "#ff8800"
          }; font-weight: bold`,
        });

        statusContainer.appendChild(firstStatusDiv);
        statusContainer.appendChild(secondStatusDiv);

        args.element
          .querySelector(".e-popup-content")
          .appendChild(statusContainer);
      }
    }
  };

  const onEventRendered = (args) => {
    args.element.style.backgroundColor = args.data.CategoryColor;
  };

  console.log(selectedCarePlan);
  return (
    <div>
      {msg.text && (
        <div className={`alert alert-${msg.type}`} role="alert">
          {msg.text}
        </div>
      )}
      <div className="schedule-control-section row">
        <div className="col-12 control-section">
          <div className="control-wrapper">
            <ScheduleComponent
              id="schedule"
              cssClass="resource"
              selectedDate={new Date()}
              ref={scheduleObj}
              eventSettings={eventSettings}
              width="100%"
              height="550px"
              currentView="Month"
              actionComplete={onActionComplete}
              actionBegin={onActionBegin}
              popupOpen={onPopupOpen}
              eventRendered={onEventRendered}>
              <ViewsDirective>
                <ViewDirective option="Month" />
                <ViewDirective option="WorkWeek" />
                <ViewDirective option="Day" />
                <ViewDirective option="Agenda" />
                <ViewDirective option="Resize" />
                <ViewDirective option="DragAndDrop" />
              </ViewsDirective>
              <Inject
                services={[
                  Day,
                  Week,
                  WorkWeek,
                  Month,
                  Agenda,
                  Resize,
                  DragAndDrop,
                ]}
              />
            </ScheduleComponent>
          </div>
          <RequestReschedule
            open={open}
            handleClose={handleClose}
            selectedCarePlan={selectedCarePlan}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
