import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../utils/globalState";
import { addNewProvider, NPI } from "../../services/PharmacyUser";
import handleAddressAutoComplete from "./../../utils/addressAutoComplete";
import { decodeToken } from "react-jwt";
import { getUserById } from "../../services/Common";

const initialForm = {
  first: "",
  last: "",
  email: "",
  phone: "",
  referralSource: "",
  DEA: "",
  NPI: "",
  stateLicense: "",
  website: "",
  faxNumber: "",
  address: [
    {
      name: "",
      relation: "",
      street: "",
      street2: "",
      city: "",
      zip: "",
      state: "",
    },
  ],
  ContactInfo: [
    {
      emName: "",
      emRelation: "",
      altPhone: "",
    },
  ],
  pharmacyID: "",
};

const AddProvider = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const pharmacy = localStorage.getItem("pharmacy");

  const [form, setForm] = useState(initialForm);
  const [errMsg, setErrMsg] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [npiSuggestions, setNpiSuggestions] = useState([]);
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  const handleGetNpi = (e) => {
    const { name, value } = e.target;

    if (name === "NPI" && value !== "") {
      NPI(value, "", "").then((res) => {
        setNpiSuggestions(res.data);
      });
    } else if (name === "first" && value !== "") {
      NPI("", value, "").then((res) => {
        setNpiSuggestions(res.data);
      });
    } else if (name === "last" && value !== "") {
      NPI("", "", value).then((res) => {
        setNpiSuggestions(res.data);
      });
    }
  };

  const handleGetAddress = (e) => {
    const { value } = e.target;
    if (!value) {
      setAddressSuggestions([]);
    } else {
      handleAddressAutoComplete(value)
        .then((res) => {
          setAddressSuggestions(res);
        })
        .catch((err) => console.error(err));
    }
  };

  const changeHandler = (e) => {
    if (
      ["street", "street2", "city", "zip", "state"].some(
        (el) => el === e.target.name
      )
    ) {
      if (e.target.name === "street" && e.target.value.split(",")) {
        let addrauto = e.target.value.split(",");
        let state = ["", ""];
        if (addrauto.length > 1) {
          state =
            addrauto[addrauto.length - 1].split(" ").length > 1
              ? addrauto[addrauto.length - 1].split(" ")
              : ["", ""];
        }
        setForm({
          ...form,
          address: [
            {
              ...form.address[0],
              street: addrauto[0],
              city: addrauto[1],
              state: state[0],
              zip: state[1],
            },
          ],
        });
      } else {
        setForm({
          ...form,
          address: [{ ...form.address[0], [e.target.name]: e.target.value }],
        });
      }
    } else if (["NPI", "first", "last"].some((el) => el === e.target.name)) {
      if (e.target.value.split(",")[0].split(":")[1]) {
        let first = e.target.value.split(",")[0].split(":")[1];
        let last = e.target.value.split(",")[1].split(":")[1];
        let npi = e.target.value.split(",")[2].split(":")[1];
        let addressFromNpi = npiSuggestions.filter(
          (el) => el.number === npi.trim()
        );

        setForm({
          ...form,
          NPI: npi.trim(),
          first: first.trim(),
          last: last.trim(),
          phone:
            addressFromNpi.length > 0 &&
            addressFromNpi[0]?.addresses[0]?.telephone_number
              ? addressFromNpi[0].addresses[0].telephone_number.replace(
                  // eslint-disable-next-line no-useless-escape
                  /[^0-9\.]+/g,
                  ""
                )
              : form.phone,
          address:
            addressFromNpi.length > 0
              ? [
                  {
                    ...form.address[0],
                    street: addressFromNpi[0].addresses[0].address_1,
                    city: addressFromNpi[0].addresses[0].city,
                    state: addressFromNpi[0].addresses[0].state,
                    zip: addressFromNpi[0].addresses[0].postal_code,
                  },
                ]
              : [{ ...form.address }],
        });
      } else {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
    setErrMsg("");
  };

  const getPortalURL = () => {
    const { type } = user;

    if (type?.toLowerCase() === "admin") {
      return "admin";
    } else if (type?.toLowerCase() === "pharmacyadmin") {
      return "pharmacy-admin";
    } else if (type?.toLowerCase() === "user") {
      return "pharmacy-user";
    } else if (type?.toLowerCase() === "provider") {
      return "provider";
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    addNewProvider(form)
      .then(() => {
        setForm(initialForm);
        navigate(`/${getPortalURL()}/manage-providers`);
      })
      .catch((err) => setErrMsg(err.response.data.message));
  };

  useEffect(() => {
    setForm({ ...form, pharmacyID: pharmacy });
    getUserById(myDecodedToken.userId)
      .then((res) => {
        setUser(res.data.data.user[0]);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Provider</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Provider Center</li>
              <li className="breadcrumb-item active">Add Provider</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {" "}
            <div className="container-fluid">
              <form className="card p-3" onSubmit={submitHandler}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="first" className="form-label pt-1">
                      First Name&nbsp;<span className="requiredField">*</span>
                    </label>
                    <input
                      name="first"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="first"
                      placeholder="ex: John"
                      value={form.first}
                      onKeyUp={handleGetNpi}
                      list="npiSuggestionsName"
                      required
                    />
                    <datalist id="npiSuggestionsName">
                      {npiSuggestions?.length > 0 ? (
                        npiSuggestions.map((item, key) => (
                          <option
                            key={key}
                            value={`First Name: ${item.basic.first_name},---Last Name: ${item.basic.last_name},---NPI: ${item.number}`}
                          />
                        ))
                      ) : (
                        <>No Data</>
                      )}
                    </datalist>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="last" className="form-label pt-1">
                      Last Name&nbsp;<span className="requiredField">*</span>
                    </label>
                    <input
                      name="last"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="last"
                      placeholder="ex: Smith"
                      value={form.last}
                      onKeyUp={handleGetNpi}
                      list="npiSuggestionslName"
                      required
                    />
                    <datalist id="npiSuggestionslName">
                      {npiSuggestions.length > 0 ? (
                        npiSuggestions.map((item, key) => (
                          <option
                            key={key}
                            value={`First Name: ${item.basic.first_name},---Last Name: ${item.basic.last_name},---NPI: ${item.number}`}
                          />
                        ))
                      ) : (
                        <>No Data</>
                      )}
                    </datalist>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="form-label pt-1">
                      Email Address&nbsp;
                      <span className="requiredField">*</span>
                    </label>
                    <input
                      name="email"
                      onChange={changeHandler}
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="ex: name@example.com"
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="website" className="form-label pt-1">
                      WebSite
                    </label>
                    <input
                      name="website"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="website"
                      placeholder="ex: www.provider.com"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="NPI" className="form-label pt-1">
                      NPI&nbsp;<span className="requiredField">*</span>
                    </label>
                    <input
                      onKeyUp={handleGetNpi}
                      name="NPI"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="NPI"
                      value={form.NPI}
                      list="npiSuggestionsNpi"
                      required
                    />
                    <datalist id="npiSuggestionsNpi">
                      {npiSuggestions.length > 0 ? (
                        npiSuggestions.map((item, key) => (
                          <option
                            key={key}
                            value={`First Name: ${item.basic.first_name},---Last Name: ${item.basic.last_name},---NPI: ${item.number}`}
                          />
                        ))
                      ) : (
                        <>No Data</>
                      )}
                    </datalist>
                  </div>
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="DEA" className="form-label pt-1">
                      DEA
                    </label>
                    <input
                      name="DEA"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="DEA"
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="stateLicense" className="form-label pt-1">
                      State License&nbsp;
                      <span className="requiredField">*</span>
                    </label>
                    <input
                      name="stateLicense"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="stateLicense"
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="referralSource" className="form-label pt-1">
                      Referral Source
                    </label>
                    <input
                      name="referralSource"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="referralSource"
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="phone" className="form-label pt-1">
                      Phone&nbsp;<span className="requiredField">*</span>
                    </label>
                    <input
                      name="phone"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="phone"
                      value={form.phone}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6 ">
                    <label htmlFor="faxNumber" className="form-label pt-1">
                      Fax Number
                    </label>
                    <input
                      name="faxNumber"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="faxNumber"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="street" className="form-label pt-1">
                      Street
                    </label>
                    <input
                      onKeyUp={handleGetAddress}
                      name="street"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="street"
                      placeholder="ex: 123 Main street"
                      list="addressesList"
                      value={form.address[0].street}
                    />
                    <datalist id="addressesList">
                      {addressSuggestions.length > 0 ? (
                        addressSuggestions.map((item, key) => (
                          <option
                            key={key}
                            value={`${item.street},${item.city},${item.state} ${item.postal}`}
                          />
                        ))
                      ) : (
                        <>No Data</>
                      )}
                    </datalist>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="street2" className="form-label pt-1">
                      Street2
                    </label>
                    <input
                      onKeyUp={handleGetAddress}
                      name="street2"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="street2"
                      placeholder="ex: Apt 123"
                      list="addressesList"
                      value={form.address[0].street2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="city" className="form-label pt-1">
                      City
                    </label>
                    <input
                      name="city"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="city"
                      placeholder="ex: Los Angeles"
                      value={form.address[0].city}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="state" className="form-label pt-1">
                      State
                    </label>
                    <input
                      name="state"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="ex: CA"
                      value={form.address[0].state}
                    />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="zip" className="form-label pt-1">
                      Zip
                    </label>
                    <input
                      name="zip"
                      onChange={changeHandler}
                      type="text"
                      className="form-control"
                      id="zip"
                      placeholder="ex: 55555"
                      value={form.address[0].zip}
                    />
                  </div>
                </div>
                <div className="row d-flex flex-column justify-content-between my-4">
                  <button
                    type="submit"
                    className="btn btn-success col-12 col-sm-3 mx-auto">
                    Submit
                  </button>
                  {errMsg && (
                    <p className="text-center text-danger col-12 h6 errMSg mt-4">
                      {errMsg}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddProvider;
