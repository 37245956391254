import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { getNotificationsByUserID } from "../../services/Common";
import { useNavigate } from "react-router-dom";
import { convertTime, convertTimeLocally } from "../../utils/timeConverter";
import userImagePlaceHolder from "../../assets/images/patient_placeholder.png";
import { SocketIoServer } from "../../utils/globalState";
import { v4 } from "uuid";
import { RecurrenceEditor } from "@syncfusion/ej2-react-schedule";
import { getPharmacyById } from "./../../services/Common";
import {
  getResponses,
  getDocResponses,
  getTokens,
  getSurveyById,
} from "../../services/PharmacyUser";
import {
  getPatientFullInfo,
  addPtToSurvey,
  getSurveyPtById,
} from "../../services/Patient";
import {
  handleGetCarePlansByUserId,
  handleGetFormById,
  handleGetCpResponsesByCpId,
} from "../../services/CarePlan";
import {
  handleGetEventById,
  handleGetFollowingEvents,
} from "./../../services/Calendar";

const HomePage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const userId = myDecodedToken.userId;
  const pharmacyID = localStorage.getItem("pharmacy");

  const value = SocketIoServer.getState().SocketIoObj;
  const navigate = useNavigate();

  const [curUserInfo, setCurUserInfo] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [formsData, setFormsData] = useState([]);
  const [pendingForms, setPendingForms] = useState([]);
  const [docsData, setDocsData] = useState([]);
  const [pendingDocs, setPendingDocs] = useState([]);
  const [isFormsLoading, setIsFormsLoading] = useState(false);
  const [isDocsLoading, setIsDocsLoading] = useState(false);
  const [surveyPt, setSurveyPt] = useState({});
  const [ptSurveys, setPtSurveys] = useState([]);
  const [tokensList, setTokensList] = useState([]);
  const [isSurveysLoading, setIsSurveysLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [carePlanData, setCarePlanData] = useState([]);
  const [isCarePlanLoading, setIsCarePlanLoading] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState({});

  useEffect(() => {
    setSocket(value);
  }, [value]);

  const getNots = () => {
    getNotificationsByUserID(userId)
      .then((res) => {
        if (res.data.length !== 0) {
          setNotifications(
            res.data.filter(
              (note) =>
                note.receiver_id === myDecodedToken.userId.toString() &&
                note.sender_id !== myDecodedToken.userId.toString()
            )
          );
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setNotifications([]);
      });
  };

  useEffect(() => {
    if (socket !== null) {
      if (Object.keys(socket).length > 0) {
        socket.on("notifications", (data) => {
          getNots();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    setIsFormsLoading(true);
    setIsDocsLoading(true);

    getResponses(pharmacyID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",
              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",

              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completion: item.completion ? convertTime(item.completion) : "",
              sent: item.sent ? convertTime(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setFormsData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFormsLoading(false);
      });

    getDocResponses(pharmacyID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",

              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",
              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completion: item.completion ? convertTime(item.completion) : "",
              sent: item.sent ? convertTime(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setDocsData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsDocsLoading(false);
      });

    handleSurveyPt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyID]);

  useEffect(() => {
    getPatientFullInfo(userId)
      .then((res) => {
        setCurUserInfo({
          ...res.data[0].user,
          address: res.data[0].address[0],
          providers: res.data[0].providers,
        });
      })
      .catch((err) => {
        console.error("err", err);
      });
    getNots();
    getPharmacyById(pharmacyID)
      .then((res) => {
        setPharmacyInfo(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pharmacyID]);

  const daysPassed = (sentDateString) => {
    const diff = dayjs().diff(dayjs(sentDateString), "day");
    return diff;
  };

  useEffect(() => {
    if (formsData?.length) {
      const preFilterd = formsData.filter((el) => {
        return parseInt(el.recipient_ID) === userId;
      });

      //  ================= completed =================
      const completed = preFilterd.filter(
        (item) => item.status.toLowerCase() === "completed"
      );

      // ================= pending =================
      const pending = preFilterd.filter((item) => {
        return (
          item.status.toLowerCase() === "pending" &&
          daysPassed(item.sent) < 8 &&
          !completed.some(
            (el) =>
              el.form_ID === item.form_ID &&
              daysPassed(el.sent) < 8 &&
              dayjs(el.sent).isAfter(dayjs(item.sent))
          )
        );
      });

      const uniqueFormIDs = [];
      pending.forEach((item) => {
        if (!uniqueFormIDs.includes(item.form_ID)) {
          uniqueFormIDs.push(item.form_ID);
        }
      });
      const pendingFinal = uniqueFormIDs
        .map((formID) => {
          const formResponses = pending.filter((item) => {
            return item.form_ID === formID;
          });

          const mostRecentResponse = formResponses.reduce((prev, current) => {
            return prev.sent > current.sent ? prev : current;
          });
          return mostRecentResponse;
        })
        .sort((a, b) => {
          return new Date(b.sent) - new Date(a.sent);
        });

      setPendingForms(pendingFinal || []);
    }
  }, [formsData, userId]);

  useEffect(() => {
    if (docsData?.length) {
      const preFilterd = docsData.filter((el) => {
        return parseInt(el.recipient_ID) === userId;
      });
      //  ================= completed =================
      const completed = preFilterd.filter(
        (item) => item.status.toLowerCase() === "completed"
      );

      // ================= pending =================
      const pending = preFilterd.filter(
        (item) =>
          item.status.toLowerCase() === "pending" &&
          daysPassed(item.sent) < 8 &&
          !completed.some(
            (el) =>
              el.doc_ID === item.doc_ID &&
              daysPassed(el.sent) < 8 &&
              dayjs(el.sent).isAfter(dayjs(item.sent))
          )
      );
      const uniqueFormIDs = [];
      pending.forEach((item) => {
        if (!uniqueFormIDs.includes(item.doc_ID)) {
          uniqueFormIDs.push(item.doc_ID);
        }
      });
      const pendingFinal = uniqueFormIDs
        .map((docID) => {
          const formResponses = pending.filter((item) => {
            return item.doc_ID === docID;
          });

          const mostRecentResponse = formResponses.reduce((prev, current) => {
            return prev.sent > current.sent ? prev : current;
          });
          return mostRecentResponse;
        })
        .sort((a, b) => {
          return new Date(b.sent) - new Date(a.sent);
        });

      setPendingDocs(pendingFinal || []);
    }
  }, [docsData, userId]);

  //  ================= surveys =================

  const handleSurveyPt = () => {
    setIsSurveysLoading(true);
    getPatientFullInfo(userId)
      .then((r) => {
        let currentpt = r.data[0].user;

        let pttosurvey = {
          firstName: currentpt.first,
          lastName: currentpt.last,
          DOB: currentpt.DOB,
          MRN: currentpt.MRN,
          phone: currentpt.phone,
          providing_pharmacy: "River's Edge Pharmacy - PD",
          pharmacy_NPI: "",
          dispense_date: "",
          dispense_type: "",
        };

        addPtToSurvey(pttosurvey)
          .then((res) => {
            if (res.data.patinet_id) {
              getSurveyPtById(res.data.patinet_id).then((re) => {
                setSurveyPt(re.data.patient);
              });
            } else if (res.data.patient) {
              getSurveyPtById(res.data.patient[0].id).then((resp) => {
                setSurveyPt(resp.data.patient);
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .finally(() => {
        setIsSurveysLoading(false);
      });
  };

  const handleGetTokens = () => {
    setIsSurveysLoading(true);
    getTokens()
      .then((res) => {
        let uniqeTokens = [];
        res.data
          .filter(
            (token) =>
              (JSON.parse(token.token).ptid === surveyPt.id ||
                JSON.parse(token.token).ptid === userId) &&
              JSON.parse(token.token).resflag === false
          )
          .map((t) => {
            return JSON.parse(t.token);
          })
          .forEach((currentValue, i) => {
            if (
              uniqeTokens.find((ut) => ut.servid === currentValue.servid) ===
              undefined
            ) {
              uniqeTokens.push(currentValue);
            } else {
              let token = uniqeTokens.find(
                (ut) => ut.servid === currentValue.servid
              );
              if (token.date < currentValue.date) {
                uniqeTokens.push(currentValue);
                uniqeTokens.splice(uniqeTokens.indexOf(token), 1);
              }
            }
          });

        setTokensList(uniqeTokens);
      })
      .catch((err) => {
        setTokensList([]);
      })
      .finally(() => {
        setIsSurveysLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(surveyPt).length > 0 && surveyPt) {
      handleGetTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyPt]);

  useEffect(() => {
    if (tokensList.length > 0) {
      setIsSurveysLoading(true);
      Promise.all(
        tokensList.map((token, i) => {
          return getSurveyById(token.servid).then(async (res) => {
            return await {
              id: i + 1,
              survey: JSON.parse(res.data[0].survey),
              createdAt: convertTimeLocally(token.date),
              dispenseDate: token.dispenseDate,
              patient: surveyPt,
              surveyid: token.servid,
              token: token.token,
            };
          });
        })
      )
        .then((res) => {
          setPtSurveys(res);
        })
        .finally(() => {
          setIsSurveysLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensList]);

  const recurrenceFromIso = (milliSecDate) => {
    return (
      dayjs(milliSecDate)
        .toISOString()
        .split(".")[0]
        .split("")
        .map((c) => (!isNaN(c) || c === "T" ? c : ""))
        .join("") + "Z"
    );
  };

  const getAllCpData = async () => {
    setIsCarePlanLoading(true);
    const cp = await handleGetCarePlansByUserId(userId);
    cp.data
      .filter((cPitem) => cPitem.completedOn === null)
      .forEach((element) => {
        handleGetEventById(element.fuEventId)
          .then(async (res) => {
            let form = await handleGetFormById(element.fuFormId);
            let cPresP = await handleGetCpResponsesByCpId(element.id);

            let recObject = new RecurrenceEditor({
              change: (args) => {},
            });

            let ruleStringDates = recObject.getRecurrenceDates(
              new Date(res.data.StartTime),
              res.data.RecurrenceRule,
              res.data.RecurrenceException
            );
            const mainEventDates = ruleStringDates.map((d) => ({
              id: v4(),
              eventId: element.fuEventId,
              date: d,
              syncExcepDate: recurrenceFromIso(d),
              isMainEvent: true,
              response: {},
              form: form.data,
              formName: JSON.parse(form.data.formText).title,
              cpid: element.id,
              fueID: element.fuEventId,
              exp: res.data.RecurrenceException,
            }));
            const followingEvents = await handleGetFollowingEvents(
              element.fuEventId
            );
            let followingDates = followingEvents.data.map((ev) => ({
              id: v4(),
              eventId: ev.Id,
              date: new Date(ev.StartTime).getTime(),
              syncExcepDate: ev.RecurrenceException,
              isMainEvent: false,
              response: cPresP.data.find((r) => r.fuSubEventId === ev.Id),
              form: form.data,
              formName: JSON.parse(form.data.formText).title,
              cpid: element.id,
              fueID: element.fuEventId,
              exp: res.data.RecurrenceException,
            }));
            const allDates = [...mainEventDates, ...followingDates]
              .sort((a, b) => a.date - b.date)
              .filter((d) => {
                return (
                  dayjs(d.date).isBetween(
                    dayjs().subtract("1", "hour"),
                    dayjs().subtract(30, "days"),
                    "minute",
                    "[]"
                  ) && !d.response?.completedOn
                );
              });

            setCarePlanData((prev) => [...prev, ...allDates]);
          })
          .catch((err) => {
            console.error(err);
          });
      });
  };

  useEffect(() => {
    getAllCpData().finally(() => {
      setIsCarePlanLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div
      className={!isDivVisible ? "toggle-sidebar pt_homepage" : "pt_homepage"}>
      <main id="main" className="main">
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid pt_homePage ps-4 ps-md-1">
              {curUserInfo.first && (
                <div className="px-3">
                  <div className="row pt_info_box d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-2 text-center mb-2 mb-md-0 d-flex align-items-center justify-content-center">
                      <img
                        src={
                          curUserInfo.pic_url
                            ? `${curUserInfo.pic_url}?${Date.now()}`
                            : userImagePlaceHolder
                        }
                        alt="profile_pic"
                        className="rounded-3 pt_info_box_img"
                      />
                    </div>
                    <div className="col-10 d-flex flex-column flex-md-row fw-bolder justify-content-center align-items-center mb-4 mb-md-0">
                      <div className="col-12 col-md-6">
                        <p className="mb-3">
                          {curUserInfo.first} {curUserInfo.last}{" "}
                          {curUserInfo.DOB &&
                            `(${dayjs().diff(
                              dayjs(curUserInfo.DOB),
                              "year"
                            )})`}{" "}
                          {curUserInfo.gender}
                        </p>
                        <p>
                          {curUserInfo.address.street},{" "}
                          {curUserInfo.address.street2
                            ? curUserInfo.address.street2 + ", "
                            : ""}
                          {curUserInfo.address.city}
                        </p>
                        <p>
                          {curUserInfo.address.state}, {curUserInfo.address.zip}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p>MRN: {curUserInfo.MRN}</p>
                        <p>DOB: {curUserInfo.DOB}</p>
                        <p>Phone: {curUserInfo.phone}</p>
                        <p>Email: {curUserInfo.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row dashboard_container pt_main_container d-flex flex-column-reverse flex-md-row">
                <div className="col-md-6 col-xl-8">
                  <div className="card mb-3">
                    <h5 className="card-title py-2 pt_card_title">
                      Recent Messages
                    </h5>
                    <div className="card-body p-1">
                      <div className="">
                        {notifications.length > 0 ? (
                          notifications
                            .filter((el) => el.body === "message")
                            .reverse()
                            .map((el, idx) => {
                              return (
                                idx < 5 && (
                                  <div
                                    className="mx-0 mx-md-2 my-2 border border-1 pt_recent_msg_div"
                                    key={idx}
                                    onClick={() =>
                                      navigate("/patient/messages")
                                    }>
                                    <div className="card-body py-0 d-flex justify-content-between align-items-center">
                                      <p className="card-text my-2">
                                        <span className="recent_msg_sender">
                                          {el.sender_name}:
                                        </span>
                                        &nbsp;&nbsp;{el.message}
                                      </p>
                                      <small className="pt_msg_timestamp text-lowercase col-3 col-xl-2 text-end">
                                        {dayjs(el.createdAt).format(
                                          "MM/DD/YYYY hh:mm a"
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                )
                              );
                            })
                        ) : (
                          <p className="text-center">no new messages</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card my-3">
                    <h5 className="card-title py-2 pt_card_title">
                      Pending Forms
                    </h5>
                    <div className="card-body p-1">
                      <div className="">
                        {!isFormsLoading ? (
                          <>
                            {pendingForms.length > 0 ? (
                              <>
                                {pendingForms.map((el, idx) => {
                                  return (
                                    <div
                                      className=" mx-0 mx-md-2 my-2 border border-1 pt_recent_forms_div"
                                      key={idx}
                                      onClick={() =>
                                        navigate(
                                          `/patient/forms-center/${el.response_ID}`
                                        )
                                      }>
                                      <div className="card-body py-0 d-flex justify-content-between align-items-center">
                                        <p className="card-text my-2 ">
                                          <span className="recent_msg_sender">
                                            {el.formName}{" "}
                                          </span>
                                        </p>
                                        <small className="pt_msg_timestamp text-lowercase">
                                          {dayjs(el.sent).format(
                                            "MM/DD/YYYY hh:mm a"
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="text-center">No pending forms</p>
                            )}
                          </>
                        ) : (
                          <div className="text-center ">
                            <h3 className="text-center ">
                              Loading Data, Please wait...
                            </h3>
                            <div
                              className="spinner-border text-info"
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <h5 className="card-title py-2 pt_card_title">
                      Pending Documents
                    </h5>
                    <div className="card-body p-1">
                      <div className="">
                        {!isDocsLoading ? (
                          <>
                            {pendingDocs.length > 0 ? (
                              <>
                                {pendingDocs.map((el, idx) => {
                                  return (
                                    <div
                                      className=" mx-0 mx-md-2 my-2 border border-1 pt_recent_forms_div"
                                      key={idx}
                                      onClick={() =>
                                        navigate(
                                          `/patient/documents-center/${el.docResponse_ID}`
                                        )
                                      }>
                                      <div className="card-body py-0 d-flex justify-content-between align-items-center">
                                        <p className="card-text my-2 ">
                                          <span className="recent_msg_sender">
                                            {el.formName}{" "}
                                          </span>
                                        </p>{" "}
                                        <small className="pt_msg_timestamp text-lowercase">
                                          {dayjs(el.sent).format(
                                            "MM/DD/YYYY hh:mm a"
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="text-center">
                                No pending Documents
                              </p>
                            )}
                          </>
                        ) : (
                          <div className="text-center ">
                            <h3 className="text-center ">
                              Loading Data, Please wait...
                            </h3>
                            <div
                              className="spinner-border text-info"
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <h5 className="card-title py-2 pt_card_title">
                      Pending Surveys
                    </h5>
                    <div className="card-body p-1">
                      <div className="">
                        {!isSurveysLoading ? (
                          <>
                            {ptSurveys.length > 0 ? (
                              <>
                                {ptSurveys.map((el, idx) => {
                                  return (
                                    <div
                                      className=" mx-0 mx-md-2 my-2 border border-1 pt_recent_forms_div"
                                      key={idx}
                                      onClick={() =>
                                        navigate(`/patient/survey/${el.token}`)
                                      }>
                                      <div className="card-body py-0 d-flex justify-content-between align-items-center">
                                        <p className="card-text my-2 ">
                                          <span className="recent_msg_sender">
                                            {el.survey.title}
                                          </span>
                                        </p>
                                        <small className="pt_msg_timestamp text-lowercase">
                                          {dayjs(el.createdAt).format(
                                            "MM/DD/YYYY hh:mm a"
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="text-center">
                                No Surveys Available Yet
                              </p>
                            )}
                          </>
                        ) : (
                          <div className="text-center ">
                            <h3 className="text-center ">
                              Loading Data, Please wait...
                            </h3>
                            <div
                              className="spinner-border text-info"
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card my-3">
                    <h5 className="card-title py-2 pt_card_title">
                      Pending Careplan Forms
                    </h5>
                    <div className="card-body p-1">
                      <div className="">
                        {!isCarePlanLoading ? (
                          <>
                            {carePlanData.length > 0 ? (
                              <>
                                {carePlanData.map((el, idx) => {
                                  return (
                                    <div
                                      className=" mx-0 mx-md-2 my-2 border border-1 pt_recent_forms_div"
                                      key={idx}
                                      onClick={() =>
                                        navigate(
                                          `/patient/cp-fu-form/?id=${el.cpid}&uid=${userId}&except=${el.syncExcepDate}&d=${el.date}`
                                        )
                                      }>
                                      <div className="card-body py-0 d-flex justify-content-between align-items-center">
                                        <p className="card-text my-2 ">
                                          <span className="recent_msg_sender">
                                            {el.formName}{" "}
                                          </span>
                                        </p>
                                        <small className="pt_msg_timestamp text-lowercase">
                                          {dayjs(el.date).format(
                                            "MM/DD/YYYY hh:mm a"
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="text-center">No pending forms</p>
                            )}
                          </>
                        ) : (
                          <div className="text-center ">
                            <h3 className="text-center ">
                              Loading Data, Please wait...
                            </h3>
                            <div
                              className="spinner-border text-info"
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 aside_container">
                  <div className="card pb-1 mb-2 mb-md-0">
                    <h5 className="card-title py-2 pt_card_title">
                      Caregivers
                    </h5>
                    <div className="card-body px-1 py-0">
                      <h3 className="text-center fw-bold"> Pharmacy</h3>
                      <div className="mx-0 mx-md-2 my-3 border border-1 caregiver_container ">
                        <div className="card-body py-0 m-0">
                          <div className="d-flex align-items-center my-2">
                            <img
                              src={
                                pharmacyInfo.logo_url
                                  ? `${pharmacyInfo.logo_url}?v${Date.now()}`
                                  : userImagePlaceHolder
                              }
                              alt=""
                              className="rounded-circle pt_caregiver_image me-3 "
                            />
                            <div>
                              <p className="card-text my-1">
                                <span className="recent_msg_sender">
                                  {pharmacyInfo.name}
                                </span>
                              </p>
                              <p className="care_giver_info me-1">
                                <strong className="fw-bold">Phone: </strong>
                                {pharmacyInfo.phone
                                  ? pharmacyInfo.phone
                                  : "Not Available"}
                              </p>{" "}
                              <p className="care_giver_info me-1">
                                <strong className="fw-bold">Email: </strong>
                                {pharmacyInfo.email
                                  ? pharmacyInfo.email
                                  : "Not Available"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3 className="text-center fw-bold"> Providers</h3>
                      {curUserInfo.providers?.length > 0 ? (
                        <>
                          {curUserInfo.providers.map((el, idx) => {
                            return (
                              <div
                                className="mx-0 mx-md-2 my-3 border border-1 caregiver_container "
                                key={idx}>
                                <div className="card-body py-0 m-0">
                                  <div className="d-flex align-items-center my-2">
                                    <img
                                      src={
                                        el.pic_url
                                          ? `${el.pic_url}?v${Date.now()}`
                                          : userImagePlaceHolder
                                      }
                                      alt=""
                                      className="rounded-circle pt_caregiver_image me-3 "
                                    />
                                    <div>
                                      <p className="card-text my-1">
                                        <span className="recent_msg_sender">
                                          {el.first} {el.last}
                                        </span>
                                      </p>
                                      <p className="care_giver_info me-1">
                                        <strong className="fw-bold">
                                          Phone:{" "}
                                        </strong>
                                        {el.phone ? el.phone : "Not Available"}
                                      </p>{" "}
                                      <p className="care_giver_info me-1">
                                        <strong className="fw-bold">
                                          Email:{" "}
                                        </strong>
                                        {el.email ? el.email : "Not Available"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <p className="text-center my-3">
                          No linked Providers yet
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
