import React, { useEffect, useState } from "react";
import { getResponseById, getProfile } from "../../services/PharmacyUser";
import { getDocsTemplates } from "../../services/docuseal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  handleDocResponse,
  handleProfileUpdate,
} from "../../services/PharmacyUser";
import OTPTimer from "../../components/common/OTPTimer";
import { DocusealForm } from "@docuseal/react";
import baseURL from "../../utils/baseURL";
import {
  generateToken,
  handleEncryption,
  sendSMS,
  sendEmail,
  getPharmacyById,
} from "../../services/Common";

function ApplessDocs() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sentInstanceId = urlParams.get("reid");
  const sentFormId = urlParams.get("fid");
  const recipientId = urlParams.get("rid");
  const pharmacyId = urlParams.get("ph");

  console.log("sentInstanceId", sentInstanceId);
  console.log("sentFormId", sentFormId);
  console.log("recipientId", recipientId);
  console.log("pharmacyId", pharmacyId);

  const [user, setUser] = useState({});
  const [pharmacy, setPharmacy] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userMsg, setUserMsg] = useState({
    type: "",
    msg: "",
  });
  const [validation, setValidation] = useState({
    isValid: true,
    message: "",
  });
  const [doc, setDoc] = useState({});
  const [step, setStep] = useState("init");
  const [validationForm, setValidationForm] = useState({
    phone: "",
    otp: "",
  });
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    setValidationForm({
      ...validationForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleInit = () => {
    if (sentInstanceId && sentFormId && recipientId && pharmacyId) {
      (async () => {
        try {
          let token = await generateToken(recipientId);
          localStorage.setItem("Token", token.data.token);
          let res = await getResponseById(sentInstanceId);
          let doc = await getDocsTemplates(sentFormId);
          let profile = await getProfile(recipientId);
          let pharmacy = await getPharmacyById(pharmacyId);
          setIsLoading(false);
          profile?.data?.data?.user[0] && setUser(profile.data.data.user[0]);
          pharmacy?.data && setPharmacy(pharmacy.data);
          doc?.data?.templates &&
            doc?.data?.templates.length > 0 &&
            setDoc(doc.data.templates[0]);
          if (res.data[0].completion) {
            setValidation({
              isValid: false,
              message: "Document already completed.",
            });
            setCompleted(true);
          }

          if (res.data[0].isLinkDisabled) {
            setValidation({
              isValid: false,
              message: "Link is expired.",
            });
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          setValidation({
            isValid: false,
            message: "Something went wrong. Please try again later.",
          });
        }
      })();
    } else {
      setIsLoading(false);
      setValidation({
        isValid: false,
        message: "Invalid Link",
      });
    }
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentInstanceId, sentFormId, recipientId, pharmacyId]);

  const handleSendOTP = () => {
    const val = Math.floor(1000 + Math.random() * 9000);

    localStorage.setItem("otp", handleEncryption(val.toString()));

    const smsPayload = {
      to: user?.phone,
      body: `Your CuroRx One Time Password is ${val}`,
      from: pharmacy?.phone,
    };

    sendSMS(smsPayload)
      .then((res) => {
        if (user?.email) {
          setStep("otp");
        } else {
          setStep("email");
        }
      })
      .catch((err) => {
        console.error(err);

        sendEmail({
          to: user?.email,
          message: `Your CuroRx One Time Password is ${val}`,
        })
          .then((res) => {
            setStep("otp");
          })
          .catch((err) => {
            console.error(err);
            setUserMsg({
              type: "error",
              msg: "Unable to send OTP. Please try a valid phone number or email.",
            });
          });
      });
  };

  const handleVerifyNumber = () => {
    if (validationForm.phone !== "") {
      if (
        validationForm.phone === user?.phone ||
        validationForm.phone === user?.email
      ) {
        handleSendOTP();
      } else {
        setUserMsg({
          type: "error",
          msg: "The information you entered does not match our records. Please enter valid phone number or email",
        });
      }
    } else {
      setUserMsg({
        type: "error",
        msg: "Please enter a valid phone number or email",
      });
    }
  };
  console.log(`https://${baseURL.docuseal}/d/${doc.slug}`);
  console.log(doc);
  const verifyOTP = (e) => {
    e.preventDefault();
    setUserMsg({ type: "", msg: "" });

    if (
      localStorage.getItem("otp") ===
      handleEncryption(validationForm.otp.toString())
    ) {
      setStep("form");
    } else {
      setUserMsg({
        type: "error",
        msg: "Invalid OTP",
      });
    }
  };

  const handleComplete = (data) => {
    handleDocResponse({ value: data.submission }, sentInstanceId).then(
      (res) => {
        setCompleted(true);
      }
    );
  };
  return (
    <div className="container justify-content-center align-items-center">
      <>
        {" "}
        {isLoading ? (
          <Box sx={{ display: "flex ", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="col-12 col-lg-6 mx-auto">
            {validation.isValid ? (
              <>
                <div className="justify-content-center">
                  <>
                    {step === "init" && (
                      <div className="col-12  mt-5">
                        <div className="card">
                          <div className="card-header">
                            Please Verify Your Phone Number or Email
                          </div>
                          <div className="card-body ">
                            <p
                              className={
                                userMsg.type === "success"
                                  ? `text-success`
                                  : `text-danger`
                              }>
                              {userMsg.msg}
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone number or email"
                              name="phone"
                              value={validationForm.phone}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="card-footer text-end">
                            <button
                              className="btn btn-primary"
                              onClick={handleVerifyNumber}>
                              Send Code
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <>
                    {step === "email" && (
                      <div className="text-center col-12 col-lg-6 mx-auto mt-5">
                        <form>
                          <div class="form-group">
                            <label for="exampleInputEmail1">
                              <p class="text-warning">
                                We noticed that we don't have your email address
                                in our records. If you wish to add it, please
                                enter it here. If you don't wish to add it, you
                                can skip this step.
                              </p>
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-link my-2"
                            onClick={(e) => {
                              e.preventDefault();
                              setStep("otp");
                            }}>
                            Skip
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();

                              handleProfileUpdate(user.id, {
                                email: email,
                                updatedBy: user.id,
                              })
                                .then((res) => {
                                  setStep("otp");
                                })
                                .catch((err) => {
                                  setStep("otp");
                                  console.error(err);
                                });
                            }}>
                            Add & Continue
                          </button>
                        </form>
                      </div>
                    )}
                  </>
                  <>
                    {" "}
                    {step === "otp" && (
                      <div className="col-12  mt-5">
                        <div className="card">
                          <div className="card-header">
                            Please Verify Your One Time Password
                          </div>
                          <div className="card-body ">
                            <p
                              className={
                                userMsg.type === "success"
                                  ? `text-success`
                                  : `text-danger`
                              }>
                              {userMsg.msg}
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your OTP"
                              name="otp"
                              value={validationForm.otp}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="card-footer text-end">
                            <button
                              className="btn btn-primary"
                              onClick={verifyOTP}>
                              Verify OTP
                            </button>
                            <OTPTimer sendOTP={handleSendOTP} />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  <>
                    {" "}
                    {step === "form" && (
                      <div className="col-12">
                        {completed ? (
                          <div className="col-12">
                            <p className="text-success xtext">
                              Form submitted successfully
                            </p>
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 130.2 130.2"
                              className="x">
                              <circle
                                class="path circle"
                                fill="none"
                                stroke="#73AF55"
                                stroke-width="6"
                                stroke-miterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <polyline
                                class="path check"
                                fill="none"
                                stroke="#73AF55"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 "
                              />
                            </svg>
                          </div>
                        ) : (
                          <DocusealForm
                            src={`https://${baseURL.docuseal}/d/${doc.slug}`}
                            onComplete={(data) => handleComplete(data)}
                            email={
                              user?.email ||
                              email ||
                              `${user?.firstName} ${user?.lastName}@curorx.life`
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                </div>
              </>
            ) : (
              <div className="col-12">
                <p className="xtext text-danger">{validation.message}</p>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  className="x">
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <line
                    className="path line"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="34.4"
                    y1="37.9"
                    x2="95.8"
                    y2="92.3"
                  />
                  <line
                    className="path line"
                    fill="none"
                    stroke="#D06079"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="95.8"
                    y1="38"
                    x2="34.4"
                    y2="92.2"
                  />
                </svg>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
}

export default ApplessDocs;
