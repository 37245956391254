import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { SocketIoServer } from "../../../utils/globalState";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { handleUpdateEvent, handleGetEventById } from "../../../services/Calendar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Alert } from "@mui/material";
import baseURL from "../../../utils/baseURL";
const RequestReschedule = (props) => {
  const value = SocketIoServer.getState().SocketIoObj;
  const { open, handleClose, selectedCarePlan } = props;
  const [socket, setSocket] = useState(null);
  const [inputList, setInputList] = useState([{ inputvalue: "" }]);
    const [changeType, setChangeType] = useState("");
    const [msg, setMsg] = useState({
      type: "",
      msg: "",
    });
console.log(selectedCarePlan);
    const handleChange = (event) => {
      setChangeType(event.target.value);
    };

  useEffect(() => {
    setSocket(value);
  }, [value]);

  // handle input change
  const handleInputChange = (e, index) => {
    console.log(e);
    console.log(index);
    const list = [...inputList];
    list[index].inputvalue = e;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    // list.splice(index, 1);
    setInputList(remove);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { inputvalue: "" }]);
  };
  console.log(inputList);

  console.log(selectedCarePlan);

  const onReschedule = (payload) => {
      handleUpdateEvent(selectedCarePlan?.thisEventId, payload)
        .then(() => {

          socket.emit("noteset", {
            sender_name: selectedCarePlan?.patientName, //the patient name
            message: `You have a reschedule request for ${dayjs(
              selectedCarePlan?.thisEvent
            ).format("MM/DD/YYYY hh:mm A")} follow up call from ${
              selectedCarePlan?.patientName
            }.`, //the cotent of the note
            sender: selectedCarePlan?.recipientID, //the patient id
            receiver: selectedCarePlan?.OwnerId, //the pharmacy users id in this case
            body: `reschedule-request`, //the type of note
            unread: true,
            url: `${baseURL}/pharmacy-users/${selectedCarePlan?.thisEvent}&${selectedCarePlan?.id}`, //the url of the note if any
            timestamp: Date.now(),
          });

          setMsg({ type: "info", msg: "Request sent successfully" });
          setTimeout(() => {
            handleClose();
            setMsg({ type: "", msg: "" });
          }, 2000);
          setInputList([{ inputvalue: "" }]);
          setChangeType("");
        })
        .catch((err) => {
          setMsg({ type: "error", msg: "Something went wrong" });
          console.log(err);
        });
  }

  const onRescheduleRequest = () => {
    handleGetEventById(selectedCarePlan?.thisEventId).then((res) => {

       setMsg({ type: "", msg: "" });
       console.log(changeType);
       let payload = {};
       if (changeType === 1) {
         payload = {
           updatedBy: selectedCarePlan?.recipientID,
           isInitEvent: false,
           isChangeRequest: true,
           changeRequest: res.data.changeRequest
             ? JSON.stringify([
                 ...JSON.parse(res.data.changeRequest),
                 {
                   skip: true,
                   altDates: [],
                   originalDates: selectedCarePlan?.thisEvent,
                 },
               ])
             : JSON.stringify([
                 {
                   skip: true,
                   altDates: [],
                   originalDates: selectedCarePlan?.thisEvent,
                 },
               ]),
         };
       } else {
         if (inputList.some((el) => el.inputvalue === "")) {
           setMsg({
             type: "error",
             msg: "Please fill all fields",
           });
           return;
         }
         payload = {
           updatedBy: selectedCarePlan?.recipientID,
           isInitEvent: false,
           isChangeRequest: true,
           changeRequest: res.data.changeRequest
             ? JSON.stringify([
                 ...JSON.parse(res.data.changeRequest),
                 {
                   skip: false,
                   altDates: inputList.map((el) => el.inputvalue),
                   originalDates: selectedCarePlan?.thisEvent,
                 },
               ])
             : JSON.stringify([
                 {
                   skip: false,
                   altDates: inputList.map((el) => el.inputvalue),
                   originalDates: selectedCarePlan?.thisEvent,
                 },
               ]),
         };
       }
       onReschedule(payload);
 
      
    })
   
  
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {msg.type === "error" && msg.msg !== "" && (
          <Alert severity="error">{msg.msg}</Alert>
        )}
        {msg.type === "info" && msg.msg !== "" && (
          <Alert severity="info">{msg.msg}</Alert>
        )}
        <DialogTitle id="alert-dialog-title">
          {`Request reschedule for ${dayjs(selectedCarePlan?.thisEvent).format(
            "MMMM D, YYYY h:mm A"
          )}  follow up call.`}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id="demo-simple-select-label">
              Please select type of change
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={changeType}
              label="Please select type of change"
              onChange={handleChange}
            >
              <MenuItem value={1}>Skip a follow up call</MenuItem>
              <MenuItem value={2}>Reschedule a follow up call</MenuItem>
            </Select>
          </FormControl>
          {changeType === 2 && (
            <>
              <DialogContentText id="alert-dialog-description">
                Please enter at least one date and time to reschedule.
                <span className="text-danger">
                  Choosing more than one date is recommended to increase the
                  approval chances.
                </span>
              </DialogContentText>
              {inputList.map((x, i) => {
                return (
                  <div className="box d-flex align-items-center justify-content-between my-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Controlled picker"
                        name="inputvalue"
                        value={x.inputvalue}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </LocalizationProvider>
                    <div className="btn-box">
                      {inputList.length !== 1 && (
                        // <button
                        //   className="mr10"
                        //   onClick={() => handleRemoveClick(i)}
                        // >
                        //   Remove
                        // </button>

                        <IconButton
                          onClick={() => handleRemoveClick(i)}
                          aria-label="fingerprint"
                          color="secondary"
                        >
                          <RemoveIcon />
                        </IconButton>
                      )}
                      {inputList.length - 1 === i && (
                        // <button onClick={handleAddClick}>Add</button>
                        <IconButton
                          onClick={handleAddClick}
                          aria-label="fingerprint"
                          color="success"
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "red" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={onRescheduleRequest} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RequestReschedule;
