import React, { useState, useEffect, useRef } from "react";
import { decodeToken } from "react-jwt";
import { SideBar } from "../../utils/globalState";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useModal } from "react-hooks-use-modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useSnackbar from "@mui/base/useSnackbar";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { css, keyframes } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HideSource from "@mui/icons-material/HideSource";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getUserById } from "../../services/Common";
import { getDocsByCreatorID } from "../../services/Provider";
import SendDoc from "./../../components/provider/docsCenter/SendDoc";
import DocusealTemplateCreator from "../../components/pharmacyUser/documentsCenter/DocusealTemplateCreator";
import PreviewDoc from "../../components/pharmacyUser/documentsCenter/PreviewDoc";
import {
  getDocsTemplates,
  deleteDocusealTemplate,
} from "../../services/docuseal";
import {
  toggleDocumentHide,
  createDoc,
  deleteDocument,
} from "../../services/PharmacyUser";

const DocumentsManage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [openfsd, setOpenfsd] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [randflag, setRandflag] = useState("init");
  const [thisdoc, setThisdoc] = useState({});
  const [deletebuttonflag, setDeletebuttonflag] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [hiddenVisibility, setHiddenVisibility] = useState(false);
  const [user, setUser] = useState({});
  const [fileName, setFileName] = useState("");
  const [newDocId, setNewDocId] = useState(null);

  const descriptionElementRef = useRef(null);

  const [FilenameModal, openFilenameModal, closeFilenameModal] = useModal(
    "root",
    {
      preventScroll: true,
      closeOnOverlayClick: false,
    }
  );

  const colors = [
    "#FF0000",
    "#FF8700",
    "#FFD300",
    "#DEFF0A",
    "#A1FF0A",
    "#0AFF99",
    "#0AEFFF",
    "#147DF5",
    "#580AFF",
    "#BE0AFF",
  ];

  const getColor = (idx) => {
    return colors[idx % colors.length];
  };

  useEffect(() => {
    fetchDocs();
    userID &&
      getUserById(userID)
        .then((res) => {
          setUser(res.data.data.user[0]);
        })
        .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const fetchDocs = () => {
    getDocsByCreatorID(userID).then((res) => {
      setData(
        res.data
          .map((item, idx) => {
            return {
              ID: item.ID,
              json: JSON.parse(item.formText),
              text: "",
              isHidden: item.isHidden === 1 ? true : false,
              isSent: item.isSent === 1 ? true : false,
              color: getColor(idx),
            };
          })
          .reverse()
      );
    });
  };

  // ============================================== Full Screen Dialog ================================

  const handleClickOpen = () => {
    createDoc({
      creatorID: userID,
      pharmacyID: null,
      creatorType: "provider",
      formText: JSON.stringify({ title: fileName }),
    })
      .then((res) => {
        setNewDocId(res.data[0]);
        setOpenfsd(true);
        setScroll("body");
      })
      .catch((err) => {
        console.error(err);
        setMsg("Something went wrong, please try again later");
        setOpenfsd(true);
        handleOpen();
      });
  };

  const handlefsdClose = () => {
    setOpenfsd(false);
  };

  const handleExitOut = () => {
    setOpenfsd(false);
    deleteDocument(newDocId)
      .then(() => {
        setNewDocId(null);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (openfsd) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openfsd]);

  //   ========================================== Snackbar ==========================================
  const blue = {
    50: "#F0F7FF",
    400: "#3399FF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    200: "#E0E3E7",
  };

  const snackbarInRight = keyframes`
      from {
        transform: translateX(100%);
      }
    
      to {
        transform: translateX(0);
      }
    `;

  const CustomSnackbar = styled("div")(
    ({ theme }) => css`
      position: fixed;
      z-index: 5500;
      display: flex;
      right: 16px;
      top: 16px;
      left: auto;
      justify-content: start;
      max-width: 560px;
      min-width: 300px;
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? blue[600] : blue[400]};
      box-shadow: ${theme.palette.mode === "dark"
        ? `0 5px 13px -3px rgba(0,0,0,0.4)`
        : `0 5px 13px -3px ${grey[200]}`};
      padding: 0.75rem;
      color: ${theme.palette.mode === "dark" ? "#fff" : blue[900]};
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      animation: ${snackbarInRight} 500ms;
      transition: transform 0.2s ease-out;
    `
  );

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  //  ========================================== Snackbar Ends ==========================================

  //  ====================== Material UI Ends  ======================
  //   ====================== Modal ======================

  const [DeleteModal, openDeleteModal, closeDelete] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  //   ====================== Modal Ends ======================

  const handleDeleteChanges = (e) => {
    if (e.target.value.toLowerCase() === thisdoc.json.title.toLowerCase()) {
      setDeletebuttonflag(true);
    } else {
      setDeletebuttonflag(false);
    }
  };

  const handleDelete = () => {
    // delete form
    deleteDocument(thisdoc.ID)
      .then(() => {
        getDocsTemplates(thisdoc.ID)
          .then((res) => {
            res?.data?.templates?.length > 0 &&
              deleteDocusealTemplate(res.data.templates[0].id);
          })
          .catch((err) => {
            console.error(err);
          });

        setMsg(
          `Document "${thisdoc.json.title}" Has Been Successfully Deleted`
        );
        setData(data.filter((doc) => doc.ID !== thisdoc.ID));
        handleOpen();
      })
      .catch((err) => {
        console.error(err);
        setMsg(
          `Form "${thisdoc.json.title}" is active and can not be deleted `
        );
        handleOpen();
        setMsg(
          `Document "${thisdoc.json.title}" is active and can not be deleted `
        );
      });
  };

  const handleHideDocument = (item) => {
    let docId = item.ID;
    toggleDocumentHide(docId)
      .then(() => {
        setData(
          data.map((doc) =>
            doc.ID !== docId ? doc : { ...doc, isHidden: !doc.isHidden }
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleToggleHidden = () => {
    setHiddenVisibility(!hiddenVisibility);
  };
  useEffect(() => {
    setSearchData(data);
  }, [data]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let searchLower = e.target.value.toLowerCase();
    if (e.target.value === "") {
      setSearchData(data);
    }
    setSearchData(
      data.filter((el) => el.json.title.toLowerCase().includes(searchLower))
    );
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Documents</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Documents Center</li>
              <li
                className={`breadcrumb-item ${
                  randflag === "init" ? "active" : "cstm_breadcrumb"
                }`}
                onClick={() => {
                  setRandflag("init");
                  setThisdoc({});
                }}>
                Manage Documents
              </li>
              {randflag !== "init" && (
                <li className="breadcrumb-item active">
                  {randflag === "creator"
                    ? "Preview Document"
                    : randflag === "send"
                    ? "Send Form"
                    : ""}
                </li>
              )}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {randflag === "init" ? (
                <>
                  <div className="mb-2">
                    {
                      <Button
                        className="mx-2 ms-0  mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={openFilenameModal}>
                        Create New Document
                      </Button>
                    }
                    {data.some((item) => item.isHidden === true) && (
                      <Button
                        className="mx-sm-2 mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<HideSource />}
                        onClick={() => handleToggleHidden()}>
                        {!hiddenVisibility ? "Show Hidden " : "hide "}
                        Documents
                      </Button>
                    )}
                  </div>

                  {/* New Design Start */}
                  <div className="card px-2 pb-3 col-12  pt-4">
                    <div className="col-12 col-md-6 col-lg-4 my-2  ps-lg-5">
                      <div className="input-group flex-nowrap ">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Search Documents"
                          aria-label="Search"
                          aria-describedby="addon-wrapping"
                          onChange={handleSearch}
                          value={search}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size={"1x"}
                          />
                        </span>
                      </div>
                    </div>
                    <>
                      {searchData.length > 0 ? (
                        <div className="cstm_Survey_table col-12 col-lg-12 px-lg-5">
                          {searchData.map((item, i) => {
                            return hiddenVisibility ? (
                              <div
                                key={item.ID}
                                className="cstm_Survey_table_row card col-12"
                                style={{ backgroundColor: item.color }}>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th
                                        className="py-2 ps-3 d-flex justify-contect-between "
                                        colSpan={4}
                                        style={{
                                          backgroundColor: item.isHidden
                                            ? "#bab39e"
                                            : "white",
                                        }}>
                                        <p>{item.json.title}</p>
                                        <p className="flex-grow-1 text-end fs-6">
                                          {item.isSent ? (
                                            <span className="text-success fw-bold font-italic me-5">
                                              Active Document
                                            </span>
                                          ) : null}
                                        </p>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="col-12 ">
                                    <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                      <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setRandflag("send");
                                            setThisdoc(item);
                                          }}>
                                          Send Document
                                        </button>
                                      </td>

                                      <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setThisdoc(item);
                                            setRandflag("viewer");
                                          }}>
                                          Preview Document
                                        </button>
                                      </td>

                                      <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            handleHideDocument(item);
                                          }}>
                                          {!item.isHidden ? "Hide" : "Unhide"}
                                        </button>
                                      </td>

                                      <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                          disabled={item.isSent ? true : false}
                                          onClick={() => {
                                            openDeleteModal();
                                            setThisdoc(item);
                                          }}>
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              !item.isHidden && (
                                <div
                                  key={item.ID}
                                  className="cstm_Survey_table_row card col-12"
                                  style={{ backgroundColor: item.color }}>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th
                                          className="py-2 ps-3 d-flex justify-contect-between "
                                          colSpan={4}>
                                          <p>{item.json.title}</p>
                                          <p className="flex-grow-1 text-end fs-6">
                                            {item.isSent ? (
                                              <span className="text-success fw-bold font-italic me-5">
                                                Active Document
                                              </span>
                                            ) : null}
                                          </p>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="col-12 ">
                                      <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                        <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                          <button
                                            className="w-100 h-100 border-0 m-0 p-0"
                                            onClick={() => {
                                              setRandflag("send");
                                              setThisdoc(item);
                                            }}>
                                            Send Document
                                          </button>
                                        </td>

                                        <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                          <button
                                            className="w-100 h-100 border-0 m-0 p-0"
                                            onClick={() => {
                                              setThisdoc(item);
                                              setRandflag("viewer");
                                            }}>
                                            Preview Document
                                          </button>
                                        </td>

                                        <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                          <button
                                            className="w-100 h-100 border-0 m-0 p-0"
                                            onClick={() => {
                                              handleHideDocument(item);
                                            }}>
                                            {!item.isHidden ? "Hide" : "Unhide"}
                                          </button>
                                        </td>

                                        <td className="text-center col col-6 col-lg-auto flex-grow-1">
                                          <button
                                            className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                            disabled={
                                              item.isSent ? true : false
                                            }
                                            onClick={() => {
                                              openDeleteModal();
                                              setThisdoc(item);
                                            }}>
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )
                            );
                          })}
                        </div>
                      ) : (
                        <> no Documents Available</>
                      )}
                    </>
                  </div>
                  {/*  New Design End */}
                  {/*======================= Dialog ========================*/}
                  <Dialog
                    fullScreen
                    open={openfsd}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    style={{ marginTop: "60px" }}
                    // TransitionComponent={Transition}
                  >
                    <DialogTitle id="scroll-dialog-title">
                      <AppBar sx={{ position: "relative" }}>
                        <Toolbar>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                              setRandflag("init");
                              setThisdoc({});
                              setOpenfsd(false);
                              handleExitOut();
                            }}
                            aria-label="close">
                            <CloseIcon />
                          </IconButton>
                          <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div">
                            Upload & Save Document
                          </Typography>
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    <DialogContent dividers={scroll === "paper"}>
                      <>
                        <DocusealTemplateCreator
                          fileName={fileName}
                          externalId={newDocId}
                          pharmacyId={user.id + "_Provider"}
                          handlefsdClose={handlefsdClose}
                          fetchDocs={fetchDocs}
                        />
                      </>
                    </DialogContent>
                  </Dialog>
                  {/* ==================== Dialog Ends ==================== */}
                  <DeleteModal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Delete {thisdoc?.json?.title && thisdoc.json.title}
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ color: "red" }}>
                              Please type "
                              {thisdoc?.json?.title && thisdoc.json.title}" in
                              the field below to confirm the deleted document
                              title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInput"
                              aria-describedby="emailHelp"
                              onChange={handleDeleteChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeDelete();
                          }}>
                          Cancel
                        </button>
                        <button
                          disabled={!deletebuttonflag}
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          onClick={() => {
                            closeDelete();
                            handleDelete();
                          }}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </DeleteModal>
                  {open ? (
                    <ClickAwayListener onClickAway={onClickAway}>
                      <CustomSnackbar {...getRootProps()}>{msg}</CustomSnackbar>
                    </ClickAwayListener>
                  ) : null}
                </>
              ) : randflag === "send" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisdoc({});
                    }}>
                    Back
                  </Button>
                  <SendDoc doc={thisdoc} />{" "}
                </>
              ) : (
                <>
                  <PreviewDoc document={thisdoc} />
                </>
              )}
            </div>
          </div>
        </div>
        <FilenameModal>
          <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
            <div className="card-header bg-transparent fw-bold text-center">
              Create New Document Template
            </div>
            <div className="card-body">
              {/* <h5 className="card-title">Success card title</h5> */}
              <form>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Template Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="card-footer bg-transparent text-center">
              <button
                type="button"
                className="btn btn-danger mx-2 mx-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setFileName("");
                  closeFilenameModal();
                }}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary mx-2 mx-2"
                disabled={fileName === ""}
                onClick={() => {
                  closeFilenameModal();
                  handleClickOpen();
                }}>
                Create
              </button>
            </div>
          </div>
        </FilenameModal>
      </main>
    </div>
  );
};

export default DocumentsManage;
