import React, { useState, useEffect } from "react";
import { decodeToken } from "react-jwt";
import PatientCompletedVSPending from "../../../pharmacyUser/documentsCenter/docsTrends/patientCompletedVSPending/PatientCompletedVSPending";
import AverageTurnAroundTime from "../../../pharmacyUser/documentsCenter/docsTrends/averageTurnAroundTime/AverageTurnAroundTime";
import { getDocsByCreatorID } from "../../../../services/Provider";

const DocsTrends = (props) => {
  const { data } = props;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    getDocsByCreatorID(userID)
      .then((res) => {
        const docsFromDB = res.data.map(
          (item) => JSON.parse(item.formText).title
        );
        setAllDocs(docsFromDB);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userID]);

  return (
    <div>
      <PatientCompletedVSPending data={[...data]} name="Document" />
      <AverageTurnAroundTime
        data={[...data]}
        name="Documents"
        allDocs={allDocs}
      />
    </div>
  );
};

export default DocsTrends;
