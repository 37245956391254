import React from "react";
import { SideBar } from "../../utils/globalState";
import { NavLink, useNavigate } from "react-router-dom";

import "../../styles/commonStyles/nav.css";

const Navigator = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");

  const navigate = useNavigate();
  if (!token) {
    navigate("/");
  }

  return (
    <div
      className={isDivVisible ? "pt_nav" : "pt_nav toggle-Navigation"}
      id="side-bar-nav"
    >
      {isDivVisible && (
        <div>
          <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/homepage"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_1"
                    className="bi bi-house-door"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Home
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/messages"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_2"
                    className="bi bi-chat-dots"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Messages
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/forms-center"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_3"
                    className="bi bi-ui-checks"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Forms Center
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/documents-center"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_4"
                    className="bi bi-files"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Documents Center
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/survey"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_5"
                    className="bi bi-kanban"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Survey
                </NavLink>
              </li>
              {/* care-plan-center */}
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/care-plan-center"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_5"
                    className="bi bi-heart-pulse"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Care Plan Center
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/profile"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_6"
                    className="bi bi-person-fill"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Profile
                </NavLink>
              </li>
              <li className="nav-content pt_nav_item">
                <NavLink
                  to="/patient/contact-us"
                  className="nav-link"
                  data-bs-target="#components-nav"
                >
                  <i
                    id="nav_icon_7"
                    className="bi bi-info-circle"
                    style={{ fontSize: "1.4rem" }}
                  />
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default Navigator;
