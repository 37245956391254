import React, { useState, useEffect } from "react";
import { convertTime } from "../../../../../utils/timeConverter";
import { decodeToken } from "react-jwt";
import PatientCompletedVSPending from "../../../../pharmacyUser/documentsCenter/docsTrends/patientCompletedVSPending/PatientCompletedVSPending";
import AverageTurnAroundTime from "../../../../pharmacyUser/documentsCenter/docsTrends/averageTurnAroundTime/AverageTurnAroundTime";
import { getFormsByCreatorID } from "../../../../../services/Provider";

function FormsTrends(props) {
  const { rawResponses } = props;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [data, setData] = useState([]);
  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    const incoming = rawResponses
      .map((item) => {
        const updatedItem = {
          ...item,
          isStop: item.isStop === 1 ? true : false,
          isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
          formText: item.formText ? JSON.parse(item.formText) : "",
          value: item.value ? JSON.parse(item.value) : "",
          senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
          recipientInfo: item.recipientInfo
            ? JSON.parse(item.recipientInfo)
            : "",

          sender: item.senderInfo
            ? JSON.parse(item.senderInfo).first +
              " " +
              JSON.parse(item.senderInfo).last
            : "",
          recipientName: item.recipientInfo
            ? JSON.parse(item.recipientInfo).first +
              " " +
              JSON.parse(item.recipientInfo).last
            : "",

          recipientType: item.recipientInfo
            ? JSON.parse(item.recipientInfo).type
            : "",
          MRN: item.recipientInfo
            ? JSON.parse(item.recipientInfo).MRN || "N/A"
            : "",
          formName: item.formText ? JSON.parse(item.formText).title : "",

          isArchived: item.isArchived === 1 ? true : false,
          archived_date: item.archived_date
            ? convertTime(item.archived_date)
            : "",
          isReviewed: item.isReviewed === 1 ? true : false,
          reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
          completionDate: item.completion || "",
          completion: item.completion ? convertTime(item.completion) : "",
          sentDate: item.sent || "",
          sent: item.sent ? convertTime(item.sent) : "",
          dateOfLastReminder: item.dateOfLastReminder
            ? convertTime(item.dateOfLastReminder)
            : "",
        };

        return updatedItem;
      })
      .reverse();

    setData(incoming);
  }, [rawResponses]);

  useEffect(() => {
    getFormsByCreatorID(userID)
      .then((res) => {
        const docsFromDB = res.data.map(
          (item) => JSON.parse(item.formText).title
        );
        setAllDocs(docsFromDB);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userID]);
  return (
    <div>
      <PatientCompletedVSPending data={data} name="Form" />
      <AverageTurnAroundTime data={data} name="Forms" allDocs={allDocs} />
    </div>
  );
}

export default FormsTrends;
