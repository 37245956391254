import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

export const handleGetPharmacies = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getPharmacies`);
};

export const handleAddPharmacy = (pharmacy) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/admin/addPharmacy`,
    pharmacy
  );
};

export const handleGetAdmins = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/Admin/pharmacy/${id}/admins`);
};

export const getPharmaPrivileges = (id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/Admin/pharmacy/${id}/privileges`
  );
};

export const handleAddAdmin = (admin) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/admin/addPharmacyAdmin`,
    admin
  );
};

export const handleUpdatePharmaPrivileges = (items) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/Admin/addPrivToPharmacy`,
    items
  );
};

export const handleGetPrivileges = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getPrivileges`);
};

export const handleAddPrivilege = (privilege) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/admin/addPrivilege`,
    privilege
  );
};

export const handleDeletePrivilegeFromPharmacy = (pharmacyId, privilegeId) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/admin/pharmacy/${pharmacyId}/privilege/${privilegeId}`
  );
};

export const handleGetIntegrations = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getAllIntegrations`);
};

export const handleAddIntegration = (integration) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/admin/addIntegration`,
    integration
  );
};

export const handleUpdateIntegration = (id, integration) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/admin/updateIntegration/${id}`,
    integration
  );
};

export const handleDeleteIntegration = (id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/admin/deleteIntegration/${id}`
  );
};

export const handleAssignIntegrationToPharmacy = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/admin/addIntegrationToPharmacy`,
    payload
  );
};

export const handleDeleteIntegrationFromPharmacy = (payload) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/admin/deleteIntegrationFromPharmacy`,
    { data: payload }
  );
};

export const handleGetFeatures = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getallfeatures`);
};
export const handleAddFeature = (feature) => {
  return axiosWithAuth().post(`${baseURL.curoback}/admin/addfeature`, feature);
};
export const handleUpdateFeature = (feature) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/admin/feature/${feature.id}`,
    feature
  );
};
export const handleDeleteFeature = (id) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/admin/feature/${id}`);
};

export const getAllProviders = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/admin/getallproviders`);
};

export const getFeatureByProviderId = (id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/admin/providerfeatures/${id}`
  );
};

export const handleAddProviderFeature = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/admin/linkfeature`, payload);
};

export const handleDeleteProviderFeature = (payload) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/admin/unlinkfeature`, {
    data: payload,
  });
};

export const addPharmacyLogo = (id, payload) => {
  return axiosWithAuth("multipart/form-data").put(
    `${baseURL.curoback}/admin/updatePharmacyLogo/${id}`,
    payload
  );
};

export const updatePharmacySMSPhoneNum = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/admin/updatePharmacySMSPhone/${id}`,
    payload
  );
};

// Payload can be phone or email or both, to delete existing value pass null as value
export const updatePharmacyContact = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/admin/updatePharmacyContact/${id}`,
    payload
  );
};
