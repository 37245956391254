/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import profileImgPlaceHolder from "../../../assets/images/patient_placeholder.png";
import logo from "../../../assets/images/CuroRX-logo-transparent.png";
import { decodeToken } from "react-jwt";
import { handleGetPharmacies } from "../../../services/Admin";
// ========================================DO NOT DELETE TO BE USED LATER===================================
// import { BiSun, BiMoon } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import {
  SideBar,
  User,
  SocketIoServer,
  Pharmacy,
} from "../../../utils/globalState";
import "../../../styles/commonStyles/boxicons/css/boxicons.min.css";
import "../../../styles/commonStyles/bootstrap-icons/bootstrap-icons.css";
import "../../../styles/menu-style/style.css";
import "../../../styles/commonStyles/header.css";
import {
  getUserById,
  getNotificationsByUserID,
} from "../../../services/Common";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";

const Header = () => {
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const [isMessagesOpen, setMessagesOpen] = useState(false);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [darkTheme, setDarkTheme] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [profilePicture, setProfilePicture] = useState(profileImgPlaceHolder);
  const [pharmacyObj, setPharmacyObj] = useState({});
  const toggleDivVisibility = SideBar((state) => state.toggleDivVisibility);
  // eslint-disable-next-line no-unused-vars
  const userInformation = User.getState().info;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const pharmacy_id = localStorage.getItem("pharmacy");
  const pharmacy = Pharmacy((state) => state.info);
  const [pharmacyLogo, setpharmacyLogo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserById(myDecodedToken.userId);
        setUserInfo(response.data.data?.user[0]);
        User.setState({ info: response.data.data?.user[0] });
        setProfilePicture(
          response.data.data?.user[0].pic_url
            ? `${response.data.data.user[0].pic_url}?v${Date.now()}}`
            : profileImgPlaceHolder
        );
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchData();
  }, [myDecodedToken.userId]);
  useEffect(() => {
    handleGetPharmacies().then((res) => {
      res.data.data.length > 0 &&
        setPharmacyObj(
          res.data.data.find((i) => i.id.toString() === pharmacy_id) || {}
        );
    });
  }, [pharmacy_id]);

  useEffect(() => {
    if (darkTheme) {
      document.documentElement.style.setProperty("--text-color", "#fff");
      document.documentElement.style.setProperty(
        "--background-color",
        "#2e3d49"
      );
    } else {
      document.documentElement.style.setProperty("--text-color", "unset");
      document.documentElement.style.setProperty("--background-color", "unset");
    }
  }, [darkTheme]);

  const toggleNotifications = () => {
    setNotificationsOpen(!isNotificationsOpen);
    if (isMessagesOpen) {
      setMessagesOpen(false);
    } else if (isProfileMenuOpen) {
      setProfileMenuOpen(false);
    }
  };

  const toggleMessages = () => {
    setMessagesOpen(!isMessagesOpen);
    if (isNotificationsOpen) {
      setNotificationsOpen(false);
    } else if (isProfileMenuOpen) {
      setProfileMenuOpen(false);
    }
  };

  const toggleProfile = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
    if (isNotificationsOpen) {
      setNotificationsOpen(false);
    } else if (isMessagesOpen) {
      setMessagesOpen(false);
    }
  };

  // ========================================DO NOT DELETE TO BE USED LATER===================================
  // const toggleDarkTheme = () => {
  //   setDarkTheme(!darkTheme);
  // };

  const handleSignOut = () => {
    localStorage.clear();
  };

  // -----------------------------------------Socket Io-----------------------------------------

  const value = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [msgsNotifications, setMsgsNotifications] = useState([]);
  const [eventsNotifications, setEventsNotifications] = useState([]);
  useEffect(() => {
    setSocket(value);
  }, [value]);
  useEffect(() => {
    if (socket !== null) {
      if (Object.keys(socket).length > 0) {
        socket.on("notifications", (data) => {
          getNots();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    getNots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMsgsNotifications(notifications.filter((i) => i.body === "message"));
    setEventsNotifications(notifications.filter((i) => i.body !== "message"));
  }, [notifications]);

  const getNots = () => {
    getNotificationsByUserID(myDecodedToken.userId)
      .then((res) => {
        if (res.data.length !== 0) {
          setNotifications(
            res.data
              .reverse()
              .filter(
                (note) =>
                  note.receiver_id === myDecodedToken.userId.toString() &&
                  note.sender_id !== myDecodedToken.userId.toString()
              )
          );
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setNotifications([]);
      });
  };

  const handleread = (note) => {
    socket.emit("updatenote", { id: note.id });
  };

  const handleDelete = (note) => {
    socket.emit("deletenote", { id: note.id });
  };
  // ========================================Socket Io End===================================

  const getPortalURL = () => {
    const { type } = userInfo;

    if (type?.toLowerCase() === "admin") {
      return "admin";
    } else if (type?.toLowerCase() === "pharmacyadmin") {
      return "pharmacy-admin";
    } else if (type?.toLowerCase() === "user") {
      return "pharmacy-user";
    } else if (type?.toLowerCase() === "provider") {
      return "provider";
    } else if (type?.toLowerCase() === "patient") {
      return "patient";
    }
  };
  useEffect(() => {
    if (pharmacyObj && Object.keys(pharmacyObj).length > 0) {
      setpharmacyLogo(pharmacyObj.logo_url || logo);
    } else {
      setpharmacyLogo(logo);
    }
  }, [pharmacyObj]);

  useEffect(() => {
    if (pharmacy && Object.keys(pharmacy).length > 0) {
      setpharmacyLogo(pharmacy.logo_url || logo);
    } else {
      setpharmacyLogo(logo);
    }
  }, [pharmacy]);
  return (
    <div>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <Link
            to={
              getPortalURL() === "patient"
                ? `/${getPortalURL()}/homepage`
                : `/${getPortalURL()}/dashboard`
            }
            className="logo d-flex align-items-center">
            <img src={pharmacyLogo} alt={"logo"} className="main-logo" />
          </Link>
          <i
            className="bi bi-list toggle-sidebar-btn"
            onClick={toggleDivVisibility}></i>
        </div>
        {/* <!-- End Logo --> */}

        <nav className="header-nav ms-auto" visability="true">
          <ul className="d-flex align-items-center">
            {/* // ========================================DO NOT DELETE TO BE USED LATER=================================== */}
            {/* <li className="nav-item dark-theme-toggler">
              <button className="theme-toggle-btn" onClick={toggleDarkTheme}>
                {darkTheme ? <BiSun /> : <BiMoon />}
              </button>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown">
                <i className="bi bi-bell" onClick={toggleNotifications}></i>
                <span className="badge bg-primary badge-number">
                  {eventsNotifications.filter((i) => i.unread === 1).length}
                </span>
              </a>
              {/* <!-- End Notification Icon --> */}
              <ul
                onMouseLeave={() => setNotificationsOpen(false)}
                style={
                  isNotificationsOpen
                    ? {
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate(-25px, 35px)",
                      }
                    : {}
                }
                className={
                  isNotificationsOpen
                    ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications header-nav-dropdown show"
                    : "dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications header-nav-dropdown"
                }>
                <li className="dropdown-header">
                  You have{" "}
                  {eventsNotifications.filter((i) => i.unread === 1).length} new
                  notifications
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {eventsNotifications.length > 0 ? (
                  eventsNotifications.map((note) => {
                    return (
                      <React.Fragment key={note.id}>
                        <li className="message-item">
                          <a
                            href={
                              note.body === "reschedule-request"
                                ? "#"
                                : note.url
                            }
                          >
                            {/* onClick={() => handleread(note)} removed to handle from todo list */}
                            <div>
                              <h4>{note.sender_name}</h4>
                              <p>{note.message}...</p>
                              <p>
                                {dayjs(note.createdAt).format(
                                  "MM/DD/YYYY hh:mm a"
                                )}
                              </p>
                            </div>
                            <DeleteIcon
                              sx={{
                                fontSize: "20px",
                                color: "red !important",
                                float: "right",
                              }}
                              onClick={() => handleDelete(note)}
                            />
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
              {/* <!-- End Notification Dropdown Items --> */}
            </li>
            {/* <!-- End Notification Nav --> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown">
                <i
                  className="bi bi-chat-left-text"
                  onClick={toggleMessages}></i>
                <span className="badge bg-success badge-number">
                  {msgsNotifications.filter((i) => i.unread === 1).length}
                </span>
              </a>
              {/* <!-- End Messages Icon --> */}

              <ul
                onMouseLeave={() => setMessagesOpen(false)}
                style={
                  isMessagesOpen
                    ? {
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate(-25px, 35px)",
                      }
                    : {}
                }
                className={
                  isMessagesOpen
                    ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow messages header-nav-dropdown show"
                    : "dropdown-menu dropdown-menu-end dropdown-menu-arrow messages header-nav-dropdown"
                }>
                <li className="dropdown-header">
                  You have{" "}
                  <>{msgsNotifications.filter((i) => i.unread === 1).length}</>{" "}
                  new messages
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {msgsNotifications.length > 0 ? (
                  msgsNotifications.map((note) => {
                    return (
                      <React.Fragment key={note.id}>
                        <li className="message-item">
                          <a href="#">
                            <div
                              onClick={() => {
                                handleread(note);
                                let link = document.createElement("a");
                                link.target = "_blank";
                                link.href = note.url;
                                link.click();
                              }}>
                              <h4>{note.sender_name}</h4>
                              <p>{note.message}...</p>
                              <p>
                                {dayjs(note.createdAt).format(
                                  "MM/DD/YYYY hh:mm a"
                                )}
                              </p>
                            </div>
                            <DeleteIcon
                              sx={{
                                fontSize: "20px",
                                color: "red !important",
                                float: "right",
                              }}
                              onClick={() => handleDelete(note)}
                            />
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
              {/* <!-- End Messages Dropdown Items --> */}
            </li>
            {/* <!-- End Messages Nav --> */}
            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
                onClick={toggleProfile}>
                <img
                  src={profilePicture}
                  alt="Profile"
                  className="rounded-circle header_profile_img"
                />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {userInfo.first} {userInfo.last}
                </span>
              </a>
              {/* <!-- End Profile Iamge Icon --> */}

              <ul
                onMouseLeave={() => setProfileMenuOpen(false)}
                style={
                  isProfileMenuOpen
                    ? {
                        position: "absolute",
                        inset: "0px 0px auto auto",
                        margin: "0px",
                        transform: "translate(-16px, 38px)",
                      }
                    : {}
                }
                className={
                  isProfileMenuOpen
                    ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile header-nav-dropdown show"
                    : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile header-nav-dropdown"
                }>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink
                    to={`/${getPortalURL()}/profile`}
                    className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-gear"></i>
                    <span>My Profile</span>
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <NavLink
                    to={`/${getPortalURL()}/profile-picture`}
                    className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-person"></i>
                    <span>Profile Picture</span>
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink
                    to={`/${getPortalURL()}/contact-us`}
                    className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink
                    to={`/`}
                    className="dropdown-item d-flex align-items-center"
                    onClick={handleSignOut}>
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </NavLink>
                </li>
              </ul>
              {/* <!-- End Profile Dropdown Items --> */}
            </li>
            {/* <!-- End Profile Nav --> */}
          </ul>
        </nav>
        {/* !-- End Icons Navigation --> */}
      </header>
    </div>
  );
};

export default Header;
