import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

export const getProfile = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/${id}`);
};

export const handleProfileUpdate = (id, payload) => {
  payload.ContactInfo = [];
  return axiosWithAuth().put(`${baseURL.curoback}/user/${id}`, payload);
};

// ====================== Survey services ========== Start ============

export const getAllRes = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/get_all_responses`);
};

export const getSurveyResponseById = (responseId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/servey/getResponseById/${responseId}`
  );
};

export const addSurvey = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/addServey`, payload);
};

export const handleGetptbyid = (id) => {
  //will add the token to the request header as a temp solution to test but will need to be implemented using the actual token received from BE in the future
  return axiosWithAuth().post(`${baseURL.curoback}/servey/patintId`, {
    id: id,
  });
};

export const addNewSurveyPatient = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/addPatientSurvey`,
    payload
  );
};

export const getSurveyPtByPhone = (phone) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/servey/getServPtByPhone/${phone}`
  );
};

export const postNewAction = (surveyResponseID, action, action_user) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/${surveyResponseID}/action`,
    {
      action,
      action_user,
    }
  );
};

export const toggleResolved = (surveyResponseID, resolved) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/${surveyResponseID}/resolved/`,
    {
      resolved,
    }
  );
};

export const getSurveys = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/getSurveys`);
};

export const getSurveyById = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/getSurveyById/${id}`);
};

export const updateSurvey = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/editServey/${id}`,
    payload
  );
};

export const addResponse = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/add_response`,
    payload
  );
};

export const getNotificationsUsers = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/notified_users`);
};

export const addNewRecipent = (payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/servey/notified_users`,
    payload
  );
};

export const deleteRecipent = (email) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/servey/notified_users/${email}`
  );
};

export const getTokens = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/tokens`);
};

export const getTokenById = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/servey/tokens/${id}`);
};

export const updateToken = (id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/tokens/${id}`,
    payload
  );
};

export const createNewToken = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/tokens`, payload);
};

export const deleteSurvey = (id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/servey/deleteSurvey/${id}`
  );
};

export const csvfilenamecheck = (name) => {
  return axiosWithAuth().post(`${baseURL.curoback}/servey/file/`, {
    name,
  });
};

export const markSurveyResponseAsReviewed = (responseId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/servey/reviewed/${responseId}`,
    payload
  );
};

// ====================== Survey services ========== END ============

export const getPtLog = () => {
  return axiosWithAuth().get(`${baseURL.curoback}/user/stored/data`);
};

export const getBirthdayMsg = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/user/birthday_message/${pharmacyId}`
  );
};

export const updateBirthdayMsg = (pharmacyId, newMsg) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/user/birthday_message/${pharmacyId}`,
    newMsg
  );
};

export const getPatientscharts = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/patient/pharmacy/${pharmacyId}/get_patients`
  );
};

export const searchUsers = (pharmacyID, searchItems) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/users/${pharmacyID}?${searchItems}`
  );
};

export const searchPt = (searchItems, phId) => {
  let dob = !searchItems.DOB
    ? ""
    : `${
        searchItems.DOB.split("-")[1].charAt(0) === "0"
          ? searchItems.DOB.split("-")[1].charAt(1)
          : searchItems.DOB.split("-")[1]
      }/${
        searchItems.DOB.split("-")[2].charAt(0) === "0"
          ? searchItems.DOB.split("-")[2].charAt(1)
          : searchItems.DOB.split("-")[2]
      }/${searchItems.DOB.split("-")[0]}`;
  return axiosWithAuth().get(
    `${
      baseURL.curoback
    }/patient/pharmacy/${phId}/patients?offset=&limit=&first=${
      searchItems.first || ""
    }&last=${searchItems.last || ""}&DOB=${dob}&email=${
      searchItems.email || ""
    }&phone=${searchItems.phone || ""}&MRN=${searchItems.MRN || ""}`
  );
};

export const getPrivelge = (Role) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/role/${Role}/privileges`
  );
};

// ====================== Forms services ========== Start ============

export const createForm = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/form`, payload);
};

export const updateForm = (formId, payload) => {
  return axiosWithAuth().put(`${baseURL.curoback}/form/${formId}`, payload);
};

export const getAllForms = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/pharmacies/${pharmacyId}`
  );
};

export const sendForm = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/form/sendForm`, payload);
};

export const handleFormResponse = (payload, id) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/responses/${id}`,
    payload
  );
};

export const getResponseByid = (id) => {
  return axiosWithAuth().get(`${baseURL.curoback}/form/response/${id}`);
};

export const getResponses = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/responses/${pharmacyId}`
  );
};

export const archiveResponse = (responseId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/responses/${responseId}/archive`,
    {
      archivedBy: payload,
    }
  );
};

export const toggleFormHide = (formId) => {
  return axiosWithAuth().put(`${baseURL.curoback}/form/${formId}/toggleHidden`);
};

export const deleteForm = (formId) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/form/${formId}`);
};

export const reviewResponse = (Id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/responses/${Id}/review`,
    payload
  );
};

export const storeFormResponseLinkInDB = (response_id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/form_responses/${response_id}/link`,
    {
      link: payload,
    }
  );
};

export const cancelReminderForm = (response_id) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/response/${response_id}`,
    {
      isStop: true,
    }
  );
};

export const disableLinkForm = (response_id) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/responses/${response_id}/isLinkDisabled`,
    {
      isLinkDisabled: true,
    }
  );
};

export const deleteFormResponse = (response_id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/form/responses/${response_id}`
  );
};

export const handleSetDraft = (payload, resId) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/draft/responses/${resId}`,
    payload
  );
};

export const searchPtForms = (pharmacyId, payload) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/form/user/${pharmacyId}`,
    payload
  );
};

// ====================== Forms services ========== End ============

// ====================== Forms instances services ========== Start ============

// #### End point to post new formInstance ###
// payload = { formText, creatorID, formId }
export const createFormInstance = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/form/formInstance`, payload);
};

// #### End point to get all formInstances for a specific form ###
export const getFormInstancesByFormId = (formId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/formInstances/forms/${formId}`
  );
};

// #### End point to get formInstance by id ###
export const getFormInstanceById = (formInstanceId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/formInstance/${formInstanceId}`
  );
};

// #### End point to update formInstance ###
// payload = { formText }
export const updateFormInstance = (formInstanceId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/formInstance/${formInstanceId}`,
    payload
  );
};

// #### End point to delete formInstance ###
export const deleteFormInstance = (formInstanceId) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/form/formInstance/${formInstanceId}`
  );
};

// ====================== Forms instances services ========== End ============

// ====================== Documents services ========== Start ============
export const createDoc = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/form/docs`, payload);
};

export const getDocs = (phId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/pharmacies/${phId}/docs`
  );
};

export const getDocById = (docId) => {
  return axiosWithAuth().get(`${baseURL.curoback}/form/doc/${docId}`);
};

export const sendDoc = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/form/sendDoc`, payload);
};

export const updateDoc = (docId, payload) => {
  return axiosWithAuth().put(`${baseURL.curoback}/form/doc/${docId}`, payload);
};

export const getDocResponses = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/doc_responses/${pharmacyId}`
  );
};

export const getResponseById = (responseId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/doc_response/${responseId}`
  );
};

export const handleDocResponse = (payload, id) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc_response/${id}`,
    payload
  );
};

export const toggleDocumentHide = (documentId) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc/${documentId}/toggleHidden`
  );
};

export const deleteDocument = (documentId) => {
  return axiosWithAuth().delete(`${baseURL.curoback}/form/doc/${documentId}`);
};

export const archiveDocResponse = (responseId, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc_responses/${responseId}/archive`,
    {
      archivedBy: payload,
    }
  );
};

export const reviewDocResponse = (Id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc_responses/${Id}/review`,
    payload
  );
};

export const storeDocResponseLinkInDB = (response_id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc_responses/${response_id}/link`,
    {
      link: payload,
    }
  );
};

export const cancelReminderDoc = (response_id) => {
  return axiosWithAuth().put(`${baseURL.curoback}/form/docs/${response_id}`, {
    isStop: true,
  });
};

export const disableLinkDoc = (response_id) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/form/doc_responses/${response_id}/isLinkDisabled`,
    {
      isLinkDisabled: true,
    }
  );
};

export const deleteDocResponse = (response_id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/form/doc_responses/${response_id}`
  );
};

export const handleGetDocFiles = (filename) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/form/getDocument/${filename}`
  );
};

export const handleStoreFiles = (payload, userID) => {
  return axiosWithAuth("multipart/form-data").post(
    `${baseURL.curoback}/form/uploadDocument/${userID}`,
    payload
  );
};
// ====================== Documents services ========== End ============

// ====================== Provider services ========== Start ============
export const addNewProvider = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/provider/provider`, payload);
};

export const NPI = (npi, fname, lname) => {
  return axiosWithAuth().post(`${baseURL.curoback}/provider/npi`, {
    npi,
    fname,
    lname,
  });
};

export const getProviders = (id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/provider/pharmacy/${id}/providers`
  );
};

export const updateProviderInfo = (prvider_id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/provider/provider/${prvider_id}`,
    payload
  );
};
// ====================== Provider services ========== End ============

// ====================== Patient services ========== Start ============
export const addNewPatient = (payload) => {
  return axiosWithAuth().post(`${baseURL.curoback}/patient/patient`, payload);
};

export const getPatients = (pharmacyId, page) => {
  let offset = page === 1 ? 0 : (page - 1) * 100;
  return axiosWithAuth().get(
    `${baseURL.curoback}/patient/pharmacy/${pharmacyId}/patients?offset=${offset}&limit=100`
  );
};

export const getPatientsTotal = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/patient/pharmacy/${pharmacyId}/patient_count`
  );
};

export const updatePatientInfo = (Patient_id, payload) => {
  return axiosWithAuth().put(
    `${baseURL.curoback}/patient/patient/${Patient_id}`,
    payload
  );
};

// ====================== Patient services ========== End ============

// ====================== Dashboard ========== Start ==========

export const getPharmacyAnnouncement = (pharmacyId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/pharmacyAdmin/announcements/${pharmacyId}`
  );
};
// ====================== Dashboard ========== End ============
//=====================Messages=========================

export const handleGetMessages = async (data) => {
  return axiosWithAuth()
    .get(`${baseURL.curoback}/message/channel/${data.channel_id}/messages`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const handleFiles = async (data) => {
  return axiosWithAuth().post(`${baseURL.curoback}/message/upload_file`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const handleSendMessage = async (data) => {
  return axiosWithAuth()
    .post(`${baseURL.curoback}/message/message`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const handleSendEmail = async (data) => {
  return axiosWithAuth()
    .post(`${baseURL.curoback}/api/sendemail`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const addMessagesUser = async (user) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/pharmacyAdmin/addToGroup`,
    user
  );
};

export const deleteMessagesUser = async (user) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/pharmacyAdmin/deleteFromGroup`,
    { data: user }
  );
};

export const getAllUsers = async (phId) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/pharmacy/${phId}/users`
  );
};

export const handleUpdateChannel = async (data, chId) => {
  return axiosWithAuth()
    .put(`${baseURL.curoback}/message/update_channel/${chId}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const handleGetChannels = async (user_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/message/sender/${user_id}/channels`
  );
};

export const handleCheckChannel = async (info) => {
  return axiosWithAuth()
    .post(`${baseURL.curoback}/message/messages`, info)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};
