import { SurveyPDF } from "survey-pdf";
import { curoRxLogo } from "./curoRxLogoBase64";

const pdfDocOptions = {
  haveCommercialLicense: true,
  fontSize: 12,
  htmlRenderAs: "auto",
  margins: {
    left: 10,
    right: 10,
    top: 10,
    bot: 10,
  },
};

const flattenSurveyPages = (surveyJson) => {
  const flattenedSurvey = { ...surveyJson, pages: [{ elements: [] }] };
  surveyJson.pages.forEach((page) => {
    flattenedSurvey.pages[0].elements =
      flattenedSurvey.pages[0].elements.concat(page.elements);
  });
  return flattenedSurvey;
};

export const savePdf = function (originalSurveyJson, surveyData, relatedData) {
  const flattenedSurveyJson = flattenSurveyPages(originalSurveyJson);
  const surveyPdf = new SurveyPDF(flattenedSurveyJson, pdfDocOptions);
  surveyPdf.data = surveyData;
  surveyPdf.mode = "display";

  surveyPdf.onRenderHeader.add(async function (_, canvas) {
    await canvas.drawImage({
      base64: curoRxLogo || "",
      horizontalAlign: "center",
      width: 250,
      height: 80,
      margins: { top: 5 },
    });
  });

  surveyPdf.onRenderFooter.add(async function (_, canvas) {
    const sentOn = relatedData.sent ? relatedData.sent : relatedData.sentOn;
    await canvas.drawText({
      text: "Received On: " + sentOn,
      fontSize: 10,
      horizontalAlign: "left",
      margins: {
        button: 12,
        left: 10,
      },
    });
    const completedOn = relatedData.completion
      ? relatedData.completion
      : relatedData.completedOn;

    await canvas.drawText({
      text: "Completed On: " + completedOn,
      fontSize: 10,
      horizontalAlign: "right",
      margins: {
        button: 12,
        right: 10,
      },
    });
  });

  surveyPdf.save("complete_form.pdf");
};
