import React, { useState, useEffect } from "react";
import { User } from "../../utils/globalState";
import { SideBar } from "../../utils/globalState";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "survey-core/modern.min.css";
import "../../styles/page-styles/forms.css";
import {
  archiveDocResponse,
  cancelReminderDoc,
  disableLinkDoc,
  getDocResponses,
  reviewDocResponse,
} from "../../services/PharmacyUser";
import {
  convertTime,
  convertTimeLocally,
  convertDateToInputFormat,
  timeZoneDifference,
} from "../../utils/timeConverter";
import { getDocSubmitterById } from "../../services/docuseal";
import PreviewDocResponse from "../../components/pharmacyUser/documentsCenter/PreviewDocResponse";

const DocumentsAnalytics = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userInformation = User.getState().info;
  const pharmacyID = localStorage.getItem("pharmacy");
  const firstName = localStorage.getItem("first");
  const lastName = localStorage.getItem("last");
  const userName =
    userInformation.first && userInformation.last
      ? `${userInformation.first} ${userInformation.last}`
      : `${firstName} ${lastName}`;

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviwpageflag, setReviewPageFlag] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});
  const [msg, setMsg] = useState("");
  const [catCount, setCatCount] = useState({
    pending: 0,
    complete: 0,
    archived: 0,
  });
  const [startDate, setStartDate] = useState(
    new Date(new Date() - 1 * 24 * 60 * 60 * 1000 * 30)
  );
  const [endDate, setEndDate] = useState(new Date());

  const localdataUpdate = (item, rowID) => {
    return item.docResponse_ID !== rowID
      ? item
      : {
          ...item,
          isArchived: true,
          archivedBy: userName,
          archived_date: convertTimeLocally(new Date()),
        };
  };

  const columnsComplete = [
    { field: "docResponse_ID", headerName: "ID", minWidth: 75, maxWidth: 75 },
    { field: "sender", headerName: "Sent By", minWidth: 140 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 150 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 90, maxWidth: 90 },
    { field: "doc_ID", headerName: "Doc. ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Document Name", minWidth: 150 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 60,
      valueFormatter: (params) => (params.value ? "Yes" : "No"),
      cellClassName: (params) => (params.value ? "reviewed" : "not-reviewed"),
    },
    { field: "reviewedBy", headerName: "Reviewed BY", minWidth: 140 },
    {
      field: "reviewedOn",
      headerName: "Review Date",
      minWidth: 165,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 280,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => {
              handleReview(params);
            }}>
            Review
          </button>
          <button
            onClick={() => {
              let rowID = params.id;
              archiveDocResponse(rowID, userName)
                .then(() => {
                  setDateFilteredData(
                    dateFilteredData.map((item) => localdataUpdate(item, rowID))
                  );
                  setData(data.map((item) => localdataUpdate(item, rowID)));
                })
                .catch((err) => {
                  console.error(err);
                });
            }}>
            Archive
          </button>
          <button onClick={() => handleDownload(params)}>
            Download as PDF
          </button>
        </div>
      ),
    },
  ];

  const columnsPending = [
    { field: "docResponse_ID", headerName: "ID", minWidth: 75, maxWidth: 75 },
    { field: "sender", headerName: "Sent By", minWidth: 140 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 150 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 90, maxWidth: 90 },
    { field: "doc_ID", headerName: "Doc. ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Document Name", minWidth: 150 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "numOfReminders",
      headerName: "Reminders Sent",
      minWidth: 140,
    },
    {
      field: "dateOfLastReminder",
      headerName: "Last Reminder Date",
      minWidth: 165,
      Date: true,
    },

    {
      field: "isReviewed",
      headerName: "Reviewed",
      minWidth: 60,
      valueFormatter: (params) => (params.value ? "Yes" : "No"),
      cellClassName: (params) => (params.value ? "reviewed" : "not-reviewed"),
    },
    { field: "reviewedBy", headerName: "Reviewed BY", minWidth: 140 },
    { field: "reviewedOn", headerName: "Review Date", minWidth: 165 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 450,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            onClick={() => {
              handleReview(params);
            }}>
            Review
          </button>
          <button
            onClick={() => {
              handleCancelReminders(params);
            }}
            disabled={params.row.isStop}>
            {params.row.isStop ? "Reminders Paused" : "Pause Reminders"}
          </button>
          <button
            onClick={() => {
              handleCancelLink(params);
            }}
            disabled={params.row.isLinkDisabled}>
            {params.row.isLinkDisabled ? "Link Disabled" : "Disable Link"}
          </button>
        </div>
      ),
    },
  ];

  const columnsArchived = [
    { field: "docResponse_ID", headerName: "ID", minWidth: 75, maxWidth: 75 },
    { field: "sender", headerName: "Sent By", minWidth: 130 },
    { field: "recipientName", headerName: "Recipient Name", minWidth: 140 },
    { field: "recipientType", headerName: "Recipient Type", minWidth: 125 },
    { field: "MRN", headerName: "MRN", minWidth: 80, maxWidth: 80 },
    { field: "doc_ID", headerName: "Doc. ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Doc. Name", minWidth: 130 },
    {
      field: "sent",
      headerName: "Sent On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 165,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "archivedBy", headerName: "Archived By", minWidth: 120 },
    {
      field: "archived_date",
      headerName: "Archived On",
      minWidth: 170,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    { field: "status", headerName: "Status", minWidth: 90 },

    { field: "reviewedBy", headerName: "Reviewed BY", minWidth: 110 },
    { field: "reviewedOn", headerName: "Review Date", minWidth: 165 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => {
              handleReview(params);
            }}>
            View
          </button>
          <button onClick={() => handleDownload(params)}>
            Download as PDF
          </button>
        </div>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedRowId([]);
  };

  useEffect(() => {
    setIsLoading(true);

    getDocResponses(pharmacyID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",

              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",
              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completion: item.completion ? convertTime(item.completion) : "",
              sent: item.sent ? convertTime(item.sent) : "",
              sentDate: item.sent || "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setData(incoming);
        const filtered = incoming.filter((item) => {
          const sentDate = new Date(item.sentDate);

          sentDate.setTime(sentDate.getTime() + timeZoneDifference());

          return sentDate >= startDate && sentDate <= endDate;
        });
        setDateFilteredData(filtered);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyID]);

  useEffect(() => {
    if (value === 0) {
      const filtered = dateFilteredData.filter(
        (item) => item.status.toLowerCase() === "completed" && !item.isArchived
      );
      setSelectedData(filtered);
    } else if (value === 1) {
      const filtered = dateFilteredData.filter(
        (item) => item.status.toLowerCase() === "pending" && !item.isArchived
      );
      setSelectedData(filtered);
    } else if (value === 2) {
      const filtered = dateFilteredData.filter(
        (item) => item.isArchived === true
      );
      setSelectedData(filtered);
    }
  }, [dateFilteredData, value, startDate, endDate]);

  useEffect(() => {
    const completed = [];
    const pending = [];
    const archived = [];
    dateFilteredData.length &&
      dateFilteredData.forEach((item) => {
        if (item.status.toLowerCase() === "completed" && !item.isArchived) {
          completed.push(item);
        } else if (
          item.status.toLowerCase() === "pending" &&
          !item.isArchived
        ) {
          pending.push(item);
        } else if (item.isArchived) {
          archived.push(item);
        }
      });

    setCatCount({
      complete: completed.length,
      pending: pending.length,
      archived: archived.length,
    });
  }, [dateFilteredData, startDate, endDate]);

  const handleReview = (row) => {
    reviewDocResponse(row.id, {
      reviewedBy: userName,
    }).then(() => {
      setDateFilteredData(
        dateFilteredData.map((item) => {
          if (item.docResponse_ID === row.id) {
            if (item.isReviewed === true) {
              setCurrentResponse(item);
              setReviewPageFlag(true);
            } else {
              item = {
                ...item,
                isReviewed: true,
                reviewedBy: userName,
                reviewedOn: convertTimeLocally(new Date()),
              };
              setCurrentResponse(item);
              setReviewPageFlag(true);
            }
          }
          return item;
        })
      );
    });
  };

  const handleDownload = (row) => {
    setMsg("");
    let current = dateFilteredData.find(
      (item) => item.docResponse_ID === row.id
    );

    current && setCurrentResponse(current);
    if (current) {
      getDocSubmitterById(current.value.id)
        .then((res) => {
          const submitterData = res?.data?.submitter || {};

          const downloadURL = submitterData?.documents[0]?.url || "";

          if (downloadURL) {
            window.open(downloadURL, "_blank");
          }
        })
        .catch((err) => {
          setMsg("Error Downloading Document");
          console.error(err);
        });
    } else {
      setMsg("Error Downloading Document");
    }
  };

  function handleCancelReminders(row) {
    let rowID = row.id;

    cancelReminderDoc(rowID)
      .then(() => {
        setData(
          data.map((item) => {
            if (item.docResponse_ID === rowID) {
              return { ...item, isStop: true };
            }
            return item;
          })
        );
        setDateFilteredData(
          dateFilteredData.map((item) => {
            if (item.docResponse_ID === rowID) {
              return { ...item, isStop: true };
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleCancelLink(row) {
    let rowID = row.id;

    disableLinkDoc(rowID)
      .then((res) => {
        setData(
          data.map((item) => {
            if (item.docResponse_ID === rowID) {
              return { ...item, isLinkDisabled: true };
            }
            return item;
          })
        );
        setDateFilteredData(
          dateFilteredData.map((item) => {
            if (item.docResponse_ID === rowID) {
              return { ...item, isLinkDisabled: true };
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const changeHandler = (e) => {
    const diff = timeZoneDifference();
    const date = new Date(e.target.value);
    const dateUTC = new Date(date.getTime() + diff);
    const dateLocal = new Date(dateUTC.getTime() + diff);
    if (e.target.name === "startDate") {
      setStartDate(dateLocal);
    } else {
      setEndDate(dateLocal);
    }
    if (e.target.validationMessage) {
      e.target.style = "color:red;font-weight:bold;";
      setMsg(e.target.validationMessage);
    } else {
      e.target.style = "";
      setMsg("");
    }
  };

  useEffect(() => {
    const filtered = data.filter((item) => {
      const sentDate = new Date(item.sentDate);

      sentDate.setTime(sentDate.getTime() + timeZoneDifference());

      return sentDate >= startDate && sentDate <= endDate;
    });
    setDateFilteredData(filtered);
  }, [startDate, endDate, data]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Documents History</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Documents Center</li>
              <li
                onClick={() => {
                  setReviewPageFlag(false);
                  setCurrentResponse({});
                }}
                className={`breadcrumb-item ${
                  reviwpageflag ? "cstm_breadcrumb" : "active"
                }`}>
                Documents History
              </li>
              {reviwpageflag && (
                <li className="breadcrumb-item active">
                  View Response (id: {currentResponse.docResponse_ID}){" "}
                </li>
              )}{" "}
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {reviwpageflag ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setReviewPageFlag(false);
                      setCurrentResponse({});
                    }}>
                    Back
                  </Button>

                  <PreviewDocResponse data={currentResponse} />
                </>
              ) : (
                <>
                  {!isLoading ? (
                    <div>
                      {msg && (
                        <div className="ps-2 alert alert-danger" role="alert">
                          {msg}
                        </div>
                      )}
                      <Box sx={{ width: "100%" }}>
                        <div className="d-lg-flex flex-row-reverse justify-content-between">
                          <form className="d-flex flex-row h-75 my-auto">
                            <input
                              name="startDate"
                              onChange={changeHandler}
                              type="date"
                              className="form-control me-1"
                              id="startDate"
                              placeholder="Start Date"
                              value={convertDateToInputFormat(startDate)}
                              min="2008-01-01"
                              max={new Date().toISOString().split("T")[0]}
                            />
                            <input
                              name="endDate"
                              onChange={changeHandler}
                              type="date"
                              className="form-control"
                              id="endDate"
                              placeholder="Start Date"
                              value={convertDateToInputFormat(endDate)}
                              min="2008-01-01"
                              max={new Date().toISOString().split("T")[0]}
                            />
                          </form>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="tabs"
                            className="mb-1 ms-2">
                            <Tab
                              label={
                                <span>
                                  Complete
                                  <span className="text-success fw-bold">
                                    &nbsp;({catCount.complete})
                                  </span>
                                </span>
                              }
                            />

                            <Tab
                              label={
                                <span>
                                  Pending
                                  <span className="text-warning fw-bold">
                                    &nbsp;({catCount.pending})
                                  </span>
                                </span>
                              }
                            />
                            <Tab
                              label={
                                <span>
                                  Archived
                                  <span className="text-primary fw-bold ">
                                    &nbsp;({catCount.archived})
                                  </span>
                                </span>
                              }
                            />
                          </Tabs>
                        </div>
                        {value === 0 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => row.docResponse_ID}
                              columns={columnsComplete}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.docResponse_ID === selectedRowId
                                  ? "selected-row"
                                  : ""
                              }
                              initialState={{
                                sorting: {
                                  sortModel: [
                                    { field: "completion", sort: "desc" },
                                  ],
                                },
                              }}
                            />
                          </div>
                        )}
                        {value === 1 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => {
                                return row.docResponse_ID;
                              }}
                              columns={columnsPending}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.docResponse_ID === selectedRowId
                                  ? "selected-row"
                                  : ""
                              }
                            />
                          </div>
                        )}
                        {value === 2 && (
                          <div className="dg_1liner">
                            <DataGrid
                              pageSize={25}
                              rows={selectedData}
                              getRowId={(row) => row.docResponse_ID}
                              columns={columnsArchived}
                              density="compact"
                              components={{
                                Toolbar: GridToolbar,
                              }}
                              selectionModel={selectedRowId}
                              onSelectionModelChange={(newSelection) => {
                                setSelectedRowId(newSelection[0]);
                              }}
                              getRowClassName={(params) =>
                                params.row.status === "completed"
                                  ? `completed-row ${
                                      params.row.docResponse_ID ===
                                      selectedRowId
                                        ? "selected-row"
                                        : ""
                                    }`
                                  : `pending-row ${
                                      params.row.docResponse_ID ===
                                      selectedRowId
                                        ? "selected-row"
                                        : ""
                                    }`
                              }
                            />
                          </div>
                        )}
                      </Box>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <h3 className="text-center mt-5">
                        Loading Data, Please wait...
                      </h3>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocumentsAnalytics;
