import React, { useState, useEffect } from "react";
import { User } from "../../utils/globalState";
import { SideBar } from "../../utils/globalState";
import SurveyDissatisfaction from "./SurveyDissatisfaction";
import Cards from "../../components/pharmacyUser/dashboard/Cards";
import ColumnChart from "../../components/pharmacyUser/dashboard/ColumnChart";
import ColumnChart2 from "../../components/pharmacyUser/dashboard/ColumnChart2";
import "../../styles/commonStyles/dashboard.css";
import { decodeToken } from "react-jwt";
import { Divider } from "@mui/material";
import { convertTimeLocally } from "../../utils/timeConverter";
import { SocketIoServer } from "../../utils/globalState";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import ReschReqView from "../../components/pharmacyUser/CarePlanCenter/ReschReqView";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { getPharmaPrivileges } from "../../services/Admin";
import {
  surveyCounter,
  surveyCounterCompleted,
  yearSelect,
  monthlyCounter,
} from "../../utils/pharmacistDashboardHelpers";
import {
  getPatientsTotal,
  getPatientscharts,
  getProviders,
  getPharmacyAnnouncement,
  getTokens,
} from "../../services/PharmacyUser";
import {
  getNotificationsByUserID,
  getUserRole,
  getUserPrivilege,
  getUserById,
} from "../../services/Common";

const Dashboard = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const userInformation = User.getState().info;
  const pharmacyID = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [providers, setProviders] = useState([]);
  const [patients, setPatients] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [patientsTotal, setPatientsTotal] = useState(0);
  const [providersData, setProvidersData] = useState([]);
  const [surveyRawData, setSurveyRawData] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyCompleted, setSurveyCompleted] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [surveyYear, setSurveyYear] = useState(`${new Date().getFullYear()}`);
  const [ptYear, setPtYear] = useState(`${new Date().getFullYear()}`);
  const [prYear, setPrYear] = useState(`${new Date().getFullYear()}`);
  const [announcement, setAnnouncement] = useState("");
  const [userType, setUserType] = useState("");
  const [userPrivileges, setUserPrivileges] = useState([]);
  const value = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);
  const [completed, setCompleted] = useState({});
  const [thisReschReq, setThisReschReq] = useState({
    eventToChange: null,
    cpid: null,
  });
  const [notificationsTypes, setNotificationsTypes] = useState([]);
  const [tabvalue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    setSocket(value);
  }, [value]);

  const loadUserPrivilege = async () => {
    try {
      const userTypeResp = await getUserById(userID);
      const userType = userTypeResp.data.data.user[0].type;
      setUserType(userType.toLowerCase());

      let userPrivilegesFromDB = [];
      if (userType.toLowerCase() === "pharmacyadmin") {
        const pharmacyPrivilegeRes =
          (await getPharmaPrivileges(pharmacyID)) || {};
        const pharmadcyPrivilegesFromDB =
          pharmacyPrivilegeRes.data.data.map((priv) => priv.name) || [];

        console.log("pharmadcyPrivilegesFromDB", pharmadcyPrivilegesFromDB);
        setUserPrivileges(pharmadcyPrivilegesFromDB || []);
      } else {
        const roleResp = await getUserRole(userID);
        const roleID = roleResp.data.data[0].roleID;

        const privResp = await getUserPrivilege(roleID);
        userPrivilegesFromDB =
          privResp?.data?.data?.map((priv) => priv.name) || [];
        setUserPrivileges(userPrivilegesFromDB);
      }

      return userPrivilegesFromDB;
    } catch (error) {
      console.error("Error in getUserInfo:", error);
      return [];
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getNots = () => {
    getNotificationsByUserID(userID)
      .then((res) => {
        if (res.data.length !== 0) {
          setNotifications(
            res.data.filter(
              (note) =>
                note.receiver_id === myDecodedToken.userId.toString() &&
                note.sender_id !== myDecodedToken.userId.toString()
            )
          );
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setNotifications([]);
      });
  };

  useEffect(() => {
    if (socket !== null) {
      if (Object.keys(socket).length > 0) {
        socket.on("notifications", (data) => {
          getNots();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    getNots();

    getAllTokens();
    loadUserPrivilege();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPatientsTotal(pharmacyID)
      .then((res) => {
        setPatientsTotal(res.data.Patients);
      })
      .catch((err) => console.error(err.message));
    getProviders(pharmacyID)
      .then((res) => {
        res?.data?.data && setProviders(res.data.data);
      })
      .catch((err) => console.error(err.message));

    getPatientscharts(pharmacyID)
      .then((res) => {
        res?.data && setPatients(res.data);
      })
      .catch((err) => console.error(err.message));
    getPharmacyAnnouncement(pharmacyID)
      .then((res) => {
        setAnnouncement(res.data.pharmacy_announcement);
      })
      .catch((err) => {
        setAnnouncement("Error retreiving announcement");
        console.error(err.message);
      });
  }, [pharmacyID]);

  useEffect(() => {
    patients.length && setPatientsData(monthlyCounter(patients, ptYear, "pt"));
  }, [patients, ptYear]);

  useEffect(() => {
    providers.length &&
      setProvidersData(monthlyCounter(providers, prYear, "pr"));
  }, [providers, prYear]);

  useEffect(() => {
    surveyRawData.length &&
      setSurveyData(surveyCounter(surveyRawData, surveyYear));
    surveyRawData.length &&
      setSurveyCompleted(surveyCounterCompleted(surveyRawData, surveyYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRawData, surveyYear]);

  const getAllTokens = () => {
    getTokens()
      .then((res) => {
        setSurveyRawData(res.data.map((el) => JSON.parse(el.token)));
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleViewReq = (note) => {
    let moteurlarr = note.url.split("/");
    let id = moteurlarr[moteurlarr.length - 1];
    let idarr = id.split("&");
    let id1 = idarr[0];
    let id2 = idarr[1];
    setThisReschReq({
      eventToChange: id1,
      cpid: id2,
      thisOrder: note,
    });
  };

  const requestAction = (note) => {
    socket.emit("deletenote", { id: note.id });
  };
  useEffect(() => {
    let notesTypes = notifications.map((note) => note.body);
    setNotificationsTypes(
      notesTypes.filter(
        (note, i, arr) => note.body !== "message" && arr.indexOf(note) === i
      )
    );
  }, [notifications]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pharmacy Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">pharmacy-dashboard</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <ReschReqView req={thisReschReq} action={requestAction} />
            <div className="row card-body justify-content-between mb-3 mx-md-0 mx-2 ">
              <div
                className="col-md-3 bg-gradient-primary d-flex justify-content-between rounded d-none d-md-flex mt-2"
                id="dashboard_welcome_note">
                <div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h3 className="card-title   text-light text-center">
                      Hello {userInformation.first}
                    </h3>
                    <p className="text-light text-center ">
                      Have a nice day at work
                    </p>
                  </div>
                </div>
                <img
                  src={require("../../assets/images/heartofdrs.png")}
                  className="col-md-3 d-none d-xl-block "
                  style={{ margin: "5px", width: "5rem" }}
                  alt="doctors in heart"
                />
              </div>
            </div>

            <div className="container ">
              <div>
                <Cards
                  providers={providers.length || 0}
                  patients={patientsTotal || 0}
                  userType={userInformation.type}
                />
              </div>
              <div className="row dashboard_container">
                {userPrivileges.includes("Messages") && (
                  <div className="col-md-6">
                    <div className="card pb-1 mb-2 mb-md-0">
                      <h5 className="card-title mt-1 mb-0 pt-3 pb-0 ">
                        Recent Messages
                      </h5>
                      <Divider className="my-4" />
                      <div className="card-body">
                        <div className="dash_messages">
                          {notifications.length > 0 ? (
                            notifications
                              .filter((el) => el.body === "message")
                              .reverse()
                              .map((el, idx) => {
                                return (
                                  idx < 4 && (
                                    <div
                                      className=" mx-2 my-3 border border-2 recent_msg_div"
                                      key={idx}>
                                      <div className="card-body py-0">
                                        <h5 className="card-title text-capitalize">
                                          {el.sender_name}{" "}
                                          <small className="msg-timestamp text-lowercase fs-6">
                                            {convertTimeLocally(el.createdAt)}
                                          </small>
                                        </h5>

                                        <p className="card-text mb-2">
                                          {el.message}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                );
                              })
                          ) : (
                            <p className="text-center">no new messages</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="card pb-1 mb-2 mb-md-0">
                    <h5 className="card-title mt-1 mb-0 pt-3 pb-0 ">
                      Todo List
                    </h5>
                    <Divider className="my-4" />
                    <div className="card-body">
                      <div className="dash_messages">
                        {notifications.length > 0 ? (
                          //=====================================rescheduling request filter start ===================================================================================
                          <>
                            {/* =====================================Tabs start ================================================================== */}
                            {/* 
                            <Box sx={{ width: "100%", typography: "body1" }}> */}
                            <TabContext value={tabvalue}>
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "divider",
                                }}>
                                <TabList
                                  onChange={handleChange}
                                  aria-label="lab API tabs example">
                                  {notificationsTypes.map((el, idx) => {
                                    return (
                                      <Tab
                                        label={el}
                                        value={(idx + 1).toString()}
                                      />
                                    );
                                  })}
                                </TabList>
                              </Box>
                              {notificationsTypes.map((elm, i) => {
                                return (
                                  <TabPanel value={(i + 1).toString()}>
                                    {" "}
                                    {notifications
                                      .filter((el) => el.body === elm)
                                      .reverse()
                                      .map((el, idx) => {
                                        return (
                                          <div
                                            className="  border border-2 recent_msg_div my-3"
                                            key={idx}>
                                            <div className="card-body py-0">
                                              <FormControl
                                                component="fieldset"
                                                // sx={{ ml: 3 }}
                                              >
                                                <FormControlLabel
                                                  name={idx.toString()}
                                                  value={
                                                    completed[idx.toString()] ||
                                                    !el.unread
                                                  }
                                                  control={<Checkbox />}
                                                  label={
                                                    <h5
                                                      className={` text-capitalize ${
                                                        completed[
                                                          idx.toString()
                                                        ] || !el.unread
                                                          ? "text-decoration-line-through"
                                                          : ""
                                                      }`}>
                                                      {el.sender_name}{" "}
                                                      {dayjs(
                                                        el.createdAt
                                                      ).format(
                                                        "DD/MM/YYYY hh:mm A"
                                                      )}
                                                    </h5>
                                                  }
                                                  labelPlacement="end"
                                                  checked={
                                                    completed[idx] || !el.unread
                                                  }
                                                  onChange={(event) => {
                                                    setCompleted({
                                                      ...completed,
                                                      [idx]:
                                                        event.target.checked,
                                                    });
                                                    socket.emit("updatenote", {
                                                      id: el.id,
                                                    });
                                                  }}
                                                />
                                              </FormControl>
                                              <Stack
                                                direction="row"
                                                spacing={1}
                                                className="float-end mt-1">
                                                {el.body ===
                                                "reschedule-request" ? (
                                                  <button
                                                    className="btn btn-outline-info  btn-sm ms-2"
                                                    onClick={() => {
                                                      handleViewReq(el);
                                                    }}>
                                                    View
                                                  </button>
                                                ) : (
                                                  <a
                                                    href={el.url}
                                                    className="btn btn-outline-info  btn-sm ms-2"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    View
                                                  </a>
                                                )}
                                                <button
                                                  className="btn btn-outline-danger  btn-sm ms-2"
                                                  onClick={() =>
                                                    socket.emit("deletenote", {
                                                      id: el.id,
                                                    })
                                                  }>
                                                  Delete
                                                </button>
                                              </Stack>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </TabPanel>
                                );
                              })}
                            </TabContext>
                            {/* </Box> */}

                            {/* =====================================Tabs end =============================================================== */}
                          </>
                        ) : (
                          //=====================================rescheduling request filter end =====================================================================
                          <p className="text-center">NO TODO FOR TODAY</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row dashboard_container mt-4">
                <div className="col-md-12">
                  <div className="card pb-1 mb-2 mb-md-0">
                    <h5 className="card-title mt-1 mb-0 pt-3 pb-0 ">
                      Pharmacy Announcements!
                    </h5>
                    <Divider className="my-4" />
                    <div className="card-body">
                      <p className="card-text announcement_container">
                        {announcement}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {userPrivileges.includes("Survey Dissatisfaction") && (
                <div className="row my-4 dashboard_container">
                  <div className="col-12">
                    <div className="card p-0">
                      <div className="card-body px-0">
                        <h5 className="card-title ">
                          Survey Dissatisfaction Log
                        </h5>
                        <Divider className="mt-1" />
                        <SurveyDissatisfaction dashboard={true} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row my-5">
                {patientsData.length > 0 &&
                  userPrivileges.includes("Manage Patients") && (
                    <div className="col-12 col-lg-6">
                      {yearSelect().length > 1 && (
                        <select
                          className="form-select form-select-sm mb-3 mx-2"
                          aria-label=".form-select-md example"
                          defaultValue={"DEFAULT"}
                          id="yearSelection"
                          onChange={(e) => setPtYear(`${e.target.value}`)}>
                          <option disabled value="DEFAULT">
                            Select Year
                          </option>
                          {yearSelect().map((el) => (
                            <option key={el} value={`${el}`}>
                              {el}
                            </option>
                          ))}
                        </select>
                      )}
                      <ColumnChart
                        nameX="Patients"
                        info={patientsData}
                        titleX={`New Patients/Month for ${ptYear}`}
                        color="#25649e"
                        type="bar"
                      />
                    </div>
                  )}

                {providersData.length > 0 &&
                  userPrivileges.includes("Manage Providers") && (
                    <div className="col-12 col-lg-6">
                      {yearSelect().length > 1 && (
                        <select
                          className="form-select form-select-sm mb-3 mx-2"
                          aria-label=".form-select-md example"
                          defaultValue={"DEFAULT"}
                          id="yearSelection"
                          onChange={(e) => setPrYear(`${e.target.value}`)}>
                          <option disabled value="DEFAULT">
                            Select Year
                          </option>
                          {yearSelect().map((el) => (
                            <option key={el} value={`${el}`}>
                              {el}
                            </option>
                          ))}
                        </select>
                      )}
                      <ColumnChart
                        nameX="Providers"
                        info={providersData}
                        titleX={`New Providers/Month for ${prYear}`}
                        color="#00b7c7"
                        type="bar"
                      />
                    </div>
                  )}
              </div>
              {
                <div className="row my-5">
                  {surveyData.length > 0 &&
                    userPrivileges.includes("Manage Surveys") && (
                      <div className="col-12">
                        {yearSelect().length > 1 && (
                          <select
                            className="form-select form-select-sm mb-3 mx-2"
                            aria-label=".form-select-md example"
                            defaultValue={"DEFAULT"}
                            id="yearSelection"
                            onChange={(e) =>
                              setSurveyYear(`${e.target.value}`)
                            }>
                            <option disabled value="DEFAULT">
                              Select Year
                            </option>
                            {yearSelect().map((el) => (
                              <option key={el} value={`${el}`}>
                                {el}
                              </option>
                            ))}
                          </select>
                        )}
                        <ColumnChart2
                          nameX="Surveys Sent"
                          nameY="Surveys Completed"
                          infoX={surveyData}
                          infoY={surveyCompleted}
                          titleX={`Total Surveys/Month for ${surveyYear}`}
                          colorX="#25649e"
                          colorY="#25889e"
                          type="area"
                        />
                      </div>
                    )}
                </div>
              }
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
