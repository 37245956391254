import React, { useState } from "react";
import curorx_re_site_logo from "../../../assets/images/CuroRX_RE_SiteLogo.gif";
import { useNavigate, Link } from "react-router-dom";
import { handleAuth, getPharmacyById } from "../../../services/Common";
import { User, Pharmacy } from "../../../utils/globalState";
import { handleGetPharmacies } from "../../../services/Admin";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setBtn(true);
    let payload = {};

    const { email, password } = user;
    if (user.email.includes("@")) {
      payload = { email, password };
    } else {
      payload = { phone: email, password };
    }

    handleAuth(payload)
      .then((resp) => {
        if (resp) {
          console.log(resp);
          User.setState({ info: resp.data });
          const token = resp.data.token;
          const pharmacy = resp.data.pharmacyID;
          const userType = resp.data.user_type;

          if (userType !== "Admin") {
            getPharmacyById(pharmacy)
              .then((resp) => {
                localStorage.setItem("pharmacyName", resp.data.name);
              })
              .catch((err) => {
                console.error(err);
              });
          }

          const first = resp.data.first;
          const last = resp.data.last;
          const role = resp.data.Role;
          localStorage.setItem("pharmacy", pharmacy);
          localStorage.setItem("first", first);
          localStorage.setItem("last", last);
          localStorage.setItem("role", role);

          if (token) {
            localStorage.setItem("Token", token);
          }

          if (resp.data.user_type === "User") {
            navigate("/pharmacy-user/dashboard");
          } else if (resp.data.user_type.toLowerCase() === "pharmacyadmin") {
            navigate("/pharmacy-admin/dashboard");
          } else if (resp.data.user_type.toLowerCase() === "provider") {
            navigate("/provider/dashboard");
          } else if (resp.data.user_type.toLowerCase() === "patient") {
            if (resp.data.active) {
              navigate("/patient/homepage");
            } else {
              setErrorMsg("User is not active. Please contact admin.");
            }
          } else if (resp.data.user_type.toLowerCase() === "admin") {
            navigate("/admin/managePharmacy");
          } else if (resp.data.user_type.toLowerCase() === "patient") {
            if (resp.data.active) {
              navigate("/patient/homepage");
            } else {
              setErrorMsg("User is not active. Please contact admin.");
            }
          } else {
            navigate("/");
          }

          handleGetPharmacies(pharmacy).then((resp) => {
            Pharmacy.setState({
              info: resp.data.data.find((item) => item.id === pharmacy),
            });
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setErrorMsg("Invalid credentials. Please try again.");
      });
  };

  return (
    <div>
      <div className="container-fluid clearfix" id="index-top-logo-login-div">
        <div className="row g-0">
          <div className="col-md-6">
            <img
              src={curorx_re_site_logo}
              className="img-fluid"
              id="curo-re-logo"
              alt="Welcome to CuroRX"
            ></img>
          </div>
          <div className="col-md-6 text-end" id="top-login-div"></div>
        </div>
        <div id="index-welcome-div">
          <div className="col offset-0" id="index-welcome-div-login">
            <h1 className="fs-1 " id="index-welcome-div-h1">
              Welcome to <span className="curo">Curo</span>
              <span className="rx">RX</span>
            </h1>
            <p id="index-welcome-div-p">
              The all-in-one patient management platform designed with you in
              mind.
            </p>

            <form
              className="col-12  d-flex flex-column flex-wrap my-5 row"
              onSubmit={handleSubmit}
            >
              {errorMsg && <div className="col-12">{errorMsg}</div>}

              <div className="d-flex flex-row gap-2 flex-wrap ">
                <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email or Phone number"
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                  &nbsp;
                </div>
                <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input
                      type={password}
                      className="form-control"
                      aria-label="Amount (to the nearest dollar)"
                      name="password"
                      placeholder="Password"
                      value={user.password}
                      onChange={handleChange}
                      required
                    />
                    <span className="input-group-text">
                      <i
                        onClick={Eye}
                        className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-row flex-wrap justify-content-between align-content-center">
                <div className="register-links d-flex flex-column">
                  <Link to="/register"> REGISTER </Link>
                  <Link to="/resetpassword"> FORGOT YOUR PASSWORD ? </Link>
                </div>{" "}
                <button
                  className="curo_btn_primary col-12 col-sm-3 mt-2 mt-sm-0 "
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <div id="four-tiles-div">
          <div className="four-tiles-row">
            <div
              className="col-12 col-sm-6 col-lg-3 col-xl-3"
              id="tile-send-new-referral"
            >
              <i
                className="fas fa-file-prescription d-lg-block d-xl-block justify-content-xxl-center"
                id="icon-send-new-referral"
              ></i>
              <p
                className="d-lg-block d-xl-block fs-auto"
                id="tile-text-send-new-referral"
              >
                SEND A NEW&nbsp;
                <strong>REFERRAL </strong>
              </p>
            </div>
            <div
              className="col-12 col-sm-6 col-lg-3 col-xl-3"
              id="tile-new-patient"
            >
              <i
                className="fas fa-user-plus d-lg-block d-xl-block justify-content-xxl-center"
                id="icon-new-patient-registration"
              ></i>
              <p
                className="d-lg-block d-xl-block fs-auto"
                id="tile-text-new-patient-registration"
              >
                NEW PATIENT <strong> REGISTRATION </strong>
              </p>
            </div>
            <div
              className="col-12 col-sm-6 col-lg-3 col-xl-3"
              id="tile-register-as-new-provider"
            >
              <i
                className="fas fa-user-md d-lg-block d-xl-block justify-content-xxl-center"
                id="icon-register-as-new-provider"
              ></i>
              <p
                className="d-lg-block d-xl-block fs-auto"
                id="tile-text-register-as-new-provider"
              >
                NEW PROVIDER <strong> REGISTRATION </strong>
              </p>
            </div>
            <div
              className="col-12 col-sm-6 col-lg-3 col-xl-3"
              id="tile-training-resources"
            >
              <i
                className="fas fa-envelope d-lg-block d-xl-block justify-content-xxl-center"
                id="icon-training-resources"
              ></i>
              <p
                className="d-lg-block d-xl-block fs-auto"
                id="tile-text-training-resources"
              >
                SEND/RECEIVE<strong> MESSAGES </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
