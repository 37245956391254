import React, { useEffect, useState } from "react";
import { SideBar } from "../../../utils/globalState";
import { getSurveys, deleteSurvey } from "../../../services/Provider";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const ManageSurvey = () => {
  const navigate = useNavigate();
  // SideBar visibility state.
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;
  const [surveysList, setSurveysList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currSurvey, setCurrSurvey] = useState({});
  const [open, setOpen] = React.useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState("");
  const [deleteSurveyErrorFlag, setDeleteSurveyErrorFlag] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [msg, setMsg] = useState({
    text: "",
    status: false,
  });

  const colors = [
    "#FF0000",
    "#FF8700",
    "#FFD300",
    "#DEFF0A",
    "#A1FF0A",
    "#0AFF99",
    "#0AEFFF",
    "#147DF5",
    "#580AFF",
    "#BE0AFF",
  ];

  const getColor = (idx) => {
    return colors[idx % colors.length];
  };

  useEffect(() => {
    getSurveys()
      .then((res) => {
        setIsLoading(false);

        const surveys = res.data.Surveys.filter(
          (survey) => survey.createdBy === userId.toString()
        ).map((survey, idx) => {
          survey.json = JSON.parse(survey.survey);
          survey.color = getColor(idx);
          return survey;
        });
        setSurveysList(surveys);
        setSearchData(surveys);
      })
      .catch((err) => {
        setIsLoading(false);
        setSurveysList([]);
        setMsg({ text: err.message, status: false });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleAddSurvey = () => {
    navigate(`edit-and-review`);
  };

  // ===================================================================
  // Delete Survey

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCurrSurvey({});
    setOpen(false);
  };

  const handleDeleteSurvey = (survey) => {
    setCurrSurvey(survey);
    handleClickOpen();
  };

  const handleChange = (event) => {
    setDeleteInputValue(event.target.value);
  };
  const MyFormHelperText = () => {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (focused) {
        if (deleteInputValue !== currSurvey.json.title) {
          setDeleteSurveyErrorFlag(true);
          return "Please enter the correct survey title";
        } else {
          setDeleteSurveyErrorFlag(false);
          return "";
        }
      }
      // return '';
    }, [focused]);

    return <FormHelperText>{helperText}</FormHelperText>;
  };

  const handleConfirmDeleteSurvey = () => {
    deleteSurvey(currSurvey.id)
      .then((res) => {
        handleClose();
        setMsg({ text: "Survey deleted successfully", status: true });
        setSurveysList(
          surveysList.filter((survey) => survey.id !== currSurvey.id)
        );
        setSearchData(
          searchData.filter((survey) => survey.id !== currSurvey.id)
        );
        setCurrSurvey({});
      })
      .catch((err) => {
        console.error(err);
        setMsg({ text: err.message, status: false });
      })
      .finally(() => {
        setTimeout(() => {
          setMsg({ text: "", status: false });
        }, 5000);
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let searchLower = e.target.value.toLowerCase() || "";
    if (e.target.value === "") {
      setSearchData(surveysList);
    }
    setSearchData(
      surveysList.filter((el) =>
        el.json.title.toLowerCase().includes(searchLower)
      )
    );
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Survey Center</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                Surveys Management Board
              </li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {msg.text && (
                <p className={`text-${msg.status ? "success" : "danger"} fs-6`}>
                  {msg.text}
                </p>
              )}
              <div className="mb-2">
                <button className="btn btn-primary" onClick={handleAddSurvey}>
                  <i className="fa fa-plus" aria-hidden="true"></i> ADD NEW
                  SURVEY
                </button>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="card px-2 pb-3 col-12  pt-4">
                    <div className="col-12 col-md-6 col-lg-4 my-2  ps-lg-5">
                      <div className="input-group flex-nowrap ">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Search Documents"
                          aria-label="Search"
                          aria-describedby="addon-wrapping"
                          onChange={handleSearch}
                          value={search}
                        />
                        <span className="input-group-text" id="addon-wrapping">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            size={"1x"}
                          />
                        </span>
                      </div>
                    </div>
                    <>
                      {searchData.length > 0 ? (
                        <div className="cstm_Survey_table col-12 col-lg-12 px-lg-5">
                          {searchData.map((survey) => {
                            return (
                              <div
                                key={survey.id}
                                className="cstm_Survey_table_row card col-12"
                                style={{ backgroundColor: survey.color }}>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="py-2 ps-3" colSpan="5">
                                        {survey.json.title}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="col-12 ">
                                    <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            navigate(
                                              `send-survey/?id=${survey.id}`
                                            );
                                          }}>
                                          Upload Recipient List & Send
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            navigate(
                                              `edit-and-review/?id=${survey.id}`
                                            );
                                          }}>
                                          View & Edit
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            navigate(
                                              `results/?id=${survey.id}`
                                            );
                                          }}>
                                          Results
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            navigate(
                                              `analytics/?id=${survey.id}`
                                            );
                                          }}>
                                          Analytics
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1 p-0">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                          onClick={() =>
                                            handleDeleteSurvey(survey)
                                          }>
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <> no Surveys Available </>
                      )}
                    </>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          {Object.keys(currSurvey).length > 0 ? (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                Please Confirm {currSurvey.json.title} Survey Deletion.
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  To delete {currSurvey.json.title} survey, please confirm by
                  typing the following "{currSurvey.json.title}" in the input
                  field below.:
                  <form noValidate autoComplete="off">
                    <FormControl sx={{ width: "35ch", marginTop: 2 }}>
                      <OutlinedInput
                        placeholder="Please enter text"
                        onChange={handleChange}
                        error={deleteSurveyErrorFlag}
                        value={deleteInputValue}
                      />
                      <MyFormHelperText />
                    </FormControl>
                  </form>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDeleteSurvey}
                  disabled={
                    deleteSurveyErrorFlag &&
                    deleteInputValue !== currSurvey.json.title
                  }>
                  Confirm Delete
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <></>
          )}
        </div>
      </main>
    </div>
  );
};

export default ManageSurvey;
