import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { convertTime } from "../../utils/timeConverter";
import { SideBar } from "../../utils/globalState";
import {
  handleDocResponse,
  getResponseById,
} from "../../services/PharmacyUser";
import { getDocsTemplates, getDocSubmitterById } from "../../services/docuseal";
import baseURL from "./../../utils/baseURL";
import { DocusealForm } from "@docuseal/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleProfileUpdate } from "../../services/PharmacyUser";

const DocSignPage = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const { docResponseId } = useParams();
  const navigate = useNavigate();
  const [currentResponse, setCurrentResponse] = useState({});
  const [completed, setCompleted] = useState(false);
  const [submiter, setSubmiter] = useState("");
  const [email, setEmail] = useState("");
  const [skipEmail, setSkipEmail] = useState(false);
  useEffect(() => {
    getResponseById(docResponseId)
      .then((res) => {
        const item = res.data[0];

        const updatedItem = {
          ...item,
          isStop: item.isStop === 1 ? true : false,
          formText: item.formText ? JSON.parse(item.formText) : "",
          value: item.value ? JSON.parse(item.value) : "",

          senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
          recipientInfo: item.recipientInfo
            ? JSON.parse(item.recipientInfo)
            : "",
          sender: item.senderInfo
            ? JSON.parse(item.senderInfo).first +
              " " +
              JSON.parse(item.senderInfo).last
            : "",
          recipientName: item.recipientInfo
            ? JSON.parse(item.recipientInfo).first +
              " " +
              JSON.parse(item.recipientInfo).last
            : "",

          recipientType: item.recipientInfo
            ? JSON.parse(item.recipientInfo).type
            : "",
          MRN: item.recipientInfo
            ? JSON.parse(item.recipientInfo).MRN || "N/A"
            : "",
          formName: item.formText ? JSON.parse(item.formText).title : "",

          isArchived: item.isArchived === 1 ? true : false,
          archived_date: item.archived_date
            ? convertTime(item.archived_date)
            : "",
          isReviewed: item.isReviewed === 1 ? true : false,
          reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
          completion: item.completion ? convertTime(item.completion) : null,
          sent: item.sent ? convertTime(item.sent) : "",
          dateOfLastReminder: item.dateOfLastReminder
            ? convertTime(item.dateOfLastReminder)
            : "",
        };
        getDocsTemplates(item.docsID).then((resp) => {
          console.log(resp);
          updatedItem.docsTemplate = resp.data.templates[0] || {};
          setCurrentResponse(updatedItem);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [docResponseId]);

  const handleComplete = (data) => {
    handleDocResponse({ value: data.submission }, docResponseId).then((res) => {
      setCompleted(true);
    });
  };
  console.log(currentResponse?.value?.url);
  useEffect(() => {
    if (currentResponse?.value?.id) {
      getDocSubmitterById(currentResponse.value.id).then((res) => {
        console.log(res);
        setSubmiter(res.data.submitter);
      });
    }
  }, [currentResponse]);
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div>
                <button
                  className="btn btn-success col-auto mb-1"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </button>
                {Object.keys(currentResponse).length > 0 ? (
                  <div>
                    {currentResponse.completion || completed ? (
                      <div className="col-12">
                        <p className="text-success xtext">
                          Form submitted successfully
                        </p>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                          className="x"
                        >
                          <circle
                            class="path circle"
                            fill="none"
                            stroke="#73AF55"
                            stroke-width="6"
                            stroke-miterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            class="path check"
                            fill="none"
                            stroke="#73AF55"
                            stroke-width="6"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>
                        <div>
                          {currentResponse.completion && (
                            <>
                              <button
                                className="btn btn-Link text-bold"
                                onClick={() => {
                                  let link = document.createElement("a");
                                  link.href = `${submiter.documents[0].url}`;
                                  link.download = "signed-copy.pdf";
                                  link.target = "_blank";
                                  link.click();
                                }}
                              >
                                {" "}
                                <FileDownloadIcon /> Download a copy
                              </button>
                              <DocusealForm
                                withDownloadButton={true}
                                src={`https://${baseURL.docuseal}/s/${submiter?.slug}`}
                                preview={true}
                                email={
                                  currentResponse?.recipientInfo?.email ||
                                  `admin@nextehealth.com`
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          currentResponse?.recipientInfo?.email
                           ||
                          skipEmail ? (
                            <DocusealForm
                              src={`https://${baseURL.docuseal}/d/${currentResponse?.docsTemplate.slug}`}
                              onComplete={(data) => handleComplete(data)}
                              sendCopyEmail
                              email={
                                currentResponse?.recipientInfo?.email ||
                                email ||
                                `${currentResponse?.recipientInfo?.first} ${currentResponse?.recipientInfo?.last}@curorx.life`
                              }
                            />
                          ) : (
                            <>
                              <div className="text-center col-6 mx-auto mt-5">
                                <form>
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">
                                      <p class="text-warning">
                                        We noticed that we don't have your email
                                        address in our records. If you wish to
                                        add it, please enter it here. If you
                                        don't wish to add it, you can skip this
                                        step.
                                      </p>
                                    </label>
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter email"
                                      value={email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-link my-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSkipEmail(true);
                                    }}
                                  >
                                    Skip
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      handleProfileUpdate(
                                        currentResponse.recipientInfo.id,
                                        {
                                          email: email,
                                          updatedBy:
                                            currentResponse.recipientInfo.id,
                                        }
                                      )
                                        .then((res) => {
                                          setSkipEmail(true);
                                        })
                                        .catch((err) => {
                                          setSkipEmail(true);
                                          console.error(err);
                                        });
                                    }}
                                  >
                                    Add & Continue
                                  </button>
                                </form>
                              </div>
                            </>
                          )
                        }
                      </>
                    )}
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <h3 className="text-center mt-5">
                      Loading Data, Please wait...
                    </h3>
                    <div className="spinner-border text-info" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocSignPage;
