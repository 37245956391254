import React, { useState, useEffect } from "react";
import { DocusealForm } from "@docuseal/react";
import {
  getDocsTemplates,
  getDocSubmitterById,
} from "../../../services/docuseal";
import baseURL from "../../../utils/baseURL";

const PreviewDocResponse = (props) => {
  const { data: currentResponse } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [srcUrl, setSrcUrl] = useState(null);

  useEffect(() => {
    if (currentResponse.status === "pending") {
      getDocsTemplates(currentResponse.doc_ID)
        .then((res) => {
          setSrcUrl(
            `https://${baseURL.docuseal}/d/${res.data.templates[0].slug}`
          );
        })
        .catch((err) => {
          console.error("err", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (
      currentResponse.status === "completed" &&
      currentResponse.value.id
    ) {
      getDocSubmitterById(currentResponse.value.id)
        .then((res) => {
          const submitterData = res.data.submitter;
          setSrcUrl(`https://${baseURL.docuseal}/s/${submitterData.slug}`);
        })
        .catch((err) => {
          console.error("err", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setSrcUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResponse.doc_ID, currentResponse.status]);

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>

          <p className="mt-3">Loading Document...</p>
        </div>
      ) : (
        <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {srcUrl ? (
            <DocusealForm
              src={srcUrl}
              preview={true}
              withDownloadButton={true}
            />
          ) : (
            <div>
              {/*Error loading Document*/}
              <div className="alert alert-danger text-center" role="alert">
                Error loading Document
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PreviewDocResponse;
