import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { savePdf } from "../../utils/savePdf";
import ReviewResponse from "../../components/pharmacyUser/formCenter/ReviewResponse";
import {
  handleGetCarePlansByUserId,
  handleGetCpFormsByPharmacyId,
  handleGetCpResponsesByCpId,
} from "../../services/CarePlan";

const CPHistory = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const pharmacyID = parseInt(localStorage.getItem("pharmacy"));

  const [allData, setAllData] = useState([]);
  const [cps, setCps] = useState([]);
  const [forms, setForms] = useState([]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(null);
  const [isView, setIsView] = useState(false);

  console.log("allData", allData);

  const columnDef = [
    {
      field: "formName",
      headerName: "Form Name",
      sortable: true,
      width: 200,
    },
    {
      field: "date",
      headerName: "Follow-Up Date",
      sortable: true,
      minWidth: 200,
      valueGetter(params) {
        return dayjs(params.row.sentOn).format("MM/DD/YYYY");
      },
    },
    {
      field: "completedOn",
      headerName: "Completed On",
      sortable: true,
      width: 200,
      valueGetter(params) {
        return params.row.completedOn
          ? dayjs(params.row.completedOn).format("MM/DD/YYYY")
          : "Not Completed";
      },
    },
    {
      field: "location",
      headerName: "fullow up Preference",
      sortable: true,
      width: 200,
      valueGetter(params) {
        return params.row.cp.isPhoneFu ? "By Phone" : "By Form";
      },
    },
    {
      field: "formType",
      headerName: "Form Type",
      sortable: true,
      width: 200,
      valueGetter(params) {
        return params.row.isInitResponse
          ? "Initial Assessment"
          : "Follow Up Assessment";
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 200,
      renderCell: (params) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setCurrentResponse(params.row);
              setIsView(true);
            }}>
            View
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              savePdf(params.row.form.JSON, params.row.value, params.row);
            }}>
            Download
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    handleGetCarePlansByUserId(userID)
      .then((res) => {
        setCps(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    handleGetCpFormsByPharmacyId(pharmacyID)
      .then((res) => {
        setForms(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userID, pharmacyID]);

  useEffect(() => {
    Promise.all(
      cps.map(async (cp) => {
        return handleGetCpResponsesByCpId(cp.id)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
          });
      })
    ).then((res) => {
      setResponses(res.flat());
    });
  }, [cps]);

  useEffect(() => {
    const data = responses.map((response) => {
      const cp = cps.find((cp) => cp.id === response.cpId);
      const form = forms.find((form) => form.id === response.cpFormId);
      return {
        ...response,
        completedOn: dayjs(response.completedOn).format("MM/DD/YYYY hh:mm A"),
        sentOn: dayjs(response.sentOn).format("MM/DD/YYYY hh:mm A"),
        cp: cp,
        form: { ...form, JSON: JSON.parse(form.formText) },
        formText: JSON.parse(form.formText),
        formName: form.formText ? JSON.parse(form.formText).title : "",
        value: JSON.parse(response.value) || {},
      };
    });
    setAllData(data);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses]);

  return (
    <div>
      {loading ? (
        <div className="text-center mt-5">
          <h3 className="text-center mt-5">Loading Data, Please wait...</h3>
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {isView ? (
            <>
              <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  setIsView(false);
                  setCurrentResponse({});
                }}>
                Back
              </Button>

              <ReviewResponse data={currentResponse} />
            </>
          ) : (
            <div className="col-12 mt-3 mb-3 dg_1liner">
              <DataGrid
                rows={allData}
                columns={columnDef}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 100 },
                  },
                }}
                pageSizeOptions={[5, 100]}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CPHistory;
