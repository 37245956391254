import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  sendDoc,
  storeDocResponseLinkInDB,
  deleteDocResponse,
  getProfile,
} from "../../../services/PharmacyUser";
import { decodeToken } from "react-jwt";
import { sendSMS, urlShortner, sendEmail } from "../../../services/Common";
import baseURL from "../../../utils/baseURL";
import {
  searchPtForms as searchUsers,
  searchPt,
} from "../../../services/Provider";

function RecipentsFromDB(props) {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userId = myDecodedToken.userId;

  const [selected, setSelected] = useState([]);
  const [recepint, setRecepint] = useState("");
  const [suggistions, setSuggistions] = useState([]);
  const [smsLog, setSmsLog] = useState([]);
  const [columns, setColumns] = useState([]);
  const [logflag, setLogflag] = useState(false);
  const [sfirsname, setSfirsname] = useState([]);
  const [slastname, setSlastname] = useState([]);
  const [smrn, setSmrn] = useState([]);
  const [provider, setProvider] = useState({});

  useEffect(() => {
    getProfile(userId).then((res) => {
      setProvider(res.data.data.user[0]);
    });
  }, [userId]);

  useEffect(() => {
    let combinedResults = [...sfirsname, ...slastname, ...smrn];

    let nameToMrnMap = {};
    combinedResults.forEach((element) => {
      let [name, mrn] = element.split("-MRN: ");
      nameToMrnMap[name] = mrn ? `MRN: ${mrn}` : "";
    });

    let uniqueNames = Object.keys(nameToMrnMap);

    let uniqueUsers = uniqueNames.map((name) => {
      let mrn = nameToMrnMap[name];
      return mrn ? `${name}-${mrn}` : name;
    });

    setSuggistions(
      uniqueUsers
        .map((user) => {
          return selected.includes(user) ? null : user;
        })
        .filter((el) => el !== null) || []
    );
  }, [sfirsname, slastname, smrn, selected]);

  useEffect(() => {
    let c = [
      { field: "id", headerName: "ID", width: 80 },
      { field: "first", headerName: "First name", width: 100 },
      { field: "last", headerName: "Last name", width: 100 },
      {
        field: "phone",
        headerName: "Phone",
        width: 150,
        valueFormatter: (params) => (params.value ? params.value : "N/A"),
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
        valueFormatter: (params) => (params.value ? params.value : "N/A"),
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        valueFormatter: (params) => (params.value ? "Success" : "Failed"),
      },
      { field: "msg", headerName: "Details", width: 700 },
    ];
    setColumns(c);
  }, []);

  const handleSearch = (e) => {
    setRecepint(e.target.value);

    try {
      let fnamesearch = `first=${e.target.value}&last=&MRN=`;

      searchUsers(fnamesearch, userId).then((res) => {
        if (res.data?.data?.length > 0) {
          setSfirsname([
            ...sfirsname,
            ...res.data.data.map((el) => {
              return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
            }),
          ]);
        }
      });
      let lnamesearch = `first=&last=${e.target.value}&MRN=`;
      searchUsers(lnamesearch, userId).then((res) => {
        if (res.data.length > 0) {
          setSlastname([
            ...slastname,
            ...res.data.data.map((el) => {
              return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
            }),
          ]);
        }
      });

      let mrnsearch = {
        first: "",
        last: "",
        email: "",
        phone: "",
        DOB: "",
        MRN: e.target.value,
      };
      searchPt(mrnsearch, 0, userId).then((res) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            setSmrn([
              ...smrn,
              ...res.data.data
                .filter((el) => el.user.id.toString() !== userId)
                .map((el) => {
                  return `${el.user.id}-${el.user.first} ${el.user.last}-MRN: ${el.user.MRN}`;
                }),
            ]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const hanleSetRecipents = () => {
    setLogflag(false);
    setSmsLog([]);

    if (recepint !== "") {
      setSelected([...selected, recepint]);
    }
    setRecepint("");
  };

  const heandleSend = () => {
    selected.forEach((el) => {
      getProfile(el.split("-")[0]).then((resp) => {
        let phone = resp?.data?.data?.user[0]?.phone
          ? resp.data.data.user[0].phone
          : "";
        let first = resp.data.data.user[0].first;
        let last = resp.data.data.user[0].last;
        let recId = resp.data.data.user[0].id;
        let email = resp.data.data.user[0].email || "";

        if (phone.trim() !== "") {
          let clean = ("" + phone.trim()).replace(/\D/g, "");
          let match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
          const invcodes = [
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "800",
            "888",
            "877",
            "866",
            "855",
            "844",
            "833",
            "001",
            "011",
            "002",
            "000",
          ];

          if (match) {
            if (invcodes.includes(match[1])) {
              phone = "";
            } else {
              phone = `${match[1]}${match[2]}${match[3]}`;
            }
          }
        }
        sendDoc({
          DocId: props.data.doc.ID.toString(),
          senderId: userId,
          recipientId: recId.toString(),
        })
          .then(async (re) => {
            let data = {
              date: new Date(),
              completed: "",
              PatientId: el.split("-")[0],
              Statusflag: "pending",
              FormId: props.data.doc.ID,
              Token: re.data.response_id,
              ActionUserId: "",
              recipientName: `${first} ${last}`,
            };
            let urlLong = `${baseURL.frontEnd}/appless-docs-p/?fid=${props.data.doc.ID}&rid=${recId}&reid=${re.data.response_id}&pr=${userId}`;
            const url = await urlShortner(urlLong);
            sendSMS({
              to: phone,
              body: `Dear ${first} ${last}, You were sent a Document from ${
                provider.first + " " + provider.last
              } Pharmacy. Please take a moment to read and sign the document linked below. Your responses will help us provide you with the best possible care. ${
                url ? url : urlLong
              } , Thank you for your cooperation! If you would like to unsubscribe please send STOP`,
            })
              .then(() => {
                setSmsLog((prev) => [
                  ...prev,
                  {
                    id: recId,
                    first: first,
                    last: last,
                    msg: `Sent SMS to Phone`,
                    status: true,
                    phone: phone,
                    email: email,
                  },
                ]);
              })
              .catch(() => {
                sendEmail({
                  to: email,
                  message: `Dear ${first} ${last}, You were sent a Document from Dr. ${
                    provider.first + " " + provider.last
                  }. Please take a moment to read and sign the document linked below. Your responses will help us provide you with the best possible care. ${
                    url ? url : urlLong
                  } , Thank you for your cooperation!`,
                })
                  .then(() => {
                    setSmsLog((prev) => [
                      ...prev,
                      {
                        id: recId,
                        first: first,
                        last: last,
                        msg: `Sent to Email`,
                        status: true,
                        phone: phone,
                        email: email,
                      },
                    ]);
                  })
                  .catch((err) => {
                    setSmsLog((prev) => [
                      ...prev,
                      {
                        id: recId,
                        first: first,
                        last: last,
                        msg: `Failed to send Via Phone and Email`,
                        status: false,
                        phone: phone,
                        email: email,
                      },
                    ]);
                    deleteDocResponse(data.Token).catch((err) => {
                      console.error(err);
                    });
                  });
              })
              .finally(() => {
                storeDocResponseLinkInDB(data.Token, url).catch((err) => {
                  console.error(err);
                });
                setSelected([]);
                setSfirsname([]);
                setSlastname([]);
                setSmrn([]);
                setSuggistions([]);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      });
    });
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div
            className="card-header fs-5"
            style={{
              textAlign: "center",
              color: "whitesmoke",
              background:
                "linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)",
            }}>
            Search for Recipients
          </div>
          <div className="card-body">
            <div className="input-group  mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Recipient's Name..."
                aria-label="Recipient's username with two button addons"
                list="datalistOptions"
                onChange={handleSearch}
                value={recepint}
              />
              <datalist id="datalistOptions">
                {suggistions.map((item) => {
                  return <option value={item} key={item} />;
                })}
              </datalist>
              <button
                className="btn btn-outline-secondary"
                onClick={hanleSetRecipents}
                type="button">
                Add
              </button>
            </div>
            <TagsInput
              value={selected}
              onChange={setSelected}
              name="Receipts"
              placeHolder="Receipts List..."
            />
            <Button
              variant="contained"
              style={{ float: "right", marginTop: "20px" }}
              onClick={heandleSend}
              disabled={selected.length === 0}>
              Send Document
            </Button>
          </div>
          <p style={{ textAlign: "center" }}>
            {smsLog.length > 0 ? (
              <>
                Succeeded: {smsLog.filter((log) => log.status).length} Failed:{" "}
                {smsLog.filter((log) => !log.status).length}
              </>
            ) : null}
            {smsLog.length > 0 ? (
              <>
                <Button
                  onClick={() => {
                    setLogflag(true);
                  }}
                  variant="text">
                  Log
                </Button>{" "}
              </>
            ) : null}
          </p>
        </div>
      </div>
      {logflag ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}>
            {logflag ? (
              <>
                <div className="col-12 mb-4 px-1">
                  {smsLog.length > 0 && (
                    <div className="my-1" style={{ height: 500 }}>
                      <h5 className="text-center fw-bold">
                        Sent Documents Status Log
                      </h5>
                      <DataGrid
                        rows={smsLog}
                        columns={columns}
                        pageSize={10}
                        getRowClassName={(params) => {
                          return params.row.status
                            ? "bg-success text-white"
                            : "bg-danger text-white";
                        }}
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}

export default RecipentsFromDB;
