import React, { useState, useEffect } from "react";
import { DocusealBuilder } from "@docuseal/react";
import baseURL from "../../../utils/baseURL";
import { getDocusealToken } from "../../../services/docuseal";
import { updateDoc } from "../../../services/PharmacyUser";

const DocusealTemplateCreator = (props) => {
  const { fileName, externalId, pharmacyId, handlefsdClose, fetchDocs } = props;

  const [docuSealtoken, setDocuSealToken] = useState(null);

  const handleSave = (data) => {
    const title = data.name;
    updateDoc(externalId, { formText: { title: title } }).then(() => {
      fetchDocs();
      handlefsdClose();
    });
  };

  useEffect(() => {
    getDocusealToken(fileName, externalId, pharmacyId)
      .then((response) => {
        setDocuSealToken(response.data.token);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [fileName, externalId, pharmacyId]);

  return (
    <div>
      {docuSealtoken ? (
        <DocusealBuilder
          token={docuSealtoken}
          host={baseURL.docuseal}
          autosave={false}
          onSave={(data) => handleSave(data)}
          withRecipientsButton={false}
          withAddPageButton={false}
          withSendButton={false}
          withSignYourselfButton={false}
        />
      ) : (
        <div>
          {/*Error loading template creator */}
          <div className="alert alert-danger" role="alert">
            Error loading template creator
          </div>
        </div>
      )}
    </div>
  );
};

export default DocusealTemplateCreator;
