import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Fade";
import AnalyticsDatepicker from "../../../common/forms/AnalyticsDatepicker";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
function UserDocsStatus(props) {
  const { responses } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [popperId, setPopperId] = useState(null);
  const [userForms, setUserForms] = useState([]);
  const [completedResponses, setCompletedResponses] = useState([]);
  const [pendingResponses, setPendingResponses] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [cahartDef, setCahartDef] = useState({
    series: [],
    options: {
      noData: {
        text: "No data to show",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
      legend: {
        position: "left",
        horizontalAlign: "center",

        markers: {
          radius: 100,
          width: 10,
          height: 10,
          strokeColor: "#fff",
        },
      },
      chart: {
        type: "pie",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      colors: ["#f87181", "#94a3e8"],
      labels: ["Completed documents", "Pending documents"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    const processedData = responses.filter((item) => {
      return (
        (item.recipientInfo.type.toLowerCase() === "user" ||
          item.recipientInfo.type.toLowerCase() === "pharmacyadmin") &&
        (dayjs(item.sent).isBetween(startDate, endDate) ||
          dayjs(item.sent).isSame(startDate, "day") ||
          dayjs(item.sent).isSame(endDate, "day"))
      );
    });
    setUserForms(processedData);
  }, [startDate, endDate, responses]);

  useEffect(() => {
    setCompletedResponses(
      userForms.filter(
        (response) => response.status.toLowerCase() === "completed"
      )
    );

    setPendingResponses(
      userForms.filter(
        (response) => response.status.toLowerCase() === "pending"
      )
    );
  }, [userForms]);

  useEffect(() => {
    setCahartDef({
      ...cahartDef,
      series:
        completedResponses.length === 0 && pendingResponses.length === 0
          ? []
          : [completedResponses.length, pendingResponses.length],
    });
  }, [completedResponses, pendingResponses]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    setPopperId(open ? "transition-popper" : null);
  }, [open]);

  const handleStartDatechange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  return (
    <div className="row forms-charts m-3">
      <div className="col-12  card-header">
        <div className="row">
          <div className="col-10 ">
            <Stack direction="row" spacing={1}>
              <h3>Total Completed vs Pending Documents By Pharmacy User</h3>
              <Chip
                label={`${dayjs(endDate).diff(dayjs(startDate), "day")} Days`}
                color="primary"
                size="small"
              />
            </Stack>
          </div>
          <div className="col-2 w-2 h-2 float-end p-0 m-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleClick}
              fill="none"
              aria-describedby={popperId}
              viewBox="0 2 24 24"
              stroke-color="#6e8192"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-1 h-1"
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                color: "#6e8192",
                float: "right",
              }}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="col-12 ">
        <Chart
          options={cahartDef.options}
          series={cahartDef.series}
          type="pie"
          height={250}
        />
        <Popper
          id={popperId}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={500} delay={300}>
              <Box sx={{ border: 1, p: 1, bgcolor: "background.paper", mt: 1 }}>
                <AnalyticsDatepicker
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDatechange={handleStartDatechange}
                  handleEndDateChange={handleEndDateChange}
                />
              </Box>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default UserDocsStatus;
