import React from "react";
import PatientFormStatus from "../../../../pharmacyUser/formCenter/Analytics/Charts/PatientFormStatus";

const FormsCharts = (props) => {
  const { rawResponses } = props;
  return (
    <div className="card">
      <div className="row ">
        <div className="col-12 col-lg-6 ">
          <PatientFormStatus responses={rawResponses} />
        </div>
      </div>
    </div>
  );
};

export default FormsCharts;
