import React from "react";
import PatientDocsStatus from "../../../pharmacyUser/documentsCenter/docsCharts/PatientDocsStatus";

const DocsCharts = (props) => {
  const { data } = props;

  return (
    <div className="card">
      <div className="row">
        <div className="col12 col-lg-6">
          <PatientDocsStatus responses={data} />
        </div>
      </div>
    </div>
  );
};

export default DocsCharts;
