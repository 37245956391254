import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { handleGetCarePlanById } from "../../../services/CarePlan";
import {
  handleGetEventById,
  handleUpdateEvent,
  handleAddEvent,
} from "../../../services/Calendar";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Stack } from "@mui/material";
import { decodeToken } from "react-jwt";
import { SocketIoServer } from "../../../utils/globalState";
import { getNotificationsByUserID } from "../../../services/Common";
const ReschReqView = (props) => {
  const value = SocketIoServer.getState().SocketIoObj;
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;
  const { req, action } = props;
  const [open, setOpen] = useState(false);
  const [cp, setCP] = useState({});
  const [event, setEvent] = useState({});
  const [rejReason, setRejReason] = useState("");
  const [approved, setApproved] = useState(false);
  const [reject, setReject] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [socket, setSocket] = useState(null);
  const [thisreq, setThisreq] = useState({});
  const [thisNote, setThisNote] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setSocket(value);
  }, [value]);

  const handleClose = () => {
    setOpen(false);
    setApproved(false);
    setReject(false);
    setNewDate("");
  };

  useEffect(() => {
    if (req.cpid) {
      handleGetCarePlanById(req.cpid)
        .then((r) => {
          setCP(r.data);
          handleGetEventById(r.data.fuEventId)
            .then((res) => {
              res.data.changeRequest = JSON.parse(res.data.changeRequest);
              if (
                res.data.changeRequest.some((value) => {
                  return (
                    dayjs(value.originalDates).format("MM/DD/YYYY") ===
                    dayjs(req.eventToChange).format("MM/DD/YYYY")
                  );
                })
              ) {
                setThisreq(
                  res.data.changeRequest.find(
                    (value) =>
                      dayjs(value.originalDates).format("MM/DD/YYYY") ===
                      dayjs(req.eventToChange).format("MM/DD/YYYY")
                  )
                );
              }
              setEvent(res.data);
              getNotificationsByUserID(userID)
                .then((res) => {
                  res.data
                    .filter(
                      (note) =>
                        note.receiver_id === myDecodedToken.userId.toString() &&
                        note.sender_id !== myDecodedToken.userId.toString() &&
                        note.body === "reschedule-request"
                    )
                    .map((note) => {
                      let moteurlarr = note.url.split("/");
                      let id = moteurlarr[moteurlarr.length - 1];
                      let idarr = id.split("&");
                      let id1 = idarr[0];
                      let id2 = idarr[1];

                      if (
                        req.cpid.toString() === id2 &&
                        dayjs(req.eventToChange).format("MM/DD/YYYY") ===
                          dayjs(id1).format("MM/DD/YYYY")
                      ) {
                        setThisNote(note);
                      }
                    });
                })
                .catch((err) => {
                  console.error(err);
                });
              handleClickOpen();
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [req]);

  const recurrenceFromIso = (milliSecDate) => {
    return (
      dayjs(milliSecDate)
        .toISOString()
        .split(".")[0]
        .split("")
        .map((c) => (!isNaN(c) || c === "T" ? c : ""))
        .join("") + "Z"
    );
  };

  const handleApproval = (newDate) => {
    setNewDate(newDate);
    setApproved(true);
    setReject(false);
  };
  const handleDecline = () => {
    setApproved(false);
    setReject(true);
  };

  const updateEvent = () => {
    let exp = null;

    if (event.RecurrenceException) {
      if (approved) {
        let exptionDates = event.RecurrenceException.split(",");
        let removed = exptionDates.filter(
          (d) => d !== recurrenceFromIso(req.eventToChange)
        );
        exp = [...removed, recurrenceFromIso(newDate)].join(",");
      } else {
        exp = event.RecurrenceException;
      }
    }

    if (event.changeRequest.length > 0) {
      event.changeRequest = event.changeRequest.filter(
        (el) =>
          dayjs(el.originalDates).format("MM/DD/YYYY") !==
          dayjs(req.eventToChange).format("MM/DD/YYYY")
      );
    }
    let updatepaload = {
      updatedBy: Number(userID),
      isInitEvent: 0,
      RecurrenceException: exp,
      changeRequest:
        event.changeRequest.length > 0
          ? JSON.stringify(event.changeRequest)
          : null,
      isChangeRequest: event.changeRequest.length > 0 ? true : null,
    };

    handleUpdateEvent(event.Id, updatepaload)
      .then((rs) => {
        handleClose();
        socket.emit("deletenote", { id: thisNote.id });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleConfirm = () => {
    if (!approved && !reject) {
      return;
    }
    if (approved) {
      let paload = {
        pharmacyId: event.pharmacyId,
        creatorId: event.creatorId,
        OwnerId: event.OwnerId,
        Subject: `${event.Subject} rescheduled from ${dayjs(
          req.eventToChange
        ).format("MM/DD/YYYY")}`,
        StartTime: dayjs(newDate).toISOString(),
        EndTime: dayjs(newDate).add(30, "minutes").toISOString(),
        IsAllDay: 0,
        Description: null,
        Location: "phone",
        CategoryColor: "#A5A2B2",
        Priority: "Normal",
        RecurrenceRule: null,
        RecurrenceException: null,
        RecurrenceID: event.Id,
        StartTimezone: null,
        EndTimezone: null,
        IsReadonly: 0,
        IsBlock: 0,
        patientName: event.patientName,
        isInitEvent: 0,
        Status: 0,
        isAutomated: 0,
        isChangeRequest: 0,
        changeRequest: null,
      };

      handleAddEvent(paload)
        .then((res) => {
          updateEvent();
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (reject) {
      updateEvent();
      action(req.thisOrder);
      handleClose();
    }

    socket.emit("noteset", {
      sender_name: "Pharmacy", //the patient name
      message: reject
        ? `Reschedule request was rejected. Reason: ${req.reason} `
        : `Reschedule request was approved.`, //the cotent of the note
      sender: userID, //the patient id
      receiver: cp?.recipient?.id, //the pharmacy users id in this case
      body: `reschedule-request`, //the type of note
      unread: true,
      url: `#`, //the url of the note if any
      timestamp: Date.now(),
    });
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Requesting reschedule from  ${cp?.recipient?.first} ${cp?.recipient?.last}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The original date and time is{" "}
            {dayjs(req.eventToChange).format("MM/DD/YYYY hh:mm A")}
          </DialogContentText>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell align="right">Last Name</TableCell>
                  <TableCell align="right">DOB</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {cp?.recipient?.first}
                  </TableCell>
                  <TableCell align="right">{cp?.recipient?.last}</TableCell>
                  <TableCell align="right">{cp?.recipient?.DOB}</TableCell>
                  <TableCell align="right">{cp?.recipient?.email}</TableCell>
                  <TableCell align="right">{cp?.recipient?.phone}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {req.cpid && Object.keys(event).length > 0 && (
            <List sx={{ bgcolor: "background.paper", marginTop: "10px" }}>
              {event.changeRequest && event.changeRequest.length > 0 && (
                <>
                  {Object.keys(thisreq).length > 0 && thisreq.skip ? (
                    <p>
                      {" "}
                      Patient requested to skip only the follow up on{" "}
                      {dayjs(req.eventToChange).format("MM/DD/YYYY hh:mm A")}.
                    </p>
                  ) : (
                    Object.keys(thisreq).length > 0 &&
                    thisreq.altDates.map((value) => (
                      <ListItem
                        key={value}
                        sx={{ borderBottom: "1px solid #e0e0e0" }}
                        disableGutters
                        secondaryAction={
                          <Button
                            disabled={
                              dayjs(newDate).format("MM/DD/YYYY hh:mm A") ===
                                dayjs(value).format("MM/DD/YYYY hh:mm A") &&
                              approved
                                ? true
                                : false
                            }
                            size="small"
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleApproval(value);
                            }}>
                            APPROVE{" "}
                          </Button>
                        }>
                        <ListItemText
                          primary={`${dayjs(value).format(
                            "MM/DD/YYYY hh:mm A"
                          )}`}
                        />
                      </ListItem>
                    ))
                  )}
                </>
              )}
            </List>
          )}

          <Stack direction="column" spacing={2} sx={{ marginTop: "10px" }}>
            <Button
              disabled={reject}
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                handleDecline(req.eventToChange);
              }}>
              REJECT RESCHEDULE REQUEST{" "}
            </Button>
            <TextField
              name="reason"
              value={rejReason}
              onChange={(e) => setRejReason(e.target.value)}
              id="outlined-textarea"
              label="Rejection Reason"
              placeholder="Please provide a reason for rejection"
              multiline
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            CLOSE
          </Button>
          <Button color="success" onClick={handleConfirm}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ReschReqView;
