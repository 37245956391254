import React, { useState, useEffect } from "react";
import "../../styles/page-styles/messages.css";
import { SideBar } from "../../utils/globalState";
import { decodeToken } from "react-jwt";
import Channels from "../../components/pharmacyUser/messageCenter/Channel";
import MessageArea from "../../components/pharmacyUser/messageCenter/MessageArea";
// Material UI
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import LinearProgress from "@mui/material/LinearProgress";
import {
  handleGetChannels,
  handleCreateChannel,
  handleToggleShowChannel,
  getChannelById,
} from "../../services/Messages";
import { getAllUsersByTypeAndPhId } from "../../services/Common";
import { getPatientscharts } from "../../services/PharmacyUser";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { getMessagesUsers } from "../../services/PharmacyAdmin";
import { SocketIoServer } from "../../utils/globalState";

const Messages = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  let userId = myDecodedToken.userId;
  const [value, setValue] = useState("1");
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"), 10);
  const [usersList, setUsersList] = useState([]);
  const [messageAreaFlage, setMessageAreaFlage] = useState(false);
  const [msg, setMsg] = useState("");
  const [selected, setSelected] = useState([]);
  const [mobHide, setMobHide] = useState("");
  const [findval, setFindval] = useState("");
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [checked, setChecked] = useState(false);
  const [channelslist, setChannelslist] = useState([]);
  const [channelsSeachStore, setChannelsSeachStore] = useState([]);
  const [incommingFromchannels, setIncommingFromchannels] = useState(false);
  const [swichValue, setSwichValue] = useState(false);
  const [messageusersflag, setMessageusersFlag] = useState(true);
  const [messageuserslist, setMessageUsersList] = useState([]);
  const socketvalue = SocketIoServer.getState().SocketIoObj;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(socketvalue);
  }, [socketvalue]);

  const fetchChannels = (pharmacyId, userId) => {
    handleGetChannels(pharmacyId, userId)
      .then((res) => {
        setChannelslist(
          res.data.map((el) => {
            el.timeStamp = el.updatedAt || el.createdAt;
            el.className = "";
            if (el.hiddenFor) {
              let users = el.hiddenFor.split(",");
              if (users.includes(String(userId))) {
                el.localShow = false;
                el.show = false;
              } else {
                el.localShow = true;
                el.show = true;
              }
            } else {
              el.localShow = true;
              el.show = true;
            }

            return el;
          })
        );
        setChannelsSeachStore(
          res.data.map((el) => {
            el.timeStamp = el.updatedAt || el.createdAt;
            el.className = "";
            if (el.hiddenFor) {
              let users = el.hiddenFor.split(",");
              if (users.includes(String(userId))) {
                el.localShow = false;
                el.show = false;
              } else {
                el.localShow = true;
                el.show = true;
              }
            } else {
              el.localShow = true;
              el.show = true;
            }
            return el;
          })
        );
        setLoadingFlag(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingFlag(false);
        setChannelslist([]);
        setChannelsSeachStore([]);
        setMsg("Something went wrong");
      });
  };

  const messagesUsers = () => {
    getMessagesUsers(pharmacyId)
      .then((res) => {
        setMessageUsersList(res.data.users);
        setMessageusersFlag(
          res.data.users.some((el) => el.userId === Number(userId))
        );
      })
      .catch((err) => {
        console.error(err);
        setMessageusersFlag(false);
      });
  };

  useEffect(() => {
    fetchChannels(pharmacyId, userId);
    messagesUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId, userId]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    handleTabSelect(newValue);
  };
  const getFindval = (e) => {
    setFindval(e.target.value.trim());
    if (e.target.value.trim() === "") {
      setChannelslist(channelsSeachStore);
      return;
    }
    let reg = new RegExp(findval, "i");
    setChannelslist(
      channelslist
        .filter((ch) =>
          ch.members.find(
            (el) => el.first.search(reg) !== -1 || el.last.search(reg) !== -1
          )
        )
        .map((ch) => ({ ...ch, show: true }))
    );
  };

  useEffect(() => {
    handleTabSelect(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTabSelect = (tabValue) => {
    setUsersList([]);

    if (tabValue === "1") {
      getAllUsersByTypeAndPhId(pharmacyId)
        .then((res) => {
          setUsersList(
            res.data.data.filter(
              (user) =>
                user.userID !== userId &&
                (user.type.toLowerCase() === "user" ||
                  user.type.toLowerCase() === "pharmacyadmin")
            )
          );
        })
        .catch((err) => {
          console.error(err);
          setUsersList([]);
        });
    }

    if (tabValue === "2") {
      getAllUsersByTypeAndPhId(pharmacyId, "Provider")
        .then((res) => {
          setUsersList(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          setUsersList([]);
        });
    }

    if (tabValue === "3") {
      getPatientscharts(pharmacyId)
        .then((res) => {
          setUsersList(res.data.filter((el) => el.active));
        })
        .catch((err) => {
          console.error(err);
          setUsersList([]);
        });
    }
  };
  const handleChange = (e, values) => {
    setSelected(values);
  };
  const handleCreateCh = (e) => {
    e.preventDefault();
    setMsg("");
    if (selected.length === 0) {
      setMsg("Please select at least one recepient");
      return;
    }
    let selectRecepients = [...selected];
    let channelType = 1;
    let recepntsIds = [...selectRecepients.map((i) => i.userID), userId];
    let providerCheck = selectRecepients.filter(
      (i) => i.type.toLowerCase() === "provider"
    );
    let patientCheck = selectRecepients.filter(
      (i) => i.type.toLowerCase() === "patient"
    );

    if (providerCheck.length > 0 && patientCheck.length > 0) {
      setMsg(
        "You can't send message to provider and a patient at the same time"
      );
      return;
    }
    if (providerCheck.length > 1) {
      setMsg("You can't send message to more than one provider");
      return;
    }

    if (patientCheck.length > 1) {
      setMsg("You can't send message to more than one patient");
      return;
    }
    if (providerCheck.length === 1 && patientCheck.length === 0) {
      channelType = 2;
      recepntsIds = [providerCheck[0].userID];
    }

    if (providerCheck.length === 0 && patientCheck.length === 1) {
      channelType = 3;
      recepntsIds = [patientCheck[0].userID];
    }
    let payload = {
      creatorId: userId,
      channelType: channelType,
      pharmacyId: pharmacyId,
      users: recepntsIds,
    };

    handleCreateChannel(payload)
      .then((res) => {
        if (res?.data?.message === "Channel created") {
          setChannelslist(
            channelslist.map((ch) => {
              if (ch.channelId === res.data.channelId) {
                ch.show = true;
                ch.className =
                  "animate__animated animate__pulse animate__repeat-2 co";
                ch.scroll = "scroll";
              }
              return ch;
            })
          );
        }
        fetchChannels(pharmacyId, userId);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data?.message === "Channel already exist") {
          setChannelslist(
            channelslist.map((ch) => {
              if (ch.channelId === err.response.data.channelId) {
                ch.show = true;
                ch.className =
                  "animate__animated animate__pulse animate__repeat-2 co";
                ch.scroll = "scroll";
              }
              return ch;
            })
          );
        } else {
          setMsg(err?.response?.data?.message);
        }
      });

    setSelected([]);
  };

  const handleChannleUpdate = (channel) => {
    // 4- Here we are getting the channel to send to the Backend to update the channel.
    setIncommingFromchannels(channel);
    setMessageAreaFlage(true);
    setChannelslist(
      channelslist.map((item) => {
        // rounded rounded-pill
        if (item.channelId === channel.channelId) {
          return item;
        } else {
          item.scroll = "";
          if (item.className !== "newmessage") {
            item.className = "";
          }

          return item;
        }
      })
    );
  };

  const handleClose = (channel) => {
    if (channel.channelId === incommingFromchannels.channelId) {
      setMessageAreaFlage(false);
    }
    let users;
    let usersArr = [];
    if (channel.hiddenFor) {
      usersArr = channel.hiddenFor.split(",");
    }
    console.log(usersArr.includes(String(userId)));
    console.log(userId);
    if (usersArr.includes(String(userId))) {
      usersArr = usersArr.filter((u) => u !== String(userId));
      users = usersArr.join(",");
    } else {
      users = [...usersArr, userId].join(",");
    }

    handleToggleShowChannel(channel.channelId, users)
      .then((res) => {
        setChannelslist(
          channelslist.map((ch) => {
            if (ch.channelId === channel.channelId) {
              ch.localShow = !ch.localShow;
              ch.show = swichValue ? true : ch.localShow;
            }
            return ch;
          })
        );
      })
      .catch((err) => {
        setMsg(err?.response?.data?.message);
      });
  };

  const handleToggleShow = (event) => {
    setSwichValue(event.target.checked);
    if (event.target.checked) {
      setChannelslist(
        channelslist.map((ch) => {
          ch.show = true;
          return ch;
        })
      );
    } else {
      setChannelslist(
        channelslist.map((ch) => {
          ch.show = ch.localShow;
          return ch;
        })
      );
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("receive_message", (data) => {
        if (data.chId) {
          getChannelById(data.chId).then((res) => {
            let thischannel = res.data;
            if ([2, 3].includes(thischannel.channelType)) {
              thischannel.members = [
                ...thischannel.members,
                ...messageuserslist,
              ];
            }
            thischannel.timeStamp =
              thischannel.updatedAt || thischannel.createdAt;
            thischannel.className = "";
            // thischannel.localShow = thischannel.show;
            thischannel.localShow = thischannel.show;
            if (
              thischannel.members.some((el) => el.userId === Number(userId))
            ) {
              if (
                channelslist.some(
                  (el) => el.channelId === thischannel.channelId
                )
              ) {
                console.log(thischannel);

                setChannelslist(
                  channelslist.map((ch) => {
                    console.log(data.senderId);
                    console.log(userId);
                    if (ch.channelId === thischannel.channelId) {
                      if (data.senderId !== userId) {
                        ch.className = "newmessage";
                        ch.show = true;
                        ch.localShow = true;
                      }
                    }
                    return ch;
                  })
                );
                setChannelsSeachStore(channelsSeachStore);
              } else {
                if (data.senderId !== userId) {
                  thischannel.className = "newmessage";
                  thischannel.show = true;
                  thischannel.localShow = true;
                }

                setChannelslist([...channelslist, thischannel]);
                setChannelsSeachStore([...channelsSeachStore, thischannel]);
              }
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, channelslist, channelsSeachStore]);
  console.log(channelslist);
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Messages</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Messages Center</li>
              <li className="breadcrumb-item active">Messages</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="d-flex justify-content-center p-0 mx-2">
              <div className=" chat-app row">
                <div
                  id="plist"
                  className={`people-list col-12 col-lg-4 ${mobHide}`}>
                  <Box>
                    <TabContext value={value}>
                      <Box>
                        <TabList onChange={handleTabChange} className="">
                          <Tab
                            label="Pharmacy User"
                            value="1"
                            className="col-4"
                          />
                          {messageusersflag && (
                            <Tab
                              label="Providers"
                              value="2"
                              className="col-4"
                            />
                          )}
                          {messageusersflag && (
                            <Tab label="Patients" value="3" className="col-4" />
                          )}
                        </TabList>
                      </Box>
                    </TabContext>
                  </Box>
                  <hr className="my-2 text-secondary" />
                  <div className="row">
                    <div className=" mb-1 col-12">
                      <button
                        style={{ width: "100%" }}
                        type="button"
                        className="btn btn-secondary "
                        onClick={() => {
                          setChecked(!checked);
                        }}>
                        {checked ? "Cancel" : "Start New Chat"}
                      </button>
                    </div>
                  </div>
                  {checked && (
                    <div className="row">
                      <div className=" mt-1 mb-1">
                        <div className="input-group-text col-12 pt-2">
                          <Autocomplete
                            onChange={handleChange}
                            sx={{ width: "100%", marginRight: "10px" }}
                            multiple
                            id="tags-outlined"
                            options={usersList.filter(
                              (option) => option.id !== userId
                            )}
                            getOptionLabel={(item) =>
                              `${item.userID} - ${item.first} ${item.last} ${
                                item.MRN ? "- MRN: " + item.MRN : ""
                              }`
                            }
                            defaultValue={[]}
                            value={selected}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Recipients"
                                placeholder="Recipients"
                              />
                            )}
                          />
                          <a
                            href="#scroll"
                            className="btn btn-outline-secondary"
                            onClick={handleCreateCh}>
                            Start Chat
                          </a>
                        </div>
                      </div>

                      <div className="input-group">
                        {msg && <div className="col-12">{msg}</div>}
                      </div>
                    </div>
                  )}
                  <ul className="list-unstyled chat-list mt-2 mb-0">
                    <div className="row">
                      <div className="col-12 mb-1">
                        <Divider />
                      </div>
                      <div className="col-12 mb-1">
                        <Paper
                          component="form"
                          sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}>
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search channels by user name..."
                            inputProps={{
                              "aria-label": "Search channels by user name...",
                            }}
                            value={findval}
                            onChange={getFindval}
                          />
                          <Divider
                            sx={{ height: 28, m: 0.5 }}
                            orientation="vertical"
                          />
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            disabled>
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </div>
                      <div className="col-12 mb-1">
                        {channelslist.every((item) => item.localShow) ? (
                          <></>
                        ) : (
                          <FormControlLabel
                            control={<Switch />}
                            label="Show Hidden Channels"
                            onChange={handleToggleShow}
                            checked={swichValue}
                          />
                        )}
                      </div>
                    </div>
                    <div className="channelslist">
                      {loadingFlag ? (
                        <LinearProgress />
                      ) : (
                        <>
                          <>
                            {value === "1" && (
                              <>
                                {channelslist.length > 0 ? (
                                  channelslist
                                    .filter(
                                      (el) => el.channelType === 1 && el.show
                                    )
                                    .sort((a, b) => b.timeStamp - a.timeStamp)
                                    .map((el) => (
                                      <Channels
                                        key={el.channelId}
                                        channel={el}
                                        onChannelUpdate={handleChannleUpdate}
                                        setMobHide={setMobHide}
                                        onClose={handleClose}
                                      />
                                    ))
                                ) : (
                                  <p>No Channels Yet</p>
                                )}
                              </>
                            )}
                          </>
                          <>
                            {value === "2" && messageusersflag && (
                              <>
                                {channelslist.length ? (
                                  channelslist
                                    .filter(
                                      (el) => el.channelType === 2 && el.show
                                    )
                                    .sort((a, b) => b.timeStamp - a.timeStamp)
                                    .map((el) => (
                                      <Channels
                                        key={el.channelId}
                                        channel={el}
                                        onChannelUpdate={handleChannleUpdate}
                                        setMobHide={setMobHide}
                                        onClose={handleClose}
                                      />
                                    ))
                                ) : (
                                  <p>No Channels Yet</p>
                                )}
                              </>
                            )}
                          </>
                          <>
                            {value === "3" && messageusersflag && (
                              <>
                                {channelslist.length ? (
                                  channelslist
                                    .filter(
                                      (el) => el.channelType === 3 && el.show
                                    )
                                    .sort((a, b) => b.timeStamp - a.timeStamp)
                                    .map((el) => (
                                      <Channels
                                        key={el.channelId}
                                        channel={el}
                                        onChannelUpdate={handleChannleUpdate}
                                        setMobHide={setMobHide}
                                        onClose={handleClose}
                                      />
                                    ))
                                ) : (
                                  <p>No Channels Yet</p>
                                )}
                              </>
                            )}
                          </>
                        </>
                      )}
                    </div>
                  </ul>
                </div>

                {messageAreaFlage ? (
                  mobHide && (
                    <MessageArea
                      setMobHide={setMobHide}
                      mobHide={mobHide}
                      dataFromChannel={incommingFromchannels}
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Messages;
