import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { addNewPatient } from "../../services/PharmacyUser";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "../../styles/page-styles/addbulkpatients.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import AddBulkPatientsLogs from "../../components/pharmacyUser/patientCenter/AddBulkPatientsLogs";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
const BulkCreatePatients = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const headers = {
    // id: "",
    note: "",
    first: "",
    last: "",
    MRN: "",
    email: "",
    phone: "",
    DOB: "",
    gender: "",
    race: "",
    height: "",
    weight: "",
    SSN: "",
    name: "",
    relation: "",
    street: "",
    street2: "",
    city: "",
    zip: "",
    state: "",
    emName: "",
    emRelation: "",
    altPhone: "",
  };
  let AddedRecordsHolder = [];
  let existedRecordsHolder = [];
  let erroredRecordsHolder = [];
  const pharmacyID = localStorage.getItem("pharmacy");
  const [csvdata, setCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [completeFlag, setCompleteFlag] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [missingInfCount, setMissingInfCount] = useState(0);
  const [missingInfList, setMissingInfList] = useState([]);
  const [recordsAdded, setRecordsAdded] = useState([]);
  const [existedRecords, setExistedRecords] = useState([]);
  const [erroredRecords, setErroredRecords] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [dataProcessed, setDataProcessed] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    setColumns(
      Object.keys(headers).map((column) => {
        return {
          field: column,
          headerName: column.toUpperCase(),
          width: column === "note" || column === "email" ? 250 : 100,
        };
        // }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddPatients = (event) => {
    setUploading(true);
    setDataProcessed(false);
    event.preventDefault();
    // let csvdataHolder = csvdata
    setMissingInfList(csvdata.filter((i) => i.note !== ""));
    let data = csvdata
      .filter((i) => i.note === "")
      .map((item) => {
        item.address = [
          {
            name: item.name,
            relation: item.relation,
            street: item.street,
            street2: item.street2,
            city: item.city,
            state: item.state,
            zip: item.zip,
          },
        ];
        item.ContactInfo = [
          {
            emName: item.emName,
            emRelation: item.emRelation,
            altPhone: item.altPhone,
          },
        ];
        item.pharmacyID = pharmacyID;
        delete item.id;
        delete item.note;
        delete item.name;
        delete item.relation;
        delete item.street;
        delete item.street2;
        delete item.city;
        delete item.state;
        delete item.zip;
        delete item.emName;
        delete item.emRelation;
        delete item.altPhone;
        delete item.Line_num;
        return item;
      });

    Promise.all(data)
      .then((res) => {
        Promise.all(
          // eslint-disable-next-line array-callback-return
          res.map((patient) => {
            addNewPatient(patient)
              .then((resp) => {
                if (resp.data.message) {
                  AddedRecordsHolder.push(patient);
                  setRecordsAdded([...recordsAdded, ...AddedRecordsHolder]);
                }
              })
              .catch((err) => {
                console.error(err.response.data.message);

                if (
                  err.response.data.message &&
                  err.response.data.message === "User Exist!"
                ) {
                  existedRecordsHolder.push(patient);
                  setExistedRecords([
                    ...existedRecords,
                    ...existedRecordsHolder,
                  ]);
                } else {
                  patient.error = err.response.data.message;
                  erroredRecordsHolder.push(patient);
                  setErroredRecords([
                    ...erroredRecords,
                    ...erroredRecordsHolder,
                  ]);
                }
              });
          })
        ).finally(() => {});
      })
      .finally(() => {});
  };
  useEffect(() => {
    if (
      (recordsAdded.length > 0 ||
        existedRecords.length > 0 ||
        erroredRecords.length > 0 ||
        missingInfList.length > 0) &&
      missingInfCount +
        recordsAdded.length +
        existedRecords.length +
        erroredRecords.length ===
        totalCount
    ) {
      setUploading(false);
      setDataProcessed(true);
      setMsg("Data processed successfully!");
    }
  }, [
    totalCount,
    missingInfCount,
    existedRecords,
    recordsAdded,
    erroredRecords,
    missingInfList,
  ]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add List Of Patients</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Patient Center</li>
              <li className="breadcrumb-item active">Add List Of Patients</li>
            </ol>
          </nav>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            {showLog ? (
              <div className="container-fluid">
                <IconButton
                  aria-label="delete"
                  onClick={() => setShowLog(false)}>
                  <ArrowBackIcon />
                </IconButton>
                <AddBulkPatientsLogs
                  recordsAdded={recordsAdded}
                  erroredRecords={erroredRecords}
                  existedRecords={existedRecords}
                  missingInfList={missingInfList}
                />
              </div>
            ) : (
              <div className="container-fluid">
                {completeFlag ? (
                  <div style={{ height: "100%", width: "100%" }}>
                    <LoadingButton
                      onClick={handleAddPatients}
                      endIcon={<CloudUploadIcon />}
                      loading={uploading}
                      loadingPosition="end"
                      variant="contained"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={
                        uploading || csvdata.length === 0 || dataProcessed
                      }>
                      <span>Send</span>
                    </LoadingButton>
                    <div
                      style={{
                        textAlign: "center",
                        border: "1px solid red",
                        paddingTop: "10px",
                      }}>
                      <h5>Statistics Board</h5>
                      <ul className="list-group list-group-horizontal justify-content-center mb-2">
                        <li className="list-group-item">
                          Total Patients: {totalCount}
                        </li>
                        <li className="list-group-item">
                          Patients With Missing Info: {missingInfCount}
                        </li>
                        <li className="list-group-item">
                          Patients With Existing Info: {existedRecords.length}
                        </li>
                        <li className="list-group-item">
                          Patients Successfully Created: {recordsAdded.length}
                        </li>
                      </ul>
                      <p>Please note missing records will be excluded {}</p>
                      {msg !== "" && <p style={{ color: "green" }}>{msg}</p>}
                      {dataProcessed && (
                        <button
                          type="button"
                          className="btn btn-link btn-sm"
                          onClick={() => setShowLog(true)}>
                          View Logs
                        </button>
                      )}
                    </div>
                    <DataGrid
                      rows={csvdata}
                      columns={columns}
                      getRowId={(row) => csvdata.indexOf(row) + 1}
                      components={{ toolbar: GridToolbar }}
                      getRowClassName={(params) =>
                        params.row.note && params.row.note !== ""
                          ? `errorrow`
                          : ""
                      }
                      getCellClassName={(params) => {
                        if (params.row.note && params.row.note !== "") {
                          if (
                            params.field === "first" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                          if (
                            params.field === "last" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                          if (
                            params.field === "DOB" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                          if (
                            params.field === "MRN" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                          if (
                            params.field === "phone" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                          if (
                            params.field === "email" &&
                            (params.value === "" || params.value === null)
                          ) {
                            return "errorcell";
                          }
                        } else {
                          return "";
                        }
                      }}
                    />
                    {/* <button
                    type="button"
                    className="btn btn-success mt-2 float-right"
                    onClick={handleAddPatients}>
                    Add Patients
                  </button> */}

                    {}
                  </div>
                ) : (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        border: "2px dotted red",
                        padding: "5px",
                      }}>
                      Please use the template in the link below to import the
                      data. <br />
                      <a
                        href={require("../../assets/templates/template.4d7c8c24a212e82d9d20.csv")}>
                        <i
                          className="fa fa-download "
                          style={{ marginRight: "5px" }}></i>
                        template
                      </a>
                    </p>
                    <Importer
                      chunkSize={1000}
                      restartable={true}
                      defaultNoHeader={false}
                      skipEmptyLines={"greedy"}
                      // trimFields={false}
                      onStart={() => {}}
                      processChunk={async (rows) => {
                        setTotalCount(rows.length);

                        setCsvData(
                          await rows.map((i, ind) => {
                            // i.id = ind + 1;
                            i.note = "";
                            // i.SSN = ("" + i.SSN.trim()).replace(/\D/g, "");
                            if (i.first.trim() === "") {
                              i.note = `${i.note}, First Name Is Missing`;
                            }
                            if (i.last.trim() === "") {
                              i.note = `${i.note}, Last Name Is Missing`;
                            }
                            if (i.DOB.trim() === "") {
                              i.note = `${i.note},DOB Is Missing`;
                            } else {
                              i.DOB = dayjs(i.DOB).format("MM/DD/YYYY");
                            }
                            if (
                              i.email.trim() === "" &&
                              i.phone.trim() === ""
                            ) {
                              i.note = `${i.note}, Email and Phone Number Are Missing`;
                            }

                            if (i.MRN.trim() === "") {
                              i.note = `${i.note}, MRN Is Missing`;
                            }

                            if (i.phone.trim() !== "") {
                              let clean = ("" + i.phone.trim()).replace(
                                /\D/g,
                                ""
                              );
                              let match = clean.match(
                                /^(\d{3})(\d{3})(\d{4})$/
                              );
                              const invcodes = [
                                "800",
                                "888",
                                "877",
                                "866",
                                "855",
                                "844",
                                "833",
                                "800",
                                "888",
                                "877",
                                "866",
                                "855",
                                "844",
                                "833",
                                "001",
                                "011",
                                "002",
                                "000",
                              ];

                              if (
                                match &&
                                match[0].length === 10 &&
                                typeof Number(match[0]) === "number"
                              ) {
                                if (invcodes.includes(match[1])) {
                                  i.note = `${i.note}, ${i.phone} Invalid Phone Number`;
                                  i.phone = "";
                                } else {
                                  i.phone = `${match[1]}${match[2]}${match[3]}`;
                                }
                              } else {
                                i.note = `${i.note}, ${i.phone} Is Invalid Phone Number`;
                                i.phone = "";
                              }
                            }

                            if (i.email.trim() !== "") {
                              let validRegex =
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                              if (!i.email.match(validRegex)) {
                                i.note = `${i.note}, Invalid Email Address`;
                                i.email = "";
                              }
                            }

                            return i;
                          })
                        );
                      }}
                      onComplete={({
                        file,
                        preview,
                        fields,
                        columnFields,
                        rows,
                      }) => {
                        setMissingInfCount(
                          csvdata.filter((e) => e.note !== "").length
                        );
                        setCompleteFlag(true);
                      }}
                      // onClose={() => {
                      //   // setMissingInfCount(0);
                      //   setCompleteFlag(true);
                      // }}
                    >
                      {Object.keys(headers).map((key) => {
                        if (
                          [
                            "first",
                            "last",
                            "email",
                            "phone",
                            "DOB",
                            "MRN",
                          ].includes(key)
                        ) {
                          return (
                            <ImporterField
                              key={key}
                              name={key}
                              label={key.charAt(0).toUpperCase() + key.slice(1)}
                              required
                            />
                          );
                        } else {
                          return (
                            <ImporterField
                              key={key}
                              name={key}
                              label={key.charAt(0).toUpperCase() + key.slice(1)}
                              optional
                            />
                          );
                        }
                      })}
                    </Importer>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default BulkCreatePatients;
