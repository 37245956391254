import baseURL from "../utils/baseURL";
import axiosWithAuth from "../utils/axiosWithAuth";

export const getDocusealToken = (fileName, externalID, pharmacyId) => {
  return axiosWithAuth().post(
    `${baseURL.curoback}/api/docuseal/builder_token`,
    { name: fileName, external_id: externalID, folder_name: pharmacyId }
  );
};

export const getDocsTemplates = (id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/api/docuseal/templates/${id}`
  );
};

export const getDocSubmitterById = (submitter_id) => {
  return axiosWithAuth().get(
    `${baseURL.curoback}/api/docuseal/submitter/${submitter_id}`
  );
};

export const deleteDocusealTemplate = (id) => {
  return axiosWithAuth().delete(
    `${baseURL.curoback}/api/docuseal/templates/${id}`
  );
};
