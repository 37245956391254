import React, { useState, useEffect } from "react";
import { DocusealBuilder } from "@docuseal/react";
import { getDocsTemplates, getDocusealToken } from "../../../services/docuseal";
import baseURL from "../../../utils/baseURL";

const PreviewDoc = (props) => {
  const { document } = props;

  const [docTemplates, setDocTemplates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [docuSealtoken, setDocuSealToken] = useState(null);

  useEffect(() => {
    getDocsTemplates(document.ID)
      .then((res) => {
        setDocTemplates(res.data.templates[0]);
      })
      .catch((err) => {
        console.error("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [document.ID]);

  useEffect(() => {
    const { name, external_id, folder_name } = docTemplates;

    if (!name) return;

    getDocusealToken(name, external_id, folder_name)
      .then((response) => {
        setDocuSealToken(response.data.token);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [docTemplates, docTemplates.external_id]);

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>

          <p className="mt-3">Loading Document...</p>
        </div>
      ) : (
        <div>
          {docuSealtoken ? (
            <DocusealBuilder
              token={docuSealtoken}
              host={baseURL.docuseal}
              autosave={false}
              preview={true}
              withRecipientsButton={false}
              withAddPageButton={false}
              withSendButton={false}
              withSignYourselfButton={false}
            />
          ) : (
            <div>
              {/*Error loading Document*/}
              <div className="alert alert-danger text-center" role="alert">
                Error loading Document
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PreviewDoc;
