import React, { useEffect, useState } from "react";
import {
  getPatientscharts,
  getSurveyById,
  addNewSurveyPatient,
} from "../../../services/PharmacyUser";
import SendIcon from "@mui/icons-material/Send";
import Portal from "@mui/base/Portal";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createNewToken } from "../../../services/PharmacyUser";
import { v4 as uuidv4 } from "uuid";
import baseURL from "../../../utils/baseURL";
import {
  sendEmail,
  sendSMS,
  getPharmacyById,
  urlShortner,
} from "../../../services/Common";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const PatientsFromDB = (props) => {
  const { surveyID } = props;
  const pharmacyId = parseInt(localStorage.getItem("pharmacy"), 10);
  const pharmacy_name = localStorage.getItem("pharmacyName");
  const [patientsList, setPatientsList] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SurveyData, setSurveyData] = useState({});
  const [msg, setMsg] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [dispenseDate, setDispenseDate] = useState("");
  const [PharmacySMSNum, setPharmacySMSNum] = useState(null);

  function MyCustomToolbar(props) {
    return (
      <React.Fragment>
        <Portal container={() => document.getElementById("filter-panel")}>
          <GridToolbarQuickFilter
            placeholder="Find Respients"
            sx={{
              width: "100%",
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "10px",
              paddingBottom: "10px",
              color: "black",
            }}
          />
        </Portal>
        {/* <GridToolbar {...props} /> */}
      </React.Fragment>
    );
  }

  useEffect(() => {
    getSurveyById(surveyID)
      .then((res) => {
        res.data[0].json = JSON.parse(res.data[0].survey);
        setSurveyData(res.data[0]);
      })
      .catch((err) => {
        setSurveyData({});
        console.error(err);
      });

    getPatientscharts(pharmacyId)
      .then((res) => {
        let acvalus = res.data.map((item) => {
          return {
            id: item.id,
            first: item.first,
            last: item.last,
          };
        });

        setAutoCompleteValue(acvalus);
        setPatientsList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    getPharmacyById(pharmacyId)
      .then((res) => {
        setPharmacySMSNum(res.data.smsPhoneNum);
      })
      .catch((err) => {
        console.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "first", headerName: "First Name", width: 150 },
    { field: "last", headerName: "Last Name", width: 150 },
  ];

  const sendSurvey = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectedOptions.length === 0) {
      setMsg("Please select patients");
      setIsLoading(false);
      return;
    }
    if (dispenseDate === "") {
      setMsg("Please select dispense date");
      setIsLoading(false);
      return;
    }
    Promise.all(
      // eslint-disable-next-line array-callback-return
      selectedOptions.map((item) => {
        addNewSurveyPatient({
          firstName: item.first,
          lastName: item.last,
          DOB: item.DOB,
          MRN: item.MRN,
          phone: item.phone,
          providing_pharmacy: "",
          pharmacy_NPI: "",
          dispense_date: dispenseDate,
          dispense_type: "",
        })
          .then(async (resp) => {
            let token = uuidv4();
            let date = new Date();
            date = date.toString();
            let tokenObject = {
              token: token,
              date: date,
              dispenseDate: "",
              ptid: resp.data.patinet_id,
              resflag: false,
              location: "",
              locationNPI: "",
              servid: surveyID,
            };
            createNewToken({ token: JSON.stringify(tokenObject) });

            let url = `${baseURL.frontEnd}/appless-survey/?id=${surveyID}&pid=${resp.data.patinet_id}&pdate=${dispenseDate}&phid=${pharmacyId}&token=${token}`;

            let shortUrl = await urlShortner(url);

            if (item.mail && item.mail !== "") {
              const EmailMessage = `<!DOCTYPE html>
  <html style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
  <head>
  <meta name="viewport" content="width=device-width" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <title>Actionable emails e.g. reset password</title>
  
  
  <style type="text/css">
  img {
  max-width: 100%;
  }
  body {
  -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em;
  }
  body {
  background-color: #f6f6f6;
  }
  @media only screen and (max-width: 640px) {
    body {
      padding: 0 !important;
    }
    h1 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h2 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h3 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h4 {
      font-weight: 800 !important; margin: 20px 0 5px !important;
    }
    h1 {
      font-size: 22px !important;
    }
    h2 {
      font-size: 18px !important;
    }
    h3 {
      font-size: 16px !important;
    }
    .container {
      padding: 0 !important; width: 100% !important;
    }
    .content {
      padding: 0 !important;
    }
    .content-wrap {
      padding: 10px !important;
    }
    .invoice {
      width: 100% !important;
    }
  }
  </style>
  </head>
  
  <body itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6">
  
  <table class="body-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
      <td class="container" width="600" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;" valign="top">
        <div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
          <table class="main" width="100%" cellpadding="0" cellspacing="0" itemprop="action" itemscope itemtype="http://schema.org/ConfirmAction" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9;" bgcolor="#fff"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
                <meta itemprop="name" content="Confirm Email" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;" /><table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                   Dear ${item.first} ${item.last} (or legal guardian),
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                  We providing you the very best in health care is our top priority. We gauge success on maintaining the satisfaction of our patients and their families. As a promise to continually improve our services, we ask for your feedback both in areas we can improve and where you think we do well.
                  It's our mission to take this feedback and improve the patient experience for you and others. We appreciate your time and ask that you spend just a few minutes with this short survey by using the link below. Anything that you share is confidential, and you are encouraged to tell us exactly what you think.  
                  Thank you for your feedback.
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" itemprop="handler" itemscope itemtype="http://schema.org/HttpActionHandler" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      <a href="${
                        shortUrl ? shortUrl : url
                      }" class="btn-success" itemprop="url" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Start Survey</a>
                    </td>
                  </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                      &mdash; Sincerely,
                    </td>
                  </tr></table></td>
            </tr></table><div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
            <table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;" align="center" valign="top">If you do not wish to participate, please click on the follwing link ${
              baseURL.frontEnd
            }/optout/?id=${item.id} to opt out of this survey.</td>
              </tr></table></div></div>
      </td>
      <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
    </tr></table></body>
  </html>`;

              sendEmail({ to: item.email, message: EmailMessage })
                .then(() => {})
                .catch((err) => {
                  console.error(err);
                });
            }

            if (item.phone && item.phone !== "") {
              let SmsMessage = `${pharmacy_name} Pharmacy would like your feedback. Please rate them at ${
                shortUrl ? shortUrl : url
              }.
              To opt out, click on the follwing link ${
                baseURL.frontEnd
              }/optout/?id=${item.id} .`;

              const smsPayload = {
                to: item.phone,
                body: SmsMessage,
                from: PharmacySMSNum,
              };

              sendSMS(smsPayload).catch((err) => {
                console.error(err);
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
    ).finally(() => {
      setIsLoading(false);
      setMsg("Survey sent successfully");
    });
  };

  return (
    <div
      style={{
        border: "1px solid #e0e0e0",
        padding: "20px",
        paddingBottom: "40px",
        minHeight: "fit-content",
      }}>
      {msg === "" ? (
        <></>
      ) : (
        <div
          className="text-success"
          style={{ marginLeft: "15px", marginBottom: "20px" }}
          role="alert">
          {msg}
        </div>
      )}
      <div
        className="card-header fs-5"
        style={{
          textAlign: "center",
          color: "whitesmoke",
          background: "linear-gradient(-45deg, #64a4d8, steelblue, #1b4e79)",
        }}>
        <h3 className="text-center">Select Survey Recipients</h3>
      </div>

      {autoCompleteValue.length > 0 && (
        <>
          <Grid item>
            <Box id="filter-panel" />
          </Grid>
          <DataGrid
            checkboxSelection
            rows={autoCompleteValue}
            columns={columns}
            slots={{
              toolbar: MyCustomToolbar,
            }}
            onRowSelectionModelChange={(e) => {
              setSelectedOptions(patientsList.filter((i) => e.includes(i.id)));
              setMsg("");
              setDispenseDate("");
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
          />
        </>
      )}

      <h5 className=" mt-4 mb-4 mr-4" style={{ marginLeft: "15px" }}>
        Survey To Send:{" "}
        {Object.keys(SurveyData).length > 0 && SurveyData.json.title}
      </h5>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        locale="en-US"
        sx={{ float: "right", marginTop: 2 }}>
        <DatePicker
          label="Dispense Date *"
          value={dayjs(dispenseDate).format("YYYY-MM-DD")}
          onChange={(newValue) =>
            setDispenseDate(dayjs(newValue).format("MM/DD/YYYY"))
          }
          slotProps={{
            openPickerIcon: { fontSize: "large" },
          }}
        />
      </LocalizationProvider>
      <br />
      {!dispenseDate && (
        <span className="text-danger mt-2 mb-2" style={{ fontSize: "12px" }}>
          * Dispense date is required.
        </span>
      )}

      <LoadingButton
        onClick={sendSurvey}
        endIcon={<SendIcon />}
        sx={{ float: "right" }}
        loading={isloading}
        loadingPosition="end"
        variant="contained"
        disabled={
          selectedOptions.length < 1 || isloading || msg !== "" || !dispenseDate
        }>
        <span>Send</span>
      </LoadingButton>
    </div>
  );
};

export default PatientsFromDB;
