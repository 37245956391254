import React, { useState, useEffect } from "react";
import { SideBar } from "../../utils/globalState";
import { StyledEngineProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useModal } from "react-hooks-use-modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Unstable_Grid2";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useSnackbar from "@mui/base/useSnackbar";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { css, keyframes } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  getFormInstancesByFormId,
  deleteFormInstance,
  getPrivelge,
} from "../../services/PharmacyUser";
import InstanceCreator from "../../components/pharmacyUser/formCenter/InstanceCreator";
import SendForm from "../../components/pharmacyUser/formCenter/SendForm";
import { getUserById, getUserRole } from "../../services/Common";
import { decodeToken } from "react-jwt";

const FormsInstancesManage = (props) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

    const colors = [
      "#FF0000",
      "#FF8700",
      "#FFD300",
      "#DEFF0A",
      "#A1FF0A",
      "#0AFF99",
      "#0AEFFF",
      "#147DF5",
      "#580AFF",
      "#BE0AFF",
    ];

    const getColor = (idx) => {
      return colors[idx % colors.length];
    };

  //   ========================================== Snackbar ==========================================
  const blue = {
    50: "#F0F7FF",
    400: "#3399FF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    200: "#E0E3E7",
  };

  const snackbarInRight = keyframes`
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(0);
    }
  `;

  const CustomSnackbar = styled("div")(
    ({ theme }) => css`
      position: fixed;
      z-index: 5500;
      display: flex;
      right: 16px;
      top: 16px;
      left: auto;
      justify-content: start;
      max-width: 560px;
      min-width: 300px;
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? blue[600] : blue[400]};
      box-shadow: ${theme.palette.mode === "dark"
        ? `0 5px 13px -3px rgba(0,0,0,0.4)`
        : `0 5px 13px -3px ${grey[200]}`};
      padding: 0.75rem;
      color: ${theme.palette.mode === "dark" ? "#fff" : blue[900]};
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      animation: ${snackbarInRight} 500ms;
      transition: transform 0.2s ease-out;
    `
  );
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  //  ====================== Material UI Ends  ======================
  //   ====================== Modal ======================

  const { formId, originalForm, handleBack } = props;

  const [Modal, openModal, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [DeleteModal, openDeleteModal, closeDelete] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  //   ====================== Modal Ends ======================

  const isDivVisible = SideBar((state) => state.isDivVisible);
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userID = myDecodedToken.userId;

  const [formInstances, setFormInstances] = useState([]);
  const [instances4Search, setInstances4Search] = useState([]);
  const [variantName, seatVariantName] = useState("");
  const [randflag, setRandflag] = useState("init");
  const [thisInstance, setThisInstance] = useState({});
  const [deletebuttonflag, setDeletebuttonflag] = useState(false);
  const [msg, setMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [privilege, setPrivilege] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    userID &&
      getUserById(userID)
        .then((res) => {
          setUser(res.data.data.user[0]);
          return res.data.data.user[0].type;
        })
        .then((type) => {
          type.toLowerCase() !== "pharmacyadmin" &&
            getUserRole(userID).then((res) => {
              setUserRole(res.data.data[0].roleID || "");
            });
        })
        .catch((err) => console.error(err));
  }, [userID]);

  useEffect(() => {
    userRole &&
      getPrivelge(userRole)
        .then((res) => {
          setPrivilege(res.data.data.map((x) => x.name));
        })
        .catch((err) => console.error(err));
  }, [userRole]);

  useEffect(() => {
    getFormInstancesByFormId(formId || 1)
      .then((res) => {
        const formInstances = res.data.data.map((el) => ({
          ...el,
          json: JSON.parse(el.formText),
        }));
        setFormInstances(formInstances.reverse() || []);
      })
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randflag]);

  const handleChanges = (e) => {
    seatVariantName(e.target.value);
  };

  const handleCreate = () => {
    let form = {
      json: {},
      text: "",
    };

    form.json.title = variantName;
    form.id = 0;
    return form;
  };

  const handleDeleteChanges = (e) => {
    if (
      e.target.value.toLowerCase() === thisInstance.instanceName.toLowerCase()
    ) {
      setDeletebuttonflag(true);
    } else {
      setDeletebuttonflag(false);
    }
  };

  const handleDelete = () => {
    // delete form

    deleteFormInstance(thisInstance.id)
      .then((res) => {
        setMsg(
          `Form "${thisInstance.instanceName}" Has Been Successfully Deleted`
        );
        setFormInstances(
          formInstances.filter((instances) => instances.id !== thisInstance.id)
        );
        handleOpen();
      })
      .catch((err) => {
        console.error(err);
        setMsg(`Error Deleting "${thisInstance.instanceName}"`);
        handleOpen();
      });
  };

  useEffect(() => {
    setInstances4Search(formInstances);
  }, [formInstances]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let searchLower = e.target.value.toLowerCase();
    if (e.target.value === "") {
      setInstances4Search(formInstances);
    }
    setInstances4Search(
      formInstances.filter((el) =>
        el.instanceName.toLowerCase().includes(searchLower)
      )
    );
  };
  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{`${originalForm.json.title} Variants`}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Forms Center</li>
              <li
                className={`breadcrumb-item cstm_breadcrumb `}
                onClick={() => {
                  handleBack();
                }}
              >
                Manage Forms
              </li>{" "}
              <li
                className={`breadcrumb-item ${
                  randflag === "init" ? "active" : "cstm_breadcrumb"
                }`}
              >
                {`${originalForm.json.title}`}
              </li>
            </ol>
          </nav>
        </div>

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              {randflag === "init" ? (
                <>
                  <div className="mb-2">
                    {(privilege.includes("writeForms") ||
                      user.type?.toLowerCase() === "pharmacyadmin") && (
                      <Button
                        className="mx-2 ms-0  mt-2 mt-sm-0"
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                          openModal();
                          setIsUpdate(true);
                        }}
                      >
                        Create New Form Variant
                      </Button>
                    )}
                  </div>

                  <StyledEngineProvider injectFirst>
                    <div className="card px-2 pb-3 col-12  pt-4">
                      <div className="col-12 col-md-6 col-lg-4 my-2  ps-lg-5">
                        <div className="input-group flex-nowrap  ">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Search Variants"
                            aria-label="Search"
                            aria-describedby="addon-wrapping"
                            onChange={handleSearch}
                            value={search}
                          />
                          <span
                            className="input-group-text"
                            id="addon-wrapping"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              size={"1x"}
                            />
                          </span>
                        </div>
                      </div>
                      {/* -------------------------------------- */}
                      <div className="cstm_Survey_table col-12 col-lg-12 px-lg-5">
                        {instances4Search.map((item, i) => {
                          return (
                            <React.Fragment key={item.id}>
                              <div
                                key={item.id}
                                className="cstm_Survey_table_row card col-12"
                                style={{ backgroundColor: getColor(i) }}
                              >
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th
                                        className="py-2 ps-3"
                                        colspan="6"
                                        style={{
                                          backgroundColor: item.isHidden
                                            ? "#bab39e"
                                            : "white",
                                        }}
                                      >
                                        {item.json.title}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="col-12 ">
                                    <tr className="d-flex justify-content-stretch  col-12 flex-wrap">
                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setRandflag("send");
                                            setThisInstance(item);
                                          }}
                                        >
                                          Send
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0"
                                          onClick={() => {
                                            setIsUpdate(true);
                                            setRandflag("creator");
                                            setThisInstance(item);
                                          }}
                                        >
                                          View & Edit
                                        </button>
                                      </td>

                                      <td className="text-center col col-auto flex-grow-1 p-0">
                                        <button
                                          className="w-100 h-100 border-0 m-0 p-0 bg-danger text-white"
                                          onClick={() => {
                                            openDeleteModal();
                                            setThisInstance(item);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {i < formInstances.length - 1 ? (
                                <Divider
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                />
                              ) : (
                                <></>
                              )}{" "}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </StyledEngineProvider>
                  <Modal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Create New Variant
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Variant Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            close();
                            seatVariantName("");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          disabled={!variantName}
                          onClick={() => {
                            close();
                            setRandflag("creator");
                            setThisInstance(handleCreate());
                          }}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </Modal>
                  <DeleteModal>
                    <div className="card  mb-3" style={{ maxWidth: "35rem" }}>
                      <div className="card-header bg-transparent fw-bold text-center">
                        Delete{" "}
                        {thisInstance?.instanceName &&
                          thisInstance.instanceName}
                      </div>
                      <div className="card-body">
                        {/* <h5 className="card-title">Success card title</h5> */}
                        <form>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ color: "red" }}
                            >
                              Please type "
                              {thisInstance?.instanceName &&
                                thisInstance.instanceName}
                              " in the field below to confirm the deleted form
                              title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInput"
                              aria-describedby="emailHelp"
                              onChange={handleDeleteChanges}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-transparent text-center">
                        <button
                          type="button"
                          className="btn btn-primary mx-2 mx-2"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeDelete();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={!deletebuttonflag}
                          type="button"
                          className="btn btn-danger mx-2 mx-2"
                          onClick={() => {
                            closeDelete();
                            handleDelete();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </DeleteModal>
                  {open ? (
                    <ClickAwayListener onClickAway={onClickAway}>
                      <CustomSnackbar {...getRootProps()}>{msg}</CustomSnackbar>
                    </ClickAwayListener>
                  ) : null}
                </>
              ) : randflag === "creator" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisInstance(handleCreate());
                      setIsUpdate(false);
                    }}
                  >
                    Back
                  </Button>
                  <InstanceCreator
                    form={originalForm}
                    setRandflag={setRandflag}
                    isUpdate={isUpdate}
                    setIsUpdate={setIsUpdate}
                    privilege={privilege}
                    userID={userID}
                    isAdmin={user.type?.toLowerCase() === "pharmacyadmin"}
                    creatorType={"pharmacy"}
                    instance={thisInstance}
                    variantName={variantName}
                  />
                </>
              ) : randflag === "send" ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      setRandflag("init");
                      setThisInstance({});
                      setIsUpdate(false);
                    }}
                  >
                    Back
                  </Button>
                  <SendForm
                    form={thisInstance}
                    instanceId={thisInstance.id || 0}
                  />
                </>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormsInstancesManage;
