import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "survey-core/modern.min.css";
import { convertTime } from "../../utils/timeConverter";
import "../../styles/page-styles/forms.css";
import { decodeToken } from "react-jwt";
import { savePdf } from "../../utils/savePdf";
import { SideBar } from "../../utils/globalState";
import dayjs from "dayjs";
import { getResponses } from "../../services/PharmacyUser";

const FormsCenter = () => {
  const pharmacyID = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const userID = myDecodedToken.userId;
  const isDivVisible = SideBar((state) => state.isDivVisible);

  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnsPending = [
    {
      field: "response_ID",
      headerName: "ID",
      minWidth: 50,
    },
    { field: "form_ID", headerName: "Form ID", minWidth: 80, maxWidth: 80 },
    { field: "formName", headerName: "Form Name", minWidth: 300 },
    { field: "sender", headerName: "Received From", minWidth: 200 },
    {
      field: "sent",
      headerName: "Received On",
      minWidth: 200,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            className="btn btn-success col-10"
            onClick={() => {
              navigate(`${params.row.response_ID}`);
            }}>
            {!!params.row.value ? "Resume Form" : "Complete Form"}
          </button>
        </div>
      ),
    },
  ];

  const columnsComplete = [
    {
      field: "response_ID",
      headerName: "ID",
      minWidth: 50,
    },
    { field: "form_ID", headerName: "Form ID", minWidth: 80, maxWidth: 80 },
    { field: "formName", headerName: "Form Name", minWidth: 300 },
    { field: "sender", headerName: "Received From", minWidth: 200 },
    {
      field: "sent",
      headerName: "Received On",
      minWidth: 200,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 200,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            className="btn btn-success col-7"
            onClick={() => {
              navigate(`${params.row.response_ID}`);
            }}>
            Preview Form
          </button>
          <button
            className="btn btn-success col-5"
            onClick={() => {
              savePdf(params.row.formText, params.row.value, params.row);
            }}>
            Download
          </button>
        </div>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);

    getResponses(pharmacyID)
      .then((res) => {
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              isLinkDisabled: item.isLinkDisabled === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              instanceId: item.instanceID,
              value: item.value ? JSON.parse(item.value) : "",
              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",

              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completion: item.completion ? convertTime(item.completion) : "",
              sent: item.sent ? convertTime(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pharmacyID]);

  const daysPassed = (sentDateString) => {
    const diff = dayjs().diff(dayjs(sentDateString), "day");
    return diff;
  };

  useEffect(() => {
    if (data?.length) {
      const preFilterd = data.filter((el) => {
        return parseInt(el.recipient_ID) === userID;
      });

      //  ================= completed =================
      const completed = preFilterd.filter(
        (item) => item.status.toLowerCase() === "completed"
      );

      // ================= pending =================
      const pending = preFilterd.filter((item) => {
        return (
          item.status.toLowerCase() === "pending" &&
          daysPassed(item.sent) < 8 &&
          !completed.some(
            (el) =>
              el.form_ID === item.form_ID &&
              daysPassed(el.sent) < 8 &&
              dayjs(el.sent).isAfter(dayjs(item.sent))
          )
        );
      });

      const uniqueFormIDs = [];
      pending.forEach((item) => {
        if (!uniqueFormIDs.includes(item.form_ID)) {
          uniqueFormIDs.push(item.form_ID);
        }
      });
      const pendingFinal = uniqueFormIDs
        .map((formID) => {
          const formResponses = pending.filter((item) => {
            return item.form_ID === formID;
          });

          const mostRecentResponse = formResponses.reduce((prev, current) => {
            return prev.sent > current.sent ? prev : current;
          });
          return mostRecentResponse;
        })
        .sort((a, b) => {
          return new Date(b.sent) - new Date(a.sent);
        });

      setPendingData(pendingFinal || []);
      setCompletedData(completed || []);
    }
  }, [data, userID]);

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Forms Center</h1>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <>
                {!isLoading ? (
                  <div>
                    <Box sx={{ width: "100%" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs"
                        className="mb-1 ms-2">
                        <Tab label="Pending" />
                        <Tab label="Complete" />
                      </Tabs>
                      {value === 0 && (
                        <div className="dg_1liner">
                          <DataGrid
                            pageSize={25}
                            rows={pendingData}
                            getRowId={(row) => row.response_ID}
                            columns={columnsPending}
                            density="standard"
                            components={{
                              Toolbar: GridToolbar,
                            }}
                          />
                        </div>
                      )}
                      {value === 1 && (
                        <div className="dg_1liner">
                          <DataGrid
                            pageSize={25}
                            rows={completedData}
                            getRowId={(row) => row.response_ID}
                            columns={columnsComplete}
                            density="standard"
                            components={{
                              Toolbar: GridToolbar,
                            }}
                          />
                        </div>
                      )}
                    </Box>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <h3 className="text-center mt-5">
                      Loading Data, Please wait...
                    </h3>
                    <div className="spinner-border text-info" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FormsCenter;
