import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "survey-core/modern.min.css";
import { convertTime } from "../../utils/timeConverter";
import "../../styles/page-styles/forms.css";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { SideBar } from "../../utils/globalState";
import dayjs from "dayjs";
import {
  getDocResponses,
  handleGetDocFiles,
} from "../../services/PharmacyUser";

const DocsCenter = () => {
  const pharmacyID = localStorage.getItem("pharmacy");
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token);
  const userID = myDecodedToken.userId;
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const columnsPending = [
    { field: "docResponse_ID", headerName: "ID", minWidth: 50, maxWidth: 50 },
    { field: "doc_ID", headerName: "Doc. ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Document Name", minWidth: 150 },
    { field: "sender", headerName: "Received From", minWidth: 200 },
    { field: "sent", headerName: "Received On", minWidth: 200, Date: true },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            className="btn btn-success col-10"
            onClick={() => {
              navigate(`${params.row.docResponse_ID}`);
            }}>
            {"View & Sign"}
          </button>
        </div>
      ),
    },
  ];

  const columnsComplete = [
    { field: "docResponse_ID", headerName: "ID", minWidth: 50, maxWidth: 50 },
    { field: "doc_ID", headerName: "Doc. ID", minWidth: 70, maxWidth: 70 },
    { field: "formName", headerName: "Document Name", minWidth: 150 },
    { field: "sender", headerName: "Received From", minWidth: 200 },
    {
      field: "sent",
      headerName: "Received On",
      minWidth: 200,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },
    {
      field: "completion",
      headerName: "Completed On",
      minWidth: 200,
      Date: true,
      sortComparator: (v1, v2) => {
        return new Date(v1).getTime() - new Date(v2).getTime();
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <div className="col-12 d-flex gap-1">
          <button
            className="btn btn-success col-6"
            onClick={() => {
              navigate(`${params.row.docResponse_ID}`);
            }}>
            Preview
          </button>
        </div>
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);

    getDocResponses(pharmacyID)
      .then((res) => {
        console.log(res);
        const incoming = res.data
          .map((item) => {
            const updatedItem = {
              ...item,
              isStop: item.isStop === 1 ? true : false,
              formText: item.formText ? JSON.parse(item.formText) : "",
              value: item.value ? JSON.parse(item.value) : "",

              senderInfo: item.senderInfo ? JSON.parse(item.senderInfo) : "",
              recipientInfo: item.recipientInfo
                ? JSON.parse(item.recipientInfo)
                : "",
              sender: item.senderInfo
                ? JSON.parse(item.senderInfo).first +
                  " " +
                  JSON.parse(item.senderInfo).last
                : "",
              recipientName: item.recipientInfo
                ? JSON.parse(item.recipientInfo).first +
                  " " +
                  JSON.parse(item.recipientInfo).last
                : "",

              recipientType: item.recipientInfo
                ? JSON.parse(item.recipientInfo).type
                : "",
              MRN: item.recipientInfo
                ? JSON.parse(item.recipientInfo).MRN || "N/A"
                : "",
              formName: item.formText ? JSON.parse(item.formText).title : "",

              isArchived: item.isArchived === 1 ? true : false,
              archived_date: item.archived_date
                ? convertTime(item.archived_date)
                : "",
              isReviewed: item.isReviewed === 1 ? true : false,
              reviewedOn: item.reviewedOn ? convertTime(item.reviewedOn) : "",
              completion: item.completion ? convertTime(item.completion) : "",
              sent: item.sent ? convertTime(item.sent) : "",
              dateOfLastReminder: item.dateOfLastReminder
                ? convertTime(item.dateOfLastReminder)
                : "",
            };

            return updatedItem;
          })
          .reverse();

        setData(incoming);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pharmacyID]);

  const daysPassed = (sentDateString) => {
    const diff = dayjs().diff(dayjs(sentDateString), "day");
    return diff;
  };

  useEffect(() => {
    if (data?.length) {
      const preFilterd = data.filter((el) => {
        return parseInt(el.recipient_ID) === userID;
      });
      //  ================= completed =================
      const completed = preFilterd.filter(
        (item) => item.status.toLowerCase() === "completed"
      );

      // ================= pending =================
      const pending = preFilterd.filter(
        (item) =>
          item.status.toLowerCase() === "pending" &&
          daysPassed(item.sent) < 8 &&
          !completed.some(
            (el) =>
              el.doc_ID === item.doc_ID &&
              daysPassed(el.sent) < 8 &&
              dayjs(el.sent).isAfter(dayjs(item.sent))
          )
      );
      const uniqueFormIDs = [];
      pending.forEach((item) => {
        if (!uniqueFormIDs.includes(item.doc_ID)) {
          uniqueFormIDs.push(item.doc_ID);
        }
      });
      const pendingFinal = uniqueFormIDs
        .map((docID) => {
          const formResponses = pending.filter((item) => {
            return item.doc_ID === docID;
          });

          const mostRecentResponse = formResponses.reduce((prev, current) => {
            return prev.sent > current.sent ? prev : current;
          });
          return mostRecentResponse;
        })
        .sort((a, b) => {
          return new Date(b.sent) - new Date(a.sent);
        });

      setPendingData(pendingFinal || []);
      setCompletedData(completed || []);
    }
  }, [data, userID]);

  const handleObjectRewrite = (object) => {
    if (object.value === null) {
      return object.formText;
    } else {
      let form = object.formText;
      let response = object.value;
      let allquestions = [];

      form.pages.forEach((page) => {
        page.elements.forEach((element) => {
          allquestions.push(element);
        });
      });
      return {
        ...form,
        pages: [
          {
            name: "page1",
            elements: allquestions.map((question) => {
              if (response[question.name] !== undefined) {
                question.defaultValue = response[question.name];
                question.hideNumber = true;
              }

              return question;
            }),
          },
        ],
      };
    }
  };


  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Documents Center</h1>
        </div>
        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <>
                {!isLoading ? (
                  <div>
                    {msg === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger text-center p-2">{msg}</p>
                    )}
                    <Box sx={{ width: "100%" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs"
                        className="mb-1 ms-2">
                        <Tab label="Pending" />
                        <Tab label="Complete" />
                      </Tabs>
                      {value === 0 && (
                        <div className="dg_1liner">
                          <DataGrid
                            pageSize={25}
                            rows={pendingData}
                            getRowId={(row) => row.docResponse_ID}
                            columns={columnsPending}
                            density="standard"
                            components={{
                              Toolbar: GridToolbar,
                            }}
                          />
                        </div>
                      )}
                      {value === 1 && (
                        <div className="dg_1liner">
                          <DataGrid
                            pageSize={25}
                            rows={completedData}
                            getRowId={(row) => row.docResponse_ID}
                            columns={columnsComplete}
                            density="standard"
                            components={{
                              Toolbar: GridToolbar,
                            }}
                          />
                        </div>
                      )}
                    </Box>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <h3 className="text-center mt-5">
                      Loading Data, Please wait...
                    </h3>
                    <div className="spinner-border text-info" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </>
            </div>{" "}
          </div>
        </div>
      </main>
    </div>
  );
};

export default DocsCenter;
