import React, { useEffect, useState } from "react";
import { SideBar } from "../../utils/globalState";
import { handleGetPharmacies } from "../../services/Admin";
import LogoPictureUploader from "../../components/admin/LogoPictureUploader";
import { updatePharmacyInfo } from "../../services/PharmacyAdmin";
const PharmacyLogoUpdate = () => {
  const isDivVisible = SideBar((state) => state.isDivVisible);
  const pharmacy_id = localStorage.getItem("pharmacy");
  const [pharmacy, setPharmacy] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    phone: "",
    email: "",
  });

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    handleGetPharmacies().then((res) => {
      let pharmacy = res.data.data.find(
        (item) => item.id.toString() === pharmacy_id
      );
      console.log(pharmacy);
      setPharmacy(pharmacy);

      setFormData({
        phone: pharmacy.phone || "",
        email: pharmacy.email || "",
      });
    });
  }, [pharmacy_id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    updatePharmacyInfo(formData, pharmacy_id)
      .then((res) => {
        console.log(res);
        setIsEditing(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={!isDivVisible ? "toggle-sidebar" : ""}>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Pharmacy Information</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Admin Tools</li>
              <li className="breadcrumb-item active">Pharmacy Information</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <div className="d-flex flex-column" id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <p className="card-text p-2 m-2">
                          <span className="fw-bold">Pharmacy BIN: </span>
                          {pharmacy.BIN}
                        </p>
                        {isEditing ? (
                          <form>
                            <div className="mb-3">
                              <label className="form-label">
                                Phone:
                                <input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  value={formData.phone}
                                  onChange={handleInputChange}
                                />
                              </label>
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Email:
                                <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                />
                              </label>
                            </div>
                            <button
                              className="btn btn-success m-2"
                              onClick={handleSubmit}
                              type="button"
                            >
                              Save
                            </button>
                            <button
                              className=" btn btn-danger  m-2"
                              type="button"
                              onClick={() => setIsEditing(false)}
                            >
                              Cancel
                            </button>
                          </form>
                        ) : (
                          <div className="text-start align-items-start">
                            <p className="card-text p-2 m-2">
                              <span className="fw-bold">Phone Number:</span>{" "}
                              {formData.phone}
                            </p>
                            <p className="card-text p-2 m-2">
                              <span className="fw-bold">Email Address:</span>{" "}
                              {formData.email}
                            </p>
                            <button
                              className="btn btn-primary"
                              onClick={() => setIsEditing(true)}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {/* <!-- Page  --> */}
                    {Object.keys(pharmacy).length > 0 && (
                      <LogoPictureUploader pharmacy={pharmacy} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PharmacyLogoUpdate;
