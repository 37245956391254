import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  handleGetCarePlansByUserId,
  handleGetFormById,
  handleGetCpResponsesByCpId,
} from "../../../services/CarePlan";
import { decodeToken } from "react-jwt";
import { v4 } from "uuid";
import { RecurrenceEditor } from "@syncfusion/ej2-react-schedule";
import {
  handleGetEventById,
  handleGetFollowingEvents,
  handleUpdateEvent,
} from "../../../services/Calendar";
import dayjs from "dayjs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const FollowUpForms = () => {
  const token = localStorage.getItem("Token");
  const myDecodedToken = decodeToken(token) || {};
  const userid = myDecodedToken.userId;
  const navigate = useNavigate();
  const [fuEvent, setFuEvent] = useState({});
  const [recurrenceRule, setRecurrenceRule] = useState(null);
  const [combinedData, setCombinedData] = useState([]);

  const columnDef = [
    {
      field: "date",
      headerName: "Follow-Up Date",
      sortable: true,
      minWidth: 200,
      valueGetter(params) {
        return dayjs(params.row.date).format("MM/DD/YYYY");
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      width: 120,
      valueGetter(params) {
        return params.row.response?.completedOn ? "Completed" : "Pending";
      },
    },
    {
      field: "location",
      headerName: "Follow-Up Preference",
      sortable: true,
      width: 200,
      valueGetter(params) {
        return params.row.cp.isPhoneFu
          ? "Follow Up By Phone"
          : "Follow Up By Form";
      },
    },
    {
      field: "formName",
      headerName: "Form Name",
      sortable: true,
      width: 200,
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 450,
      renderCell: (params) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              navigate(
                `/patient/cp-fu-form/?id=${params.row.cpid}&uid=${userid}&except=${params.row.syncExcepDate}&d=${params.row.date}`
              );
            }}>
            Start Filling Form
          </button>

          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              handleSkip(
                params.row.fueID,
                params.row.date,
                params.row.exp,
                params.row.id
              );
            }}>
            Skip Follow-up
          </button>
        </div>
      ),
    },
  ];

  const recurrenceFromIso = (milliSecDate) => {
    return (
      dayjs(milliSecDate)
        .toISOString()
        .split(".")[0]
        .split("")
        .map((c) => (!isNaN(c) || c === "T" ? c : ""))
        .join("") + "Z"
    );
  };

  const getAllData = async () => {
    const cp = await handleGetCarePlansByUserId(userid);
    cp.data
      .filter((cPitem) => cPitem.completedOn === null)
      .forEach((element) => {
        handleGetEventById(element.fuEventId)
          .then(async (res) => {
            setFuEvent(res.data);
            let form = await handleGetFormById(element.fuFormId);
            let cPresP = await handleGetCpResponsesByCpId(element.id);

            let recObject = new RecurrenceEditor({
              change: (args) => {},
            });

            let ruleStringDates = recObject.getRecurrenceDates(
              new Date(res.data.StartTime),
              res.data.RecurrenceRule,
              res.data.RecurrenceException
            );
            const mainEventDates = ruleStringDates.map((d) => ({
              id: v4(),
              eventId: element.fuEventId,
              date: d,
              syncExcepDate: recurrenceFromIso(d),
              isMainEvent: true,
              response: {},
              form: form.data,
              formName: JSON.parse(form.data.formText).title,
              cpid: element.id,
              fueID: element.fuEventId,
              exp: res.data.RecurrenceException,
              cp: element,
            }));
            const followingEvents = await handleGetFollowingEvents(
              element.fuEventId
            );
            let followingDates = followingEvents.data.map((ev) => ({
              id: v4(),
              eventId: ev.Id,
              date: new Date(ev.StartTime).getTime(),
              syncExcepDate: ev.RecurrenceException,
              isMainEvent: false,
              response: cPresP.data.find((r) => r.fuSubEventId === ev.Id),
              form: form.data,
              formName: JSON.parse(form.data.formText).title,
              cpid: element.id,
              fueID: element.fuEventId,
              exp: res.data.RecurrenceException,
              cp: element,
            }));
            const allDates = [...mainEventDates, ...followingDates]
              .sort((a, b) => a.date - b.date)
              .filter((d) => {
                return (
                  dayjs(d.date).isBetween(
                    dayjs().subtract("1", "hour"),
                    dayjs().subtract(30, "days"),
                    "minute",
                    "[]"
                  ) && !d.response?.completedOn
                );
              });

            setCombinedData((prev) => [...prev, ...allDates]);
            setRecurrenceRule(
              res.data.RecurrenceRule ? res.data.RecurrenceRule : null
            );
          })
          .catch((err) => {
            console.error(err);
          });
      });
  };
  console.log(combinedData);

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkip = (evId, date, exp, id) => {
    let exptionDates = exp ? exp.split(",") : [];

    let payload = {
      updatedBy: Number(userid),
      isInitEvent: false,
      RecurrenceException: exptionDates.includes(recurrenceFromIso(date))
        ? exp
        : [...exptionDates, recurrenceFromIso(date)].join(","),
    };

    handleUpdateEvent(evId, payload)
      .then((res) => {
        setCombinedData(combinedData.filter((d) => d.id !== id));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      {/* {combinedData.length > 0 ? ( */}
      <div className="col-12 mt-3 mb-3 dg_2liner">
        <DataGrid
          rows={combinedData}
          columns={columnDef}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[5, 100]}
        />
      </div>
      {/* // ) : null} */}
    </div>
  );
};

export default FollowUpForms;
